import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { iconSizes } from '../../../../../shared/constants';
import {
  Box,
  ButtonIcon,
  ButtonIconText,
  FlexBox,
  H1,
  icons,
  PageContainer,
  SearchInput,
} from '../../../../../shared/components';
import { translate } from './translate';
import { HorizontalSpacer } from '../../../../../shared/components/spacers';
import { projectSelectors } from '../../../redux/projectSelectors';
import { ViewMode } from '../../../../../shared/constants/viewMode';
import { DropdownBasic } from '../../../../../shared/components/dropdown/dropdown';
import { filterDropdownItems } from './roleFeatures/segmentsDropdown';
import { DropdownItem } from '../../../../../shared/components/dropdown/dropdown.base';
import { SidebarLayout } from '../../../../../shared/components/sidebar-layout';
import {
  changeProjectViewModeAction,
  filterProjectListBySegmentAction,
  filterProjectListByTextAction,
} from '../../../redux/effects';
import { roleSelectors } from '../../../../Auth/redux/roles';
import { ProjectImportModal } from './ProjectImportModal';
import { roles } from '../../../../Auth/domain';

export const ProjectsLayout = ({
  scope,
  projectsCount,
  children,
  setPerCluster,
}: {
  scope: string;
  projectsCount: number;
  children: JSX.Element[] | JSX.Element;
  setPerCluster: any;
}): JSX.Element => {
  const projectsPerCluster = 'projectsPerCluster';
  const allProjects = 'allProjects';

  const viewMode = useSelector(projectSelectors.getViewMode);
  const role = useSelector(roleSelectors.getRole);
  const filter = useSelector(projectSelectors.getSegmentFilter);
  const dispatch = useDispatch();
  const [importModalVisible, setImportModalVisible] = useState(false);

  const isActive = (mode: ViewMode): boolean => mode === viewMode;

  const ChangeViewMode = (mode: ViewMode): void => {
    dispatch(changeProjectViewModeAction(mode));
  };

  const [searchText, setSearchText] = useState(
    useSelector(projectSelectors.getTextFilter),
  );

  const handleChange = (e: string): void => {
    setSearchText(e);
    _.debounce(() => dispatch(filterProjectListByTextAction(e)), 150)();
  };

  const handleSearch = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    dispatch(filterProjectListByTextAction(searchText));
  };

  return (
    <SidebarLayout title={translate(scope)('header.title')}>
      <>
        <ProjectImportModal
          isOpen={importModalVisible}
          onClose={() => setImportModalVisible(false)}
        />
        <PageContainer type="asFullWidthInBigScreen">
          <FlexBox justifyContent="space-between" alignItems="center">
            <FlexBox>
              <H1 color={'black2'}>{translate(scope)('header.title')}</H1>
              <HorizontalSpacer size="xl" />
              <DropdownBasic
                initialItem={filterDropdownItems[role]?.items.find(
                  (e: DropdownItem) => e.id === allProjects,
                )}
                placeholder={translate(scope)('filterDropdown.placeholder')}
                items={filterDropdownItems[role]?.items}
                onChange={item => {
                  item.id === projectsPerCluster
                    ? setPerCluster(true)
                    : setPerCluster(false);

                  dispatch(filterProjectListBySegmentAction(item.id));
                }}
              />
            </FlexBox>
            <FlexBox>
              {!!projectsCount && role === roles.admin && (
                <>
                  <ButtonIconText
                    onClick={(): void => setImportModalVisible(true)}
                    icon={icons.import}
                    size={iconSizes.md}
                    text={translate('Projects')('import.button')}
                  />
                  <HorizontalSpacer size="lg" />
                </>
              )}
              <SearchInput
                handleSubmit={handleSearch}
                onChangeText={handleChange}
                value={searchText}
                placeholder={'Search'}
                type={'text'}
              />
              <HorizontalSpacer size="xl" />
              <ButtonIcon
                size={iconSizes.sm}
                onClick={(): void => ChangeViewMode(ViewMode.list)}
                active={isActive(ViewMode.list)}
                icon={icons.listView}
              />
              <HorizontalSpacer size="sm" />
              <ButtonIcon
                size={iconSizes.sm}
                onClick={(): void => ChangeViewMode(ViewMode.grid)}
                active={isActive(ViewMode.grid)}
                icon={icons.gridView}
              />
            </FlexBox>
          </FlexBox>
          <Box marginTop="lg">
            <React.Fragment>{children}</React.Fragment>
          </Box>
        </PageContainer>
      </>
    </SidebarLayout>
  );
};
