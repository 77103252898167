/* eslint-disable @typescript-eslint/no-explicit-any */

import { combineReducers } from 'redux';

import * as session from '../../app/Auth/redux/reducers';
import rolesReducer, {
  initialState as rolesInitialState,
  State as rolesState,
} from '../../app/Auth/redux/rolesReducer';
import projectsReducer, {
  initialState as projectsInitialState,
  State as projectsState,
} from '../../app/Project/redux/projectsReducer';
import projectsUploadReducer, {
  initialState as projectsUploadInitialState,
  State as projectsUploadState,
} from '../../app/Project/redux/projectsUploadReducer';
import projectsRankingReducer, {
  initialState as projectsRankingInitialState,
  State as projectRankingState,
} from '../../app/Project/redux/projectsRankingReducer';
import adminProjectsReducer, {
  initialState as adminProjectsInitialState,
  State as adminProjectsState,
} from '../../app/Dashboard/redux/adminProjectsReducer';
import dashboardReducer, {
  initialState as dashboardInitialState,
} from '../../app/Dashboard/redux/dashboardReducer';
import { State as dashboardState } from '../../app/Dashboard/redux/reducerTypes';
import trafficLightReducer, {
  initialState as trafficLightInitialState,
  State as trafficLightState,
} from '../../app/Project/redux/projectsTrafficLightRatingReducer';
import topicRatingReducer, {
  initialState as topicRatingInitialState,
  State as topicRatingState,
} from '../../app/Project/redux/projectTopicRatingReducer';
import { logoutActionTypes } from '../../app/Auth/redux/actions';
import {
  reportsInitialState,
  reportsReducer,
} from '../../app/Reports/redux/reducer';
import { ReportsState } from '../../app/Reports/domain/state';

export interface Store {
  session: session.State;
  roles: rolesState;
  projects: projectsState;
  adminProjects: adminProjectsState;
  projectRanking: projectRankingState;
  dashboard: dashboardState;
  trafficLight: trafficLightState;
  topicRating: topicRatingState;
  reports: ReportsState;
  upload: projectsUploadState;
}

const initialState: Store = {
  session: session.initialState,
  roles: rolesInitialState,
  projects: projectsInitialState,
  adminProjects: adminProjectsInitialState,
  projectRanking: projectsRankingInitialState,
  dashboard: dashboardInitialState,
  trafficLight: trafficLightInitialState,
  topicRating: topicRatingInitialState,
  reports: reportsInitialState,
  upload: projectsUploadInitialState,
};

export const persisted = combineReducers({
  session: session.reducer,
  roles: rolesReducer,
  projects: projectsReducer,
  adminProjects: adminProjectsReducer,
  projectRanking: projectsRankingReducer,
  dashboard: dashboardReducer,
  trafficLight: trafficLightReducer,
  topicRating: topicRatingReducer,
  reports: reportsReducer,
  upload: projectsUploadReducer,
});

export default (state: any, action: any): any => {
  if (action.type === logoutActionTypes.request) {
    return persisted(initialState as any, action);
  }

  return persisted(state, action);
};
