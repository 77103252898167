import { TCluster, TInstitute } from '../../domain/cluster';
import { TPieChart, TPieData } from '../../domain/chart';
import { getTranslateByScope } from '../../../../shared/i18n';
import { chartColors } from '../../domain/constants';
import {
  getPercentagePerCluster,
  totalApprovedProjectsWithInCluster,
  totalApprovedProjectsWithInInstitute,
  totalProjectsWithInCluster,
  totalProjectsWithInInstitute,
} from '../../presentation/utils';

const getChartStyling = (pieData: TPieData): any => {
  const translate = getTranslateByScope('ui.layouts.Reports');
  return {
    labels: [translate('legend.total'), translate('legend.approved')],
    datasets: [
      {
        backgroundColor: [chartColors.grey, chartColors.green],
        hoverBackgroundColor: [chartColors.grey, chartColors.green],
        data: [pieData.totalPercentage, pieData.percentage],
      },
    ],
  };
};

export const PieChartDTO = (cluster: TCluster, total?: number): TPieChart => {
  const pieData: TPieData = getPercentagePerCluster(
    total ? total : totalProjectsWithInCluster(cluster),
    totalApprovedProjectsWithInCluster(cluster),
  );

  return getChartStyling(pieData);
};

export const PieChartHorizontalDTO = (
  institute: TInstitute,
  total?: number,
): TPieChart => {
  const pieData: TPieData = getPercentagePerCluster(
    total ? total : totalProjectsWithInInstitute(institute),
    totalApprovedProjectsWithInInstitute(institute),
  );

  return getChartStyling(pieData);
};
