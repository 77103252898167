import React, { HTMLAttributes } from 'react';
import { FlexBox } from '.';
import styles from './containers.module.scss';

export const CenterContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  return (
    <FlexBox justifyContent="center" flex={1}>
      <FlexBox className={styles.container}>{children}</FlexBox>
    </FlexBox>
  );
};

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  type:
    | 'withLeftSidebar'
    | 'withLeftAndRightSidebar'
    | 'asFullWidthInBigScreen';
  children: JSX.Element | JSX.Element[];
  style?: any;
}
export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  type,
  style,
}): JSX.Element => (
  <div className={styles[type]} style={style}>
    {children}
  </div>
);

export const GridContainer: React.FC = ({ children }) => {
  return <div className={styles.gridContainer}>{children}</div>;
};
