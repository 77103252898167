import React from 'react';
import { Box, FlexBox, H4 } from '../../../../../../shared/components';
import { translate } from '../translate';
import { ProjectCard } from '../../list/ProjectCard';
import { routePaths } from '../../../../../../shared/routes/config';

export const ClusterOverview: React.FC<{ projectIds: string[] }> = ({
  projectIds,
}) => {
  if (!projectIds || projectIds.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box marginTop="lg" marginBottom="md">
        <H4>{translate('clusterProjects.label')}</H4>
      </Box>
      <FlexBox.Row style={{ overflow: 'scroll' }}>
        {projectIds.map(id => (
          <a
            key={id}
            href={routePaths.projects.detail(id)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ProjectCard projectId={id} />
          </a>
        ))}
      </FlexBox.Row>
    </Box>
  );
};
