import _ from 'lodash';
import { ViewMode } from '../../../shared/constants/viewMode';
import { State as projectState } from './projectsReducer';
import { Segments } from '../../../shared/constants/segments';
import { TEvaluation } from '../../Dashboard/domain';
import { ProjectsPerCluster, TProject } from '../domain';
import { TId } from '../../../shared/domain';

type State =
  | {
      persisted: {
        projects: projectState;
      };
    }
  | {};

type ById = Record<TId, TProject>;

const getIds = (state: State): TId[] => _.get(state, 'persisted.projects.ids');
const getMyIds = (state: State): TId[] =>
  _.get(state, 'persisted.projects.my_ids');
const getById = (state: State): ById => _.get(state, 'persisted.projects.byId');

const getForId = (id: TId) => (state: State): TProject | null => {
  const byId = getById(state);

  if (!byId) return null;

  return byId[id] || null;
};

const getForCluster = (cluster: string) => (state: State): string[] => {
  const ids = getIds(state);
  const byId = getById(state);

  if (!ids || !byId) {
    return [];
  }

  return _.filter(ids, (id: string) => byId[id].cluster === cluster);
};

const getAllForCluster = (clusters: string[]) => (
  state: State,
): ProjectsPerCluster[] => {
  const projectsPerClusters: ProjectsPerCluster[] = [];
  const ids = getIds(state);
  const byId = getById(state);

  if (!ids || !byId) {
    return [];
  }
  clusters.map(cluster => {
    projectsPerClusters.push({
      id: cluster,
      order: cluster.split('|')[0],
      projects: _.filter(ids, (id: string) => byId[id].cluster === cluster),
    });
  });

  return projectsPerClusters.sort(function(a, b) {
    return a.order.localeCompare(b.order);
  });
};

const getViewMode = (state: State): ViewMode =>
  _.get(state, 'persisted.projects.viewMode');

const getHasUnsavedComment = (state: State): boolean =>
  _.get(state, 'persisted.projects.hasUnsavedComment');

const getShouldShowEvaluationModal = (state: State): boolean =>
  _.get(state, 'persisted.projects.showEvaluationModal');

const getTextFilter = (state: State): string =>
  _.get(state, 'persisted.projects.filters.text');

const getSegmentFilter = (state: State): string =>
  _.get(state, 'persisted.projects.filters.segment');

const getCommentedIds = (state: State): TId[] =>
  _.get(state, 'persisted.projects.commentedIds');

const getQualifiedIds = (state: State): TId[] =>
  _.get(state, 'persisted.projects.qualifiedIds');

const getRecentlyImportedIds = (state: State): TId[] =>
  _.get(state, 'persisted.projects.recentlyImportedIds');

const getProjectsFilteredByText = (projects: any, text: string): TProject[] =>
  _.filter(
    projects,
    (project: any) =>
      project.name.toLowerCase().includes(text.toLowerCase()) ||
      project.shortName.toLowerCase().includes(text.toLowerCase()) ||
      project.description.toLowerCase().includes(text.toLowerCase()),
  );

const getProjectsFilteredBySegment = (
  projects: TProject[],
  segment: string,
  commentedIds: TId[],
  qualifiedIds: TId[],
  myIds: TId[],
  recentlyImportedIds: TId[],
): TProject[] =>
  projects.filter((project: TProject) => {
    switch (segment) {
      case Segments.allProjects:
        return true;
      case Segments.alreadyCommented:
        return commentedIds.includes(project.id);
      case Segments.commentMissing:
        return !commentedIds.includes(project.id);
      case Segments.alreadyEvaluated:
        return qualifiedIds.includes(project.id);
      case Segments.evaluationMissing:
        return !qualifiedIds.includes(project.id);
      case Segments.myProjects:
        return myIds.includes(project.id);
      case Segments.recentlyImported:
        return recentlyImportedIds.includes(project.id);
      case Segments.projectsPerCluster:
        return true;
      default:
        return true;
    }
  });

const getProjectsFiltered = (state: State): TProject[] => {
  const textFilter = getTextFilter(state);
  const segmentFilter = getSegmentFilter(state);
  const commentedIds = getCommentedIds(state);
  const qualifiedIds = getQualifiedIds(state);
  const myIds = getMyIds(state);
  const recentlyImportedIds = getRecentlyImportedIds(state);

  const filter1 = getProjectsFilteredByText(getById(state), textFilter);
  const filter2 = getProjectsFilteredBySegment(
    filter1,
    segmentFilter,
    commentedIds,
    qualifiedIds,
    myIds,
    recentlyImportedIds,
  );
  return filter2.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
};

const getMyProjectsFiltered = (state: State): TProject[] =>
  _.filter(getProjectsFiltered(state), project =>
    getMyIds(state).includes(project.id),
  ).sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

const getMyProjects = (state: State): TProject[] => {
  const projects = getById(state);
  return _.filter(projects, project =>
    getMyIds(state).includes(project.id),
  ).sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
};

const getProjectsFilteredIds = (state: State): TId[] =>
  _.map(getProjectsFiltered(state), project => project.id);

const getProjectsClusters = (state: State): string[] =>
  _.map(getProjectsFiltered(state), project => project.cluster);

const getMyProjectsFilteredIds = (state: State): TId[] =>
  _.map(getMyProjectsFiltered(state), project => project.id);

const getProjectEvaluations = (id: TId) => (state: State): TEvaluation[] => {
  const result = _.get(state, 'persisted.projects.evaluations');
  if (result && result[id]) {
    return result[id].sort((a: TEvaluation, b: TEvaluation) => {
      return Date.parse(b.creationDate) - Date.parse(a.creationDate);
    });
  }
  return [];
};

export const projectSelectors = {
  getIds,
  getMyIds,
  getById,
  getForId,
  getForCluster,
  getAllForCluster,
  getViewMode,
  getMyProjects,
  getSegmentFilter,
  getProjectsFilteredIds,
  getMyProjectsFilteredIds,
  getTextFilter,
  getProjectEvaluations,
  getHasUnsavedComment,
  getShouldShowEvaluationModal,
  getProjectsClusters,
};
