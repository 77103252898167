import { TId } from '../domain';

export const endpoints = {
  session: {
    signin: 'api/session/signin',
    sso: 'api/sso/login',
    refreshToken: 'api/session/refresh',
    accounts: {
      resetPassword: 'api/accounts/reset_password',
      resetUserPassword: 'api/accounts/reset_user_password',
    },
  },
  projects: {
    list: 'api/projects',
    upload: 'api/projects',
    myProjects: 'api/projects/my_projects',
    adminProjects: 'api/projects/admin_rated_projects',
    adminRate: (id: TId): string => `api/projects/${id}/admin_rate`,
    editAdminRate: (id: TId, adminRatingId: TId): string =>
      `api/projects/${id}/admin_rate/${adminRatingId}`,
    myProjectRanking: 'api/projects/my_project_ranking',
    myQualifiedProjects: 'api/projects/qualified',
    detail: (id: TId): string => `api/projects/${id}`,
    delete: (id: TId): string => `api/projects/${id}`,
    editTrafficLightRate: (id: TId, trafficLightId: TId): string =>
      `api/projects/${id}/traffic_light/${trafficLightId}`,
    trafficLightRate: (id: TId): string => `api/projects/${id}/traffic_light`,
    myTrafficLightRate: (id: TId): string => `api/projects/${id}/traffic_light`,
    editTopicRating: (id: TId, topicRatingId: TId): string =>
      `api/projects/${id}/topic_rating/${topicRatingId}`,
    topicRate: (id: TId): string => `api/projects/${id}/topic_rating`,
    myTopicRating: (id: TId): string => `api/projects/${id}/topic_rating`,
    rank: `api/projects/rank`,
    dashboardData: 'api/projects/dashboard_data',
    evaluate: (id: TId): string => `api/projects/${id}/evaluation`,
    editEvaluation: (id: TId, evaluationId: TId): string =>
      `api/projects/${id}/evaluation/${evaluationId}`,
    deleteEvaluation: (id: TId, evaluationId: TId): string =>
      `api/projects/${id}/evaluation/${evaluationId}`,
    myEvaluations: (id: TId): string => `api/projects/${id}/evaluation`,
    replyToEvaluation: (id: TId, evaluationId: TId): string =>
      `api/projects/${id}/evaluation/${evaluationId}/respond`,
    reactToEvaluation: (id: TId, evaluationId: TId): string =>
      `api/projects/${id}/evaluation/${evaluationId}/react`,
    editResearchCoordinatorProject: (
      projectId: TId,
      toRemoveResearchCoordinatorId: TId,
    ): string =>
      `api/projects/${projectId}/change_research_coordinator/${toRemoveResearchCoordinatorId}`,
  },
  reports: {
    data: 'api/reports/',
  },
};
