import React from 'react';

import { useSelector } from 'react-redux';
import {
  Box,
  BoxWithUnderlinedLabel,
  NavigateBackTextWithIcon,
  Paragraph,
  ProjectDetailTags,
} from '../../../../shared/components';
import { routePaths } from '../../../../shared/routes/config';
import { translate } from '../components/detail/translate';
import { useParams, useRequestOnMount } from '../../../../shared/hooks';
import { projectSelectors } from '../../redux/projectSelectors';
import styles from '../components/detail/index.module.scss';
import { SidebarLayout } from '../../../../shared/components/sidebar-layout';
import { Header } from '../components/detail/Header';
import { Details } from '../components/detail/Details';
import { RightSidebar } from '../components/detail/RightSidebar';
import { projectActions } from '../../redux/effects';
import { TProject } from '../../domain';
import { ClusterOverview } from '../components/detail/components/clusterOverview';
import { ProjectImagePlaceHolder } from '../components/list/ProjectImagePlaceholder';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../shared/components/spacers';

interface Interface {
  location: {
    state: {
      projectListRoute: string;
    };
  };
}

export const ProjectDetail: React.FC<Interface> = ({ location }) => {
  const { id = '' } = useParams<{ id: string }>();
  useRequestOnMount(projectActions.detail, id);

  const project: TProject | null = useSelector(projectSelectors.getForId(id));
  const projectIdsInCluster: string[] = useSelector(
    projectSelectors.getForCluster(project?.cluster || ''),
  ).filter(id => id !== project?.id);

  if (!project) return null;

  const projectListRoute: string =
    location.state?.projectListRoute || routePaths.projects.list;

  return (
    <SidebarLayout title={translate('header.backToProjects')}>
      <>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <Box padding="xl">
              <NavigateBackTextWithIcon
                text={translate('header.backToProjects')}
                route={projectListRoute}
              />
              <VerticalSpacer size="lg" />
              <ProjectImagePlaceHolder
                removeTruncate
                shortName={project.shortName}
              />
              <Header project={project} />
              <ProjectDetailTags project={project} />
              <BoxWithUnderlinedLabel
                label={translate('otherInstitutes.label')}
              >
                <Paragraph>{project.additionalInstitutes.join(', ')}</Paragraph>
              </BoxWithUnderlinedLabel>
              <BoxWithUnderlinedLabel label={translate('description.label')}>
                <Paragraph>{project.description}</Paragraph>
              </BoxWithUnderlinedLabel>
              <Details project={project} />
              <ClusterOverview projectIds={projectIdsInCluster} />
            </Box>
          </div>
          <RightSidebar project={project} />
        </div>
      </>
    </SidebarLayout>
  );
};
