import { TCluster, TClusterInstitute, TInstitute } from '../domain/cluster';
import { TPieData } from '../domain/chart';

export const totalApprovedProjects = (clusters: TCluster[]): number => {
  return clusters.reduce(
    (result, current: TCluster) => result + current.approved,
    0,
  );
};

export const totalApprovedProjectsWithInCluster = (
  cluster: TCluster,
): number => {
  return cluster.institutes.reduce(
    (result: number, current: TClusterInstitute) => result + current.approved,
    0,
  );
};

export const totalApprovedProjectsWithInInstitute = (
  institute: TInstitute,
): number => {
  return institute.clusters.reduce(
    (result: number, current: TClusterInstitute) => result + current.approved,
    0,
  );
};

export const totalProjectsWithInCluster = (cluster: TCluster): number => {
  return cluster.institutes.reduce(
    (result: number, current: TClusterInstitute) => result + current.total,
    0,
  );
};

export const totalProjectsWithInInstitute = (institute: TInstitute): number => {
  return institute.clusters.reduce(
    (result: number, current: TClusterInstitute) => result + current.total,
    0,
  );
};

export const getPercentagePerCluster = (
  totalProjects: number,
  approvedProjects: number,
): TPieData => {
  const percentage: number = (approvedProjects / totalProjects) * 100;

  return {
    percentage: percentage,
    totalPercentage: 100 - percentage,
  };
};
