import { translate } from '../translate';
import { Segments } from '../../../../../../shared/constants/segments';
import { roles } from '../../../../../Auth/domain';

const translation = (name: string) =>
  translate('MyProjects')(`filterDropdown.${name}`);

const allProjects = {
  id: Segments.allProjects,
  value: translation(Segments.allProjects),
  disabled: false,
};
const projectsPerCluster = {
  id: Segments.projectsPerCluster,
  value: translation(Segments.projectsPerCluster),
  disabled: false,
};
const alreadyEvaluated = {
  id: Segments.alreadyEvaluated,
  value: translation(Segments.alreadyEvaluated),
  disabled: false,
};
const evaluationMissing = {
  id: Segments.evaluationMissing,
  value: translation(Segments.evaluationMissing),
  disabled: false,
};
const myProjects = {
  id: Segments.myProjects,
  value: translation(Segments.myProjects),
  disabled: false,
};
const alreadyCommented = {
  id: Segments.alreadyCommented,
  value: translation(Segments.alreadyCommented),
  disabled: false,
};
const commentMissing = {
  id: Segments.commentMissing,
  value: translation(Segments.commentMissing),
  disabled: false,
};

export const filterDropdownItems: { [key: string]: any } = {
  [roles.researchCoordinator]: {
    items: [
      allProjects,
      alreadyEvaluated,
      evaluationMissing,
      myProjects,
      projectsPerCluster,
    ],
  },
  [roles.thinkTankAdmin]: {
    items: [
      allProjects,
      alreadyEvaluated,
      evaluationMissing,
      projectsPerCluster,
    ],
  },
  [roles.programManagerAdmin]: {
    items: [
      allProjects,
      alreadyEvaluated,
      evaluationMissing,
      projectsPerCluster,
    ],
  },
  [roles.thinkTank]: {
    items: [allProjects, alreadyCommented, commentMissing, projectsPerCluster],
  },
  [roles.programManager]: {
    items: [allProjects, alreadyCommented, commentMissing, projectsPerCluster],
  },
  [roles.admin]: {
    items: [allProjects, alreadyCommented, commentMissing, projectsPerCluster],
  },
};
