import React from 'react';
import { useSelector } from 'react-redux';
import { Box, FlexBox, Image, Paragraph } from '../index';
import styles from './styles.module.scss';
import fraunhoferLogo from '../../assets/images/fraunhofer-logo.png';
import { isTablet } from '../../constants';
import { translate } from './components/translate';
import { SidebarBottom } from './components/sidebarBottom';
import { SidebarItemMyProjects, SidebarItems } from './components/sidebarItems';
import { joinClassNames } from '../../utils';
import { VerticalSpacer } from '../spacers';
import { roleSelectors } from '../../../app/Auth/redux/roles';
import { roles } from '../../../app/Auth/domain';

const SidebarFooter = (): JSX.Element => (
  <FlexBox fullWidth alignItems="center" justifyContent="center" marginTop="lg">
    <Box paddingRight="md">
      <Paragraph size="tiny">{translate('poweredBy.text')}</Paragraph>
    </Box>
    <Image className={styles.poweredByFraunhoferLogo} src={fraunhoferLogo} />
  </FlexBox>
);
const SidebarLogo = (): JSX.Element => (
  <FlexBox justifyContent="center" marginTop="lg">
    <Image className={styles.fraunhoferLogo} src={fraunhoferLogo} />
  </FlexBox>
);

const SidebarBase: React.FC = props => {
  return (
    <div
      className={joinClassNames(
        styles.sidebar,
        !isTablet ? styles.sidebar__fixed : null,
      )}
    >
      <SidebarLogo />
      <VerticalSpacer size="lg" />
      <div className={styles.sidebar__content}>
        <SidebarItems>{props.children}</SidebarItems>
        <SidebarBottom />
        <SidebarFooter />
      </div>
    </div>
  );
};

export const AuthenticatedSidebar = (): JSX.Element | null => {
  const role = useSelector(roleSelectors.getRole);

  if (role === roles.researchCoordinator)
    return (
      <SidebarBase>
        <SidebarItemMyProjects />
      </SidebarBase>
    );
  else if (role === roles.thinkTank) return <SidebarBase />;
  else if (role === roles.programManager) return <SidebarBase />;
  return <SidebarBase />;
};
