import React from 'react';
import { WhiteModalNoHeader } from '../../../../../shared/components';
import { ProjectImport } from './ProjectImport';
import { translate } from './translate';

interface Interface {
  onClose: Function;
  isOpen: boolean;
}

export const ProjectImportModal = ({
  isOpen,
  onClose,
}: Interface): JSX.Element => {
  return (
    <WhiteModalNoHeader onClose={onClose} isOpen={isOpen}>
      <ProjectImport
        onFileSelected={(): void => onClose()}
        title={translate('Projects')('import.title')}
        subtitle={translate('Projects')('import.subtitle')}
      />
    </WhiteModalNoHeader>
  );
};
