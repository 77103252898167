import { ErrorMessage } from './errorMessage';
import { Applicant, ApplicantDTO } from './applicant';
import { Ipc, IpcDTO } from './ipc';
import { ResearchCoordinators } from './research-coordinator';

type ProjectJsonDTO = {
  shortName: string;
  name: string;
  sectors: string[];
  cluster: string;
  leadingInstitute: string;
  additionalInstitutes: string[];
  applicant: ApplicantDTO;
  industries: string[];
  additionalIndustries?: string[];
  description: string;
  ipc: IpcDTO;
  tags: string[];
  researchCoordinators: string[];
  roi: number;
  url: string;
};

export class ProjectJson {
  private static OPTIONAL_ATTRIBUTES = ['additionalIndustries'];

  constructor(
    public shortName: string,
    public name: string,
    public sectors: string[],
    public cluster: string,
    public leadingInstitute: string,
    public additionalInstitutes: string[],
    public applicant: Applicant,
    public industries: string[],
    public additionalIndustries: string[] | undefined,
    public description: string,
    public ipc: Ipc,
    public tags: string[],
    public researchCoordinators: ResearchCoordinators,
    public roi: number,
    public url: string,
  ) {}

  isValidImport(): boolean {
    const requiredProperties = Object.keys(this).filter(
      prop => !ProjectJson.OPTIONAL_ATTRIBUTES.includes(prop),
    );

    for (const index in requiredProperties) {
      const prop = requiredProperties[index];
      const value = this[prop as keyof this];

      if (
        (value instanceof Applicant ||
          value instanceof Ipc ||
          value instanceof ResearchCoordinators) &&
        !value.isValid()
      ) {
        return false;
      } else if (value === undefined || value === null) {
        return false;
      }
    }

    return true;
  }

  invalidProperties(): ErrorMessage[] {
    const invalidProps: ErrorMessage[] = [];
    const requiredProperties = Object.keys(this).filter(
      prop => !ProjectJson.OPTIONAL_ATTRIBUTES.includes(prop),
    );

    for (const index in requiredProperties) {
      const prop = requiredProperties[index];
      const value = this[prop as keyof this];

      if (value instanceof ResearchCoordinators && !value.isValid()) {
        invalidProps.push({
          attribute: prop,
          message: `ResearchCoordinators field is invalid`,
        });
      } else if (value === undefined || value === null) {
        invalidProps.push({ attribute: prop, message: `${prop} is missing ` });
      }

      if (value !== undefined || value !== null) {
        switch (prop) {
          case 'shortName':
            typeof value !== 'string' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be a string `,
              });
            break;
          case 'name':
            typeof value !== 'string' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be a string `,
              });
            break;
          case 'description':
            typeof value !== 'string' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be a string `,
              });
            break;
          case 'sectors':
            typeof value !== 'object' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be an array of string `,
              });
            break;

          case 'cluster':
            typeof value !== 'string' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be a string `,
              });
            break;

          case 'leadingInstitute':
            typeof value !== 'string' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be a string `,
              });
            break;
          case 'additionalInstitutes':
            typeof value !== 'object' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be an array of string `,
              });
            break;
          case 'applicant':
            typeof value !== 'object' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must have applicant credential `,
              });
            break;
          case 'industries':
            typeof value !== 'object' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be an array of string `,
              });
            break;
          case 'tags':
            typeof value !== 'object' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be an array of string `,
              });
            break;

          case 'researchCoordinators':
            typeof value !== 'object' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be an array of emails `,
              });
            break;
          case 'url':
            typeof value !== 'string' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be a url link `,
              });
            break;
          case 'roi':
            typeof value !== 'number' &&
              invalidProps.push({
                attribute: prop,
                message: `${prop} must be a number `,
              });
            break;

          default:
            break;
        }
      }
    }

    return invalidProps;
  }

  formattedInvalidProperties(): ErrorMessage[] {
    const invalidProperties: ErrorMessage[] = this.invalidProperties();

    return invalidProperties.length === 0 ? [] : this.invalidProperties();
  }

  formattedProperty(key: keyof this): string {
    if (Array.isArray(this[key])) {
      return this[key] ? ((this[key] as unknown) as string[]).join(', ') : '';
    }

    return (this[key] as unknown) as string;
  }

  toDTO(): ProjectJsonDTO {
    return {
      shortName: this.shortName,
      name: this.name,
      sectors: this.sectors,
      cluster: this.cluster,
      leadingInstitute: this.leadingInstitute,
      additionalInstitutes: this.additionalInstitutes,
      applicant: this.applicant.toDTO(),
      industries: this.industries,
      additionalIndustries: this.additionalIndustries,
      description: this.description,
      ipc: this.ipc.toDTO(),
      tags: this.tags,
      researchCoordinators: this.researchCoordinators.toDTO(),
      roi: this.roi,
      url: this.url,
    };
  }

  static createFromJson(params: ProjectJsonDTO): ProjectJson {
    return new ProjectJson(
      params.shortName,
      params.name,
      params.sectors,
      params.cluster,
      params.leadingInstitute,
      params.additionalInstitutes,
      Applicant.create(params.applicant),
      params.industries,
      params.additionalIndustries,
      params.description,
      Ipc.create(params.ipc),
      params.tags,
      ResearchCoordinators.create(params.researchCoordinators),
      params.roi,
      params.url || 'noUrl',
    );
  }
}
