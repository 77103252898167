import queryString from 'query-string';
import { Location } from 'history';

export const getSearchParam = (
  location: Location,
  paramName: string,
): string | null => {
  if (!location) return null;

  const params = new URLSearchParams(location.search);
  return params.get(paramName);
};

export const routeWithSearchParams = ({
  pathName,
  searchParams,
}: {
  pathName: string;
  searchParams: object;
}): string => pathName + '?' + queryString.stringify(searchParams);
