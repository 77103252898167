import { RefObject, useEffect, useState } from 'react';
import { TStackedBarChart } from '../../../../domain/chart';
import { useComponentSize } from '../../../../../../shared/hooks/useComponentSize';
import { ChartBarWidth } from '../../../../domain/constants';

export const useChartHook = (
  myRef: RefObject<any>,
  data: TStackedBarChart,
  page: number,
): { [key: string]: any } => {
  const { width } = useComponentSize(myRef);
  const [initialWidth, setInitialWidth] = useState(0);
  const [dataset, setDataset] = useState(data);
  const [nextPage, setNextPage] = useState(false);

  useEffect(() => {
    if (initialWidth === 0 && width !== 0) {
      setInitialWidth(width);
    }
    const columnNumber = Math.floor(width / ChartBarWidth);

    const pageLabels = data.labels.slice(
      columnNumber * page,
      columnNumber * (page + 1),
    );
    const pageDataset1 = data.datasets[0].data.slice(
      columnNumber * page,
      columnNumber * (page + 1),
    );
    const pageDataset2 = data.datasets[1].data.slice(
      columnNumber * page,
      columnNumber * (page + 1),
    );
    const regardingLabels = new Array(columnNumber - pageDataset1.length).fill(
      '',
    );
    const regardingData = new Array(columnNumber - pageDataset1.length).map(
      e => 0,
    );

    setNextPage(regardingLabels.length === 0);

    setDataset({
      ...data,
      labels: [...pageLabels, ...regardingLabels],
      datasets: [
        {
          ...data.datasets[0],
          data: [...pageDataset1, ...regardingData],
        },
        {
          ...data.datasets[1],
          data: [...pageDataset2, ...regardingData],
        },
      ],
    });
  }, [width, page, data, initialWidth]);

  return { dataset, width: initialWidth, hasNextPage: nextPage };
};
