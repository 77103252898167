import React from 'react';
import styles from './conditionals.module.scss';
import { isMobile } from '../../shared/constants';
import { useSelector } from '../hooks';
import { checkIfRoleIsAllowed } from '../utils/roles';
import { roleSelectors } from '../../app/Auth/redux/roles';
import { roles } from '../../app/Auth/domain';

interface IfElseProps {
  condition: boolean;
  renderWhenFalse: (arg0: void) => JSX.Element | null;
  renderWhenTrue: (arg0: void) => JSX.Element | null;
}

export const IfElse = ({
  condition,
  renderWhenFalse,
  renderWhenTrue,
}: IfElseProps): JSX.Element | null => {
  if (condition) return renderWhenTrue();
  return renderWhenFalse();
};

interface IfProps {
  condition: boolean;
  children: (arg0: void) => JSX.Element;
}

export const If = ({ condition, children }: IfProps): JSX.Element | null =>
  IfElse({ condition, renderWhenTrue: children, renderWhenFalse: () => null });

export const Desktop = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <If condition={!isMobile}>
    {() => <div className={styles.desktop}>{children}</div>}
  </If>
);

export const Mobile = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <If condition={isMobile}>
    {() => <div className={styles.mobile}>{children}</div>}
  </If>
);

export const AccessControl = ({
  allows,
  children,
}: {
  allows: roles[];
  children: JSX.Element;
}) => {
  const currentRole = useSelector(roleSelectors.getRole) as roles;

  if (!currentRole) return null;

  const isAllowed = checkIfRoleIsAllowed({ currentRole, allows });

  return <If condition={isAllowed}>{() => children}</If>;
};
