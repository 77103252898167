import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { EvaluationValues } from './evaluateProjectApi';
import { TId } from '../../../shared/domain';

export const editEvaluationProjectApi = ({
  id,
  evaluationId,
  authenticationToken,
  comment,
  evaluation,
}: {
  id: TId;
  evaluationId: TId;
  authenticationToken: string;
  comment: string;
  evaluation: EvaluationValues;
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.editEvaluation(id, evaluationId)),
    method: httpMethods.put,
    authenticationToken,
    data: { evaluation: { comment, evaluation } },
  });
