import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  H1,
  PageContainer,
  Paragraph,
  PrimaryButton,
} from '../../../../shared/components';
import { SidebarLayout } from '../../../../shared/components/sidebar-layout';
import { VerticalSpacer } from '../../../../shared/components/spacers';

import { getTranslateByScope } from '../../../../shared/i18n';

import { TUser } from '../../../Auth/domain';

import { sessionSelectors } from '../../../Auth/redux/selectors';
import { PasswordResetForm } from './ResetPasswordForm';

export const translate = getTranslateByScope('ui.layouts.User');

export const UserDetails: React.FC = () => {
  const currentUser: TUser | null = useSelector(sessionSelectors.currentUser);
  const [resetPassword, setResetPassword] = useState(false);

  return (
    <SidebarLayout title={''}>
      <PageContainer
        type="asFullWidthInBigScreen"
        style={{ paddingLeft: '70px' }}
      >
        <H1>{translate('header.title')}</H1>
        <VerticalSpacer size="lg2" />
        <Paragraph size="small" style={{ textDecorationLine: 'underline' }}>
          {translate('form.name')}
        </Paragraph>
        <VerticalSpacer size="xs" />
        <Paragraph size="body">
          {`${currentUser?.firstName} ${currentUser?.lastName}`}
        </Paragraph>
        <VerticalSpacer size="lg2" />
        <Paragraph size="small" style={{ textDecorationLine: 'underline' }}>
          {translate('form.email')}
        </Paragraph>
        <VerticalSpacer size="xs" />
        <Paragraph size="body">
          {currentUser?.email || translate('form.noEmail')}
        </Paragraph>
        <VerticalSpacer size="md" />
        <hr style={{ width: '70%' }} />
        <VerticalSpacer size="lg2" />
        <Paragraph style={{ textDecorationLine: 'underline' }}>
          {translate('form.password')}
        </Paragraph>
        <VerticalSpacer size="lg2" />
        <div>{resetPassword && <PasswordResetForm />}</div>
        <Box>
          {!resetPassword && (
            <PrimaryButton onClick={() => setResetPassword(!resetPassword)}>
              {translate('form.buttonResetPassword')}
            </PrimaryButton>
          )}
        </Box>
      </PageContainer>
    </SidebarLayout>
  );
};
