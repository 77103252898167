export const getFromTo = ({
  pageIndex,
  itemsPerPage,
  totalLength,
}: {
  pageIndex: number;
  itemsPerPage: number;
  totalLength: number;
}) => {
  const from = itemsPerPage * pageIndex + 1;
  let to = itemsPerPage * (pageIndex + 1);

  if (to > totalLength) {
    to = totalLength;
  }

  return {
    from,
    to,
  };
};
