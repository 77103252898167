import { endpoints } from '../../../shared/api/endpoints';

import { httpMethods } from '../../../shared/constants';
import { apiUrl } from '../../../shared/api/apiUrl';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { TAccount } from '../../Auth/domain';

export const resetUserPasswordApi = ({
  account,
  authenticationToken,
}: {
  account: Partial<TAccount>;
  authenticationToken: string;
}): Promise<any> =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.session.accounts.resetUserPassword),
    method: httpMethods.post,
    data: {
      account,
    },
    authenticationToken,
  });
