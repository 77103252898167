import { endpoints } from '../../../shared/api/endpoints';

import { httpMethods } from '../../../shared/constants';
import { apiUrl } from '../../../shared/api/apiUrl';
import { fetchApi } from '../../../shared/api/fetchApi';
import { TAccount } from '../domain';

export const resetPasswordApi = ({
  account,
  token,
}: {
  account: Partial<TAccount>;
  token: string;
}): Promise<any> =>
  fetchApi({
    url: apiUrl(endpoints.session.accounts.resetPassword),
    method: httpMethods.post,
    data: {
      account,
      token,
    },
  });
