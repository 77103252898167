import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TId } from '../../../shared/domain';

export const evaluateProjectApi = ({
  id,
  authenticationToken,
  comment,
  evaluation,
}: {
  id: TId;
  authenticationToken: string;
  comment: string;
  evaluation: EvaluationValues;
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.evaluate(id)),
    method: httpMethods.post,
    authenticationToken,
    data: { evaluation: { comment, evaluation } },
  });

export enum EvaluationValues {
  OK = 'OK',
  KO = 'KO',
  DOUBT = 'DOUBT',
}
