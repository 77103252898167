import React, { useEffect, useState } from 'react';
import {
  ButtonIcon,
  FlexBox,
  icons,
  Paragraph,
  Textarea,
  TransparentButton,
} from '../../../../../../shared/components';
import { translate } from '../../detail/translate';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../shared/components/spacers';
import {
  useDispatch,
  useRequestOnMount,
  useSelector,
} from '../../../../../../shared/hooks';
import { projectSelectors } from '../../../../redux/projectSelectors';
import { TEvaluation } from '../../../../../Dashboard/domain';
import { Comment } from '../../../../../../shared/components/comment';

import { EvaluationValues } from '../../../../data/evaluateProjectApi';
import {
  projectActions,
  setEvaluationCommentStateAction,
} from '../../../../redux/effects';
import { TProject } from '../../../../domain';

export const Comments: React.FC<{
  evaluations: TEvaluation[];
  project: TProject;
}> = ({ evaluations, project }) => (
  <>
    {evaluations.map((evaluation: TEvaluation) => (
      <Comment
        key={evaluation.id}
        evaluation={evaluation}
        projectId={project.id}
        edit
      />
    ))}
  </>
);

export const EvaluationComment: React.FC<{
  project: TProject;
  onSubmit: (
    commentText: string,
    evaluation: EvaluationValues | undefined,
  ) => void;
}> = ({ project, onSubmit }) => {
  useRequestOnMount(projectActions.myEvaluationsByProject, { id: project.id });
  const comments = useSelector(
    projectSelectors.getProjectEvaluations(project.id),
  );

  const [evaluation, setEvaluation] = useState<EvaluationValues>();
  const [commentText, setCommentText] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const hasUnsavedComment = useSelector(projectSelectors.getHasUnsavedComment);
  const dispatch = useDispatch();

  const isActive = (e: EvaluationValues): boolean => e === evaluation;
  const hasErrorEvaluation = (): boolean => submitted && !evaluation;
  const hasErrorCommentText = (): boolean => submitted && !commentText;

  const toggleEvaluation = (e: EvaluationValues): void => {
    if (e === evaluation) {
      setEvaluation(undefined);
    } else {
      setEvaluation(e);
    }
  };

  const canSubmit = (): boolean => {
    return !!evaluation && !!commentText;
  };

  const submit = (): void => {
    if (canSubmit()) {
      onSubmit(commentText, evaluation);
      setEvaluation(undefined);
      setCommentText('');
      setSubmitted(false);
      return;
    }
    setSubmitted(true);
  };

  useEffect(() => {
    const newHasUnsavedComment = !!commentText || !!evaluation;

    if (newHasUnsavedComment !== hasUnsavedComment) {
      dispatch(setEvaluationCommentStateAction(newHasUnsavedComment));
    }
  }, [commentText, evaluation]);

  return (
    <div>
      <Comments evaluations={comments} project={project} />
      <VerticalSpacer size="md" />
      <Paragraph size="small" color="black2">
        {translate('modal.comment')}
      </Paragraph>
      <VerticalSpacer size="md" />
      <FlexBox.Row>
        <ButtonIcon
          onClick={(): void => toggleEvaluation(EvaluationValues.OK)}
          active={isActive(EvaluationValues.OK)}
          icon={icons.like}
        />
        <HorizontalSpacer size="sm" />
        <ButtonIcon
          onClick={(): void => toggleEvaluation(EvaluationValues.DOUBT)}
          active={isActive(EvaluationValues.DOUBT)}
          icon={icons.questionCircle}
        />
        <HorizontalSpacer size="sm" />
        <ButtonIcon
          onClick={(): void => toggleEvaluation(EvaluationValues.KO)}
          active={isActive(EvaluationValues.KO)}
          icon={icons.dislike}
        />
      </FlexBox.Row>
      <VerticalSpacer size="xs2" />
      {hasErrorEvaluation() && (
        <Paragraph size="small" color="red">
          {translate('modal.evaluationIconError')}
        </Paragraph>
      )}
      <VerticalSpacer size="md" />
      <Textarea
        rows={4}
        onChange={(v: string): void => {
          setCommentText(v);
        }}
        placeholder={translate('modal.textareaPlaceholder')}
        hasError={hasErrorCommentText()}
      >
        {commentText}
      </Textarea>
      <VerticalSpacer size="xs2" />
      {hasErrorCommentText() && (
        <Paragraph size="small" color="red">
          {translate('modal.commentError')}
        </Paragraph>
      )}
      <VerticalSpacer size="xl" />
      <TransparentButton
        style={{
          color: 'var(--primaryColor)',
          borderColor: 'var(--primaryColor)',
        }}
        onClick={submit}
      >
        {translate('modal.sendButton')}
      </TransparentButton>
    </div>
  );
};
