import React from 'react';

import { FlexBox, Desktop, EaseInBox } from '../../../../shared/components';

import LoginForm from '../components/LoginForm';
import { SessionPagesBackground } from '../components/SessionPagesBackground';
import { getTranslateByScope } from '../../../../shared/i18n';

export const translate = getTranslateByScope('ui.layouts.Login');

export const Login = (): JSX.Element => (
  <FlexBox style={{ height: '100vh', maxHeight: '100vh' }}>
    <Desktop>
      <FlexBox flex={1}>
        <SessionPagesBackground />
      </FlexBox>
    </Desktop>
    <FlexBox alignItems="center" justifyContent="center" flex={1}>
      <EaseInBox>
        <LoginForm />
      </EaseInBox>
    </FlexBox>
  </FlexBox>
);

export default Login;
