import { AxiosPromise } from 'axios';
import { fetchApi } from '../../../shared/api/fetchApi';
import { endpoints } from '../../../shared/api/endpoints';
import { httpMethods } from '../../../shared/api/constants';
import { apiUrl } from '../../../shared/api/apiUrl';

export const refreshTokenApi = ({
  refreshToken,
}: {
  refreshToken: string;
}): AxiosPromise =>
  fetchApi({
    url: apiUrl(endpoints.session.refreshToken),
    method: httpMethods.post,
    data: { refreshToken },
  });
