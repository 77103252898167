import { DashboardResponseData } from '../domain';
import { ActionInterface, State } from './reducerTypes';

export const updateProjectEvaluationInState = ({
  entity,
  index,
  action,
  state,
}: {
  entity: DashboardResponseData;
  index: number;
  action: ActionInterface;
  state: State;
}): State => {
  const evaluation = entity.evaluations[index];

  entity.evaluations.splice(index, 1, {
    ...evaluation,
    comment: action.requestParams.comment,
    evaluation: action.requestParams.evaluation,
  });

  return {
    ...state,
    entities: {
      ...state.entities,
      [action.requestParams.id]: entity,
    },
  };
};

export const deleteProjectEvaluationInState = ({
  entity,
  index,
  action,
  state,
}: {
  entity: DashboardResponseData;
  index: number;
  action: ActionInterface;
  state: State;
}): State => {
  entity.evaluations.splice(index, 1);

  return {
    ...state,
    entities: {
      ...state.entities,
      [action.requestParams.id]: entity,
    },
  };
};

export const updateEvaluationResponseInState = ({
  entity,
  action,
  state,
}: {
  entity: DashboardResponseData;
  action: ActionInterface;
  state: State;
}): State => {
  const parentEvaluation = entity.evaluations.find(e =>
    e.responses.map(r => r.id).includes(action.requestParams.evaluationId),
  );
  if (!parentEvaluation) return state;

  const responseIndex = parentEvaluation.responses.findIndex(
    r => r.id === action.requestParams.evaluationId,
  );
  const response = parentEvaluation.responses[responseIndex];
  parentEvaluation.responses.splice(responseIndex, 1, {
    ...response,
    comment: action.requestParams.comment,
    evaluation: action.requestParams.evaluation,
  });

  return {
    ...state,
    entities: {
      ...state.entities,
      [action.requestParams.id]: {
        ...entity,
        evaluations: entity.evaluations,
      },
    },
  };
};

export const deleteEvaluationResponseInState = ({
  entity,
  action,
  state,
}: {
  entity: DashboardResponseData;
  action: ActionInterface;
  state: State;
}): State => {
  const parentEvaluation = entity.evaluations.find(e =>
    e.responses.map(r => r.id).includes(action.requestParams.evaluationId),
  );
  if (!parentEvaluation) return state;

  const responseIndex = parentEvaluation.responses.findIndex(
    r => r.id === action.requestParams.evaluationId,
  );
  parentEvaluation.responses.splice(responseIndex, 1);

  return {
    ...state,
    entities: {
      ...state.entities,
      [action.requestParams.id]: {
        ...entity,
        evaluations: entity.evaluations,
      },
    },
  };
};
