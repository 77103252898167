import React from 'react';
import { FlexBox, Paragraph } from '../../../../../shared/components';
import { HorizontalSpacer } from '../../../../../shared/components/spacers';

import styles from './chartReport.module.scss';
import { getTranslateByScope } from '../../../../../shared/i18n';

export const ChartLegend: React.FC = () => {
  const translate = getTranslateByScope('ui.layouts.Reports');

  return (
    <FlexBox>
      <FlexBox>
        <div className={styles.report__legendTotal} />
        <HorizontalSpacer size="sm" />
        <Paragraph size="small" color="black2">
          {translate('legend.total')}
        </Paragraph>
      </FlexBox>
      <HorizontalSpacer size="lg2" />
      <FlexBox>
        <div className={styles.report__legendApproved} />
        <HorizontalSpacer size="sm" />
        <Paragraph size="small" color="black2">
          {translate('legend.approved')}
        </Paragraph>
      </FlexBox>
    </FlexBox>
  );
};
