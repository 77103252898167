import React from 'react';

import { Tooltip as ChakraTooltip } from '@chakra-ui/react';

export const Tooltip: React.FC<{
  label: React.ReactNode;
  ariaLabel: string;
  isDisabled: boolean;
  bg: string;
  color: string;
  placement: any;
}> = ({ label, isDisabled, bg, color, placement, ariaLabel, children }) => (
  <ChakraTooltip
    maxWidth="700px"
    hasArrow
    label={label}
    bg={bg}
    color={color}
    placement={placement}
    aria-label={ariaLabel}
    shouldWrapChildren
    size="md"
    isDisabled={isDisabled}
  >
    {children}
  </ChakraTooltip>
);

export const TopDarkTooltip: React.FC<{
  label: React.ReactNode;
  ariaLabel: string;
  isDisabled: boolean;
}> = ({ label, isDisabled, ariaLabel, children }) => (
  <Tooltip
    label={label}
    bg="grey.900"
    color="white"
    placement="top"
    ariaLabel={ariaLabel}
    isDisabled={isDisabled}
  >
    {children}
  </Tooltip>
);

export const LeftTooltip: React.FC<{
  label: React.ReactNode;
  ariaLabel: string;
}> = ({ label, ariaLabel, children }) => (
  <Tooltip
    label={label}
    bg="white"
    color="black"
    placement="left"
    ariaLabel={ariaLabel}
    isDisabled={false}
  >
    {children}
  </Tooltip>
);
