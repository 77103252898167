import React from 'react';

import { ReactComponent as TeamsIcon } from '../assets/icons/Teams.svg';
import { ReactComponent as SignOutIcon } from '../assets/icons/SignOut.svg';
import { ReactComponent as CoachIcon } from '../assets/icons/Coach.svg';
import { ReactComponent as ChevronLeftIcon } from '../assets/icons/ChevronLeft.svg';
import { ReactComponent as ChevronDownIcon } from '../assets/icons/ChevronDown.svg';
import { ReactComponent as LicenseIcon } from '../assets/icons/License.svg';
import { ReactComponent as IndustryIcon } from '../assets/icons/Industry.svg';
import { ReactComponent as ChipIcon } from '../assets/icons/Chip.svg';
import { ReactComponent as FileIcon } from '../assets/icons/File.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/Close.svg';
import { ReactComponent as GridViewIcon } from '../assets/icons/GridView.svg';
import { ReactComponent as ListViewIcon } from '../assets/icons/ListView.svg';
import { ReactComponent as LikeIcon } from '../assets/icons/Like.svg';
import { ReactComponent as DislikeIcon } from '../assets/icons/Dislike.svg';
import { ReactComponent as QuestionCircleIcon } from '../assets/icons/QuestionCircle.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/Search.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/Delete.svg';
import { ReactComponent as EditIcon } from '../assets/icons/Edit.svg';
import { ReactComponent as ItemSelectIcon } from '../assets/icons/ItemSelect.svg';
import { ReactComponent as BurgerIcon } from '../assets/icons/Burger.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/Dashboard.svg';
import { ReactComponent as PersonIcon } from '../assets/icons/Person.svg';
import { ReactComponent as ReportsIcon } from '../assets/icons/Reports.svg';
import { ReactComponent as DownloadIcon } from '../assets/icons/Download.svg';
import { ReactComponent as ImportIcon } from '../assets/icons/Import.svg';
import { ReactComponent as ExportIcon } from '../assets/icons/Export.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/Info.svg';
import { ReactComponent as VectorIcon } from '../assets/icons/Vector.svg';

import styles from './icons.module.scss';
import { joinClassNames } from '../utils/styles';
import { iconColors, iconSizes } from '../constants';

interface Props {
  color?: iconColors;
  size?: iconSizes;
  className?: string;
}

const mapSizes = {
  xs: 8,
  xs2: 12,
  sm: 18,
  md: 24,
  lg: 28,
  xl: 32,
  xl2: 36,
  xxl: 40,
};

const createIcon = ({
  Component,
  transform,
}: {
  Component: any;
  transform?: any;
  // eslint-disable-next-line react/display-name
}) => (props: Props): any => {
  return (
    <Component
      {...props}
      style={{ transform }}
      width={mapSizes[props.size || 'md']}
      height={mapSizes[props.size || 'md']}
      className={joinClassNames(
        styles.svg,
        styles[props.color || 'black'],
        props.className,
      )}
    />
  );
};

const Placeholder = (props: Props) => (
  <div
    style={{
      minHeight: mapSizes[props.size || 'md'],
      minWidth: mapSizes[props.size || 'md'],
    }}
  />
);

const icons = {
  order: createIcon({ Component: VectorIcon }),
  users: createIcon({ Component: TeamsIcon }),
  burger: createIcon({ Component: BurgerIcon }),
  edit: createIcon({ Component: EditIcon }),
  delete: createIcon({ Component: DeleteIcon }),
  logout: createIcon({ Component: SignOutIcon }),
  dashboard: createIcon({ Component: DashboardIcon }),
  download: createIcon({ Component: DownloadIcon }),
  import: createIcon({ Component: ImportIcon }),
  export: createIcon({ Component: ExportIcon }),
  person: createIcon({ Component: PersonIcon }),
  coach: createIcon({ Component: CoachIcon }),
  chevronLeft: createIcon({ Component: ChevronLeftIcon }),
  chevronDown: createIcon({ Component: ChevronDownIcon }),
  gridView: createIcon({ Component: GridViewIcon }),
  listView: createIcon({ Component: ListViewIcon }),
  reports: createIcon({ Component: ReportsIcon }),
  like: createIcon({ Component: LikeIcon }),
  dislike: createIcon({ Component: DislikeIcon }),
  questionCircle: createIcon({ Component: QuestionCircleIcon }),
  chevronRight: createIcon({
    Component: ChevronLeftIcon,
    transform: 'rotate(180deg)',
  }),
  chevronUp: createIcon({
    Component: ChevronDownIcon,
    transform: 'rotate(180deg)',
  }),
  license: createIcon({ Component: LicenseIcon }),
  itemSelect: createIcon({ Component: ItemSelectIcon }),
  industry: createIcon({ Component: IndustryIcon }),
  chip: createIcon({ Component: ChipIcon }),
  file: createIcon({ Component: FileIcon }),
  close: createIcon({ Component: CloseIcon }),
  search: createIcon({ Component: SearchIcon }),
  info: createIcon({ Component: InfoIcon }),
  placeholder: Placeholder,
};

export { icons };
