import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    grey: {
      900: '#111827',
    },
  },
  components: {
    Tooltip: {
      baseStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
      },
      sizes: {
        md: {
          fontSize: 12,
          p: 4,
        },
      },
    },
  },
});

export const ThemeProvider: React.FC<{}> = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
);
