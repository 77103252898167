import React from 'react';

import { useSelector } from 'react-redux';
import {
  Box,
  ButtonIcon,
  FlexBox,
  H4,
  icons,
  Paragraph,
  ProjectTagsContainer,
  Truncate,
} from '../../../../../shared/components';
import styles from './index.module.scss';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { projectSelectors } from '../../../redux/projectSelectors';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { ProjectImagePlaceHolder } from './ProjectImagePlaceholder';
import { TId } from '../../../../../shared/domain';
import { iconColors } from '../../../../../shared/constants';
import { roles } from '../../../../Auth/domain';
import { roleSelectors } from '../../../../Auth/redux/roles';
import { useDeleteProject } from '../../../application/use_delete_project';
import { useModal } from '../../../../../shared/hooks';
import { DeleteModal } from './DeleteModal';

export const translate = getTranslateByScope('ui.layouts.TeamList');

interface Interface {
  projectId: TId;
}

export const ProjectCard: React.FC<Interface> = ({ projectId }) => {
  const project = useSelector(projectSelectors.getForId(projectId));
  const role = useSelector(roleSelectors.getRole);
  const deleteModal = useModal(false);
  const { isLoading, deleteProject } = useDeleteProject();
  const handleDelete = (): void => deleteProject(projectId);

  if (!project) return null;

  return (
    <Box
      className={styles.projectList__card}
      marginRight="md"
      marginBottom="lg"
      padding="md"
    >
      <DeleteModal modal={deleteModal} onConfirm={handleDelete} />
      <FlexBox.Column flex={1}>
        <FlexBox justifyContent="space-between">
          <ProjectImagePlaceHolder shortName={project.shortName} />
          {role === roles.admin && (
            <div className={styles.projectList__deleteButtonCard}>
              <ButtonIcon
                active
                loading={isLoading}
                activeColor={iconColors.grey}
                hoverColor={iconColors.primary}
                icon={icons.delete}
                onClick={(e: MouseEvent): void => {
                  e.preventDefault();
                  deleteModal.open();
                }}
              />
            </div>
          )}
        </FlexBox>
        <VerticalSpacer size="md" />
        <Truncate maxLines={2}>
          <H4 color="black2">{project.name}</H4>
        </Truncate>
        <VerticalSpacer size="md" />
        <FlexBox.Row
          flex={1}
          fullWidth
          justifyContent="flex-start"
          alignItems="center"
        >
          <ProjectTagsContainer projectTags={project.tags} truncate={true} />
        </FlexBox.Row>
        <Truncate maxLines={3}>
          <Box marginTop="sm">
            <Paragraph color="black2">{project.description}</Paragraph>
          </Box>
        </Truncate>
      </FlexBox.Column>
    </Box>
  );
};
