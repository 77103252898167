import React from 'react';
import { Paragraph } from '../../../../../../shared/components';
import { TopicRatingValue } from '../../../../domain';
import styles from '../index.module.scss';

export const RatingSelectionOption: React.FC<{
  value: TopicRatingValue;
  valueKey: string;
  isSelected: boolean;
  onClick: (value: TopicRatingValue | null) => void;
}> = ({ value, valueKey, isSelected, onClick }) => {
  return (
    <>
      <div
        onClick={(): void => (isSelected ? onClick(null) : onClick(value))}
        className={
          isSelected
            ? styles.topicRating__selectionCircleSelected
            : styles.topicRating__selectionCircle
        }
      >
        <Paragraph className="circle" color={isSelected ? 'white' : 'black'}>
          {valueKey}
        </Paragraph>
      </div>
    </>
  );
};
