import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Pagination } from '../../../../../shared/components';
import { getPaginationData } from '../../../../../shared/utils';
import { translate } from './translate';
import { projectSelectors } from '../../../redux/projectSelectors';
import { ProjectListItems } from './ProjectListItems';
import { ProjectListEmptyState } from './ProjectEmpty';
import { ProjectGridItems } from './ProjectGridItems';
import { ViewMode } from '../../../../../shared/constants/viewMode';
import { TId } from '../../../../../shared/domain';

const ITEMS_PER_PAGE = 10;

interface Interface {
  projectIds: TId[];
  scope: string;
  projectDetailRoute: (projectId: string) => string;
  projectListRoute: string;
  perCluster: boolean;
  clusters: string[];
}

export const ProjectList: React.FC<Interface> = ({
  projectIds,
  scope,
  projectDetailRoute,
  projectListRoute,
  perCluster,
  clusters,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const viewMode = useSelector(projectSelectors.getViewMode);

  const {
    itemsForPage: projectsIdsForPage,
    pages,
    fromToDescription,
    totalOfPages,
  } = getPaginationData({
    pageIndex,
    itemsPerPage: ITEMS_PER_PAGE,
    items: projectIds,
  });

  if (projectsIdsForPage.length === 0) return <ProjectListEmptyState />;

  const ComponentForViewMode = {
    [ViewMode.list]: (
      <ProjectListItems
        projectDetailRoute={projectDetailRoute}
        projectIds={projectsIdsForPage}
        projectListRoute={projectListRoute}
        pageIndex={pageIndex}
        perCluster={perCluster}
        clusters={clusters}
      />
    ),
    [ViewMode.grid]: (
      <ProjectGridItems
        projectDetailRoute={projectDetailRoute}
        projectIds={projectsIdsForPage}
        projectListRoute={projectListRoute}
        pageIndex={pageIndex}
      />
    ),
  };
  return (
    <React.Fragment>
      {ComponentForViewMode[viewMode]}
      <Pagination
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pages={pages}
        totalOfPages={totalOfPages}
        fromToDescription={fromToDescription}
        totalLength={projectIds.length}
        separatorLabel={translate(scope)('records.separate')}
        fromLabel={translate(scope)('records.from')}
        label={translate(scope)('records.projects')}
      />
    </React.Fragment>
  );
};
