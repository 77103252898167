import { useDispatch } from 'react-redux';
import { TrafficLightRating } from '../domain';
import { projectActions } from '../redux/effects';

type Params = {
  projectId: string;
  rating: TrafficLightRating;
};

export const useCreateTrafficLightRating = (): {
  createTrafficLightRating: (params: Params) => void;
} => {
  const dispatch = useDispatch();

  const createTrafficLightRating = ({ projectId, rating }: Params): void => {
    dispatch(
      projectActions.trafficLightRate({
        id: projectId,
        rating: rating,
        onSuccess: () =>
          dispatch(
            projectActions.getProjectTrafficLightRatingAction({
              id: projectId,
            }),
          ),
      }),
    );
  };

  return {
    createTrafficLightRating,
  };
};
