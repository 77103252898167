import { showToasterAction } from '../../../../../shared/redux/actions';
import { translate } from '../detail/translate';
import { toasterTypes } from '../../../../../shared/constants';
import { projectActions } from '../../../redux/effects';
import { TId } from '../../../../../shared/domain';

export const handleSuccess = (dispatch: any, projectId: TId): void => {
  dispatch(
    showToasterAction({
      description: translate('modal.toast.success'),
      type: toasterTypes.success,
    }),
  );
  dispatch(projectActions.myEvaluationsByProject({ id: projectId }));
};

export const handleFail = (dispatch: any): void => {
  dispatch(
    showToasterAction({
      description: translate('modal.toast.error'),
      type: toasterTypes.failure,
    }),
  );
};
