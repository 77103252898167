import { routePaths } from '../routes/config';

export const loggedOutRoute = routePaths.login;

export const loggedInRoute = routePaths.dashboard;

export const loggedInRouteForCurrentRole = {
  researchCoordinator: loggedInRoute,
  thinkTank: loggedInRoute,
  programManager: loggedInRoute,
  thinkTankAdmin: loggedInRoute,
  programManagerAdmin: loggedInRoute,
  admin: loggedInRoute,
};

export const loggedOutRouteForCurrentRole = {
  researchCoordinator: loggedOutRoute,
  thinkTank: loggedOutRoute,
  programManager: loggedOutRoute,
  thinkTankAdmin: loggedOutRoute,
  programManagerAdmin: loggedOutRoute,
  admin: loggedOutRoute,
};
