import React from 'react';
import cn from 'classnames';

import styles from './typographies.module.scss';
import { addStyle } from '../../shared/utils';

export type Sizes = 'tiny' | 'small' | 'body' | 'big';

export type Colors =
  | 'red'
  | 'grey'
  | 'black'
  | 'white'
  | 'darkGrey'
  | 'blue'
  | 'black2'
  | 'link';

type Weights = 'thin' | 'light' | 'regular' | 'bold';

export const H1 = ({
  children,
  className,
  style,
  color,
}: {
  children: React.ReactNode | string;
  className?: string;
  color: Colors;
  style?: React.CSSProperties | undefined;
}): JSX.Element => (
  <h1 style={style} className={cn(styles.h1, styles[color], className)}>
    {children}
  </h1>
);

H1.defaultProps = {
  color: 'black2',
};

export const H2 = ({
  children,
  className,
  style,
  color,
}: {
  children: React.ReactNode | string;
  className?: string;
  color: Colors;
  style?: React.CSSProperties | undefined;
}): JSX.Element => (
  <h1 style={style} className={cn(styles.h2, styles[color], className)}>
    {children}
  </h1>
);

H2.defaultProps = {
  color: 'black2',
};

export const H3 = ({
  children,
  className,
  style,
  color,
}: {
  children: React.ReactNode | string;
  className?: string;
  color: Colors;
  style?: React.CSSProperties | undefined;
}): JSX.Element => (
  <h4 style={style} className={cn(styles.h3, styles[color], className)}>
    {children}
  </h4>
);

H3.defaultProps = {
  color: 'black2',
};

export const H4 = ({
  children,
  className,
  style,
  color,
}: {
  children: React.ReactNode | string;
  className?: string;
  color: Colors;
  style?: React.CSSProperties | undefined;
}): JSX.Element => (
  <h4 style={style} className={cn(styles.h4, styles[color], className)}>
    {children}
  </h4>
);

H4.defaultProps = {
  color: 'black2',
};

export const Paragraph: React.FC<{
  children?: React.ReactNode;
  color?: Colors;
  size?: Sizes;
  weight?: Weights;
  style?: React.CSSProperties | undefined;
  underlined?: boolean;
  className?: string;
} & React.HTMLAttributes<HTMLParagraphElement>> = ({
  children,
  color,
  size,
  weight,
  style,
  underlined,
  className,
  ...other
}) => (
  <p
    className={cn(
      styles.baseParagraph,
      size && styles[size],
      color && styles[color],
      weight && styles[weight],
      styles.size,
      styles.color,
      addStyle(underlined === true, styles.underlined),
      className,
    )}
    style={style}
    {...other}
  >
    {children}
  </p>
);

Paragraph.defaultProps = {
  color: 'black2',
  size: 'body',
  underlined: false,
};

export const Truncate = ({
  maxLines,
  children,
}: {
  maxLines: number;
  children: any;
}) => (
  <div
    className={styles.truncate}
    style={{
      WebkitLineClamp: maxLines,
    }}
  >
    {children}
  </div>
);

export const Title = ({
  children,
}: {
  children: React.ReactNode | string;
}): JSX.Element => (
  <H1
    color="black2"
    style={{ fontSize: 64, lineHeight: '150%', whiteSpace: 'pre-line' }}
  >
    {children}
  </H1>
);
