import React, { HTMLAttributes } from 'react';
import { getMarginsByProps, getPaddingsByProps } from './boxesStyles';
import { Mobile, Desktop, Paragraph, Separator, H4 } from '.';
import { joinClassNames } from '../../shared/utils';
import styles from './boxes.module.scss';
import { keys } from '../../shared/constants';
import { TClickEvent } from '../domain';

interface BoxProps
  extends HTMLAttributes<HTMLDivElement>,
    PaddingProps,
    MarginProps {
  backgroundColor?: string;
}

export const Box: React.FC<BoxProps> = ({
  style,
  margin,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  marginVertical,
  marginHorizontal,
  padding,
  paddingTop,
  paddingLeft,
  paddingRight,
  paddingBottom,
  paddingVertical,
  paddingHorizontal,
  ...props
}) => {
  const marginProps = {
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    marginVertical,
    marginHorizontal,
  };
  const paddingProps = {
    padding,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingVertical,
    paddingHorizontal,
  };
  return (
    <div
      {...props}
      style={{
        ...getMarginsByProps(marginProps),
        ...getPaddingsByProps(paddingProps),
        ...{ backgroundColor: props.backgroundColor },
        ...style,
      }}
    />
  );
};

Box.defaultProps = {
  children: null,
};

interface FlexBoxProps extends PaddingProps {
  flexDirection?: 'row' | 'column';
  flex?: number;
  justifyContent?: string;
  alignItems?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  flexWrap?: boolean;
}

export const FlexBox: {
  Row: any;
  Column: any;
} & React.FC<FlexBoxProps & BoxProps & MarginProps & PaddingProps> = ({
  flexDirection,
  flex,
  justifyContent,
  alignItems,
  style,
  fullWidth,
  fullHeight,
  flexWrap,
  ...props
}) => (
  <Box
    {...props}
    style={{
      display: 'flex',
      flexDirection,
      flex,
      justifyContent,
      alignItems,
      width: fullWidth ? '100%' : style?.width,
      height: fullHeight ? '100%' : style?.height,
      flexWrap: flexWrap ? 'wrap' : undefined,
      ...style,
    }}
  >
    {props.children}
  </Box>
);

FlexBox.defaultProps = {
  style: {},
};

const FlexBoxColumn: React.FC<FlexBoxProps> = props => (
  <FlexBox flexDirection="column" {...props} />
);

FlexBox.Column = FlexBoxColumn;
FlexBox.Row = FlexBox;

export const ResponsiveBox = ({
  children,
  mobileProps,
  notMobileProps,
  ...allProps
}: {
  children: React.ReactNode;
  mobileProps: BoxProps & MarginProps & PaddingProps & FlexBoxProps;
  notMobileProps: BoxProps & MarginProps & PaddingProps & FlexBoxProps;
  allProps: BoxProps & MarginProps & PaddingProps & FlexBoxProps;
}) => (
  <>
    <Mobile>
      <Box {...mobileProps} {...allProps}>
        {children}
      </Box>
    </Mobile>
    <Desktop>
      <Box {...notMobileProps} {...allProps}>
        {children}
      </Box>
    </Desktop>
  </>
);

ResponsiveBox.defaultProps = {
  mobileProps: {},
  notMobileProps: {},
  allProps: {},
};

export const ResponsiveFlexBox = ({
  children,
  mobileProps,
  notMobileProps,
  ...allProps
}: {
  children: React.ReactNode;
  mobileProps: BoxProps & MarginProps & PaddingProps & FlexBoxProps;
  notMobileProps: BoxProps & MarginProps & PaddingProps & FlexBoxProps;
  allProps: BoxProps & MarginProps & PaddingProps & FlexBoxProps;
}) => (
  <>
    <Mobile>
      <FlexBox {...mobileProps} {...allProps}>
        {children}
      </FlexBox>
    </Mobile>
    <Desktop>
      <FlexBox {...notMobileProps} {...allProps}>
        {children}
      </FlexBox>
    </Desktop>
  </>
);

ResponsiveFlexBox.defaultProps = {
  mobileProps: {},
  notMobileProps: {},
  allProps: {},
};

const ResponsiveFlexBoxColumn = (props: any) => (
  <ResponsiveFlexBox flexDirection="column" {...props} />
);

ResponsiveFlexBox.Column = ResponsiveFlexBoxColumn;
ResponsiveFlexBox.Row = FlexBox;

interface LinkBoxInterface {
  children?: JSX.Element | JSX.Element[];
  onClick: any;
  onMouseEnter?: TClickEvent;
  className?: string;
  style?: React.CSSProperties | undefined;
}

export const LinkBox = ({
  children,
  onClick,
  onMouseEnter,
  className,
  style,
}: LinkBoxInterface) => {
  const handleKeyPress = (e: any) => {
    if (!e) return null;
    e.preventDefault();
    e.stopPropagation();
    if (e.key === keys.Enter) onClick();
    return null;
  };

  return (
    <div
      onKeyPress={handleKeyPress}
      onMouseEnter={onMouseEnter}
      role="button"
      tabIndex={0}
      style={style}
      className={joinClassNames(styles.linkBox, className)}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

interface AbsolutePropsInterface {
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}
const getAbsoluteProps = (props: AbsolutePropsInterface) => ({
  left: props.left,
  right: props.right,
  top: props.top,
  bottom: props.bottom,
});

export const AbsoluteBox = (
  props: {
    children?: JSX.Element | JSX.Element[];
  } & AbsolutePropsInterface,
) => {
  return (
    <div style={getAbsoluteProps(props)} className={styles.absoluteBox}>
      {props.children}
    </div>
  );
};

export const RelativeBox = (props: {
  children?: JSX.Element | JSX.Element[];
}) => {
  return <div className={styles.relativeBox}>{props.children}</div>;
};

export const BoxWithUnderlinedLabel = (props: {
  label: string;
  children?: JSX.Element;
}): JSX.Element => {
  return (
    <Box marginTop="lg">
      <Box>
        <Paragraph underlined size="small">
          {props.label}
        </Paragraph>
        <Box marginTop="xs">{props.children}</Box>
      </Box>
    </Box>
  );
};

export const BoxWithLabelAndSeparator = (props: {
  label: string;
  children?: JSX.Element | JSX.Element[];
}): JSX.Element => {
  return (
    <Box>
      <Separator.Light />
      <Box marginTop="lg">
        <Box>
          <H4>{props.label}</H4>
          <Box marginTop="md">{props.children}</Box>
        </Box>
      </Box>
    </Box>
  );
};
