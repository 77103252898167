import { ChartBarWidth, chartColors } from '../../../../domain/constants';

export const ChartConfiguration = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  cornerRadius: 4,
  maxBarThickness: ChartBarWidth,
  categoryPercentage: 0.9,
  scales: {
    xAxes: [
      {
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90,
          fontColor: chartColors.black,
          fontStyle: 'bold',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          fontColor: chartColors.blueGrey,
        },
      },
    ],
  },
};

export const HorizontalChartConfiguration = {
  legend: {
    display: false,
  },
  showLines: true,
  maintainAspectRatio: false,
  maxBarThickness: 50,
  categoryPercentage: 0.9,
  scales: {
    yAxes: [
      {
        stacked: false,
        ticks: {
          autoSkip: false,
          fontColor: chartColors.black,
          fontStyle: 'bold',
        },
      },
    ],
    xAxes: [
      {
        stacked: false,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          fontColor: chartColors.blueGrey,
        },
      },
    ],
  },
};
