import React from 'react';
import { icons, Paragraph } from '../../../../../../shared/components';
import { LeftTooltip } from '../../../../../../shared/components/tooltips';
import styles from '../index.module.scss';
import { translate } from '../translate';

export const RatingInfo: React.FC = () => {
  const points = [10, 8, 6, 4, 2, 0];
  return (
    <>
      <LeftTooltip
        label={
          <table className={styles.topicRating__infoTable}>
            <thead>
              <tr>
                <th>
                  <Paragraph weight="bold">Punkte</Paragraph>
                </th>
                <th>
                  <Paragraph weight="bold">Beschreibung</Paragraph>
                </th>
              </tr>
            </thead>
            <tbody>
              {points.map(point => (
                <tr key={point}>
                  <td>
                    <Paragraph>{point}</Paragraph>
                  </td>
                  <td>
                    <Paragraph>
                      {translate(`topicEvaluation.ratingInfo.${point}`)}
                    </Paragraph>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
        ariaLabel={'test1'}
      >
        <icons.info />
      </LeftTooltip>
    </>
  );
};
