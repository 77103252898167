import { ReactionValues, TReaction } from '.';
import { TId } from '../../../shared/domain';

export class Reaction {
  private constructor(
    public id: TId,
    public reaction: ReactionValues,
    public author: string,
    public authorId: string,
    public creationDate: string,
  ) {}

  public static create(params: TReaction): Reaction {
    return new Reaction(
      params.id,
      params.reaction,
      params.author,
      params.authorId,
      params.creationDate,
    );
  }

  isAgree(): boolean {
    return this.reaction === ReactionValues.AGREE;
  }

  isDisagree(): boolean {
    return this.reaction === ReactionValues.DISAGREE;
  }

  toJSON(): TReaction {
    return {
      id: this.id,
      reaction: this.reaction,
      author: this.author,
      authorId: this.authorId,
      creationDate: this.creationDate,
    };
  }
}
