import { roles } from '../../../app/Auth/domain';

export enum RouteVisibilityAuthentication {
  authenticatedOnly = 'authenticatedOnly',
  unauthenticatedOnly = 'unauthenticatedOnly',
  always = 'always',
}

export interface RouteInterface {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<any>;
  visibility: {
    authentication: RouteVisibilityAuthentication;
    roles: roles[];
  };
}
