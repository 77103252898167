import { adminProjectsApi } from '../data/adminProjectsApi';
import {
  adminRateProjectActionTypes,
  changeFilterAdminProjectListActionTypes,
  editAdminRatingActionTypes,
  getDashboardDataActionTypes,
  listAdminProjectActionTypes,
  reactToEvaluationActionTypes,
  replyToEvaluationActionTypes,
} from './actions';
import { dashboardDataApi } from '../data/dashboardDataApi';
import { AdminRating, ReactionValues } from '../domain';
import { adminRatingProjectApi } from '../../Project/data/adminRatingProjectApi';
import { editAdminRatingProjectApi } from '../../Project/data/editAdminRatingProjectApi';
import { createEffect } from '../../../shared/redux/actionTypes/createEffect';
import { TId } from '../../../shared/domain';
import { replyToProjectEvaluationApi } from '../data/replyToProjectEvaluationApi';
import { EvaluationValues } from '../../Project/data/evaluateProjectApi';
import { reactToProjectEvaluationApi } from '../data/reactToProjectEvaluationApi';

export const adminProjectsAction = createEffect(
  listAdminProjectActionTypes,
  adminProjectsApi,
);

export const filterAdminProjectListBySegmentAction = (payload: string) => ({
  type: changeFilterAdminProjectListActionTypes.request,
  payload,
});

export const getDashboardDataAction = createEffect(
  getDashboardDataActionTypes,
  dashboardDataApi,
);
export const adminRateProjectAction = createEffect(
  adminRateProjectActionTypes,
  adminRatingProjectApi,
  ({ id, rating }: { id: TId; rating: AdminRating }) => ({ id, rating }),
);
export const editAdminRateProjectAction = createEffect(
  editAdminRatingActionTypes,
  editAdminRatingProjectApi,
  ({
    id,
    adminRatingId,
    rating,
  }: {
    id: TId;
    adminRatingId: TId;
    rating: AdminRating;
  }) => ({ id, adminRatingId, rating }),
);

export const replyToEvaluationAction = createEffect(
  replyToEvaluationActionTypes,
  replyToProjectEvaluationApi,
  ({
    projectId,
    projectEvaluationId,
    evaluationResponse,
  }: {
    projectId: TId;
    projectEvaluationId: TId;
    evaluationResponse: {
      comment: string;
      evaluation: EvaluationValues;
    };
  }) => ({ projectId, projectEvaluationId, evaluationResponse }),
);

export const reactToEvaluationAction = createEffect(
  reactToEvaluationActionTypes,
  reactToProjectEvaluationApi,
  ({
    projectId,
    evaluationId,
    evaluationReaction,
  }: {
    projectId: TId;
    evaluationId: TId;
    evaluationReaction: {
      reaction: ReactionValues;
    };
  }) => ({ projectId, evaluationId, evaluationReaction }),
);
