import { RouteInterface } from './RouteVisibility';
import { checkIfRoleIsAllowed } from '../../utils/roles';
import { roles } from '../../../app/Auth/domain';

export function isRouteFound({
  currentLocation,
  currentRole,
}: {
  currentLocation: RouteInterface | undefined;
  currentRole: roles | null;
}): boolean {
  if (!currentLocation) return true;

  let allows: roles[] = [];

  if (currentLocation && currentLocation.visibility.roles) {
    allows = currentLocation.visibility.roles as roles[];
  }

  const isAllowed = checkIfRoleIsAllowed({
    currentRole,
    allows,
  });

  return !!currentLocation && allows.length > 0 && !isAllowed;
}
