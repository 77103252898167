import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toasterTypes } from '../../../shared/constants';
import { getTranslateByScope } from '../../../shared/i18n';
import { showToasterAction } from '../../../shared/redux/actions';

import { ProjectJson } from '../domain';
import { projectActions } from '../redux/effects';

export const useUploadProjects = (): {
  isLoading: boolean;
  uploadProjects: (projects: ProjectJson[], onSuccess: () => void) => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const translate = getTranslateByScope(`ui.layouts.Import`);

  const onRequestFinished = (status: 'success' | 'failure'): void => {
    dispatch(
      showToasterAction({
        description: translate(`toasters.${status}`),
        type: toasterTypes[status],
      }),
    );
    setIsLoading(false);
  };

  const uploadProjects = (
    projects: ProjectJson[],
    onSuccess: () => void,
  ): void => {
    setIsLoading(true);
    dispatch(
      projectActions.upload({
        projects,
        onSuccess: () => {
          onRequestFinished('success');
          onSuccess();
        },
        onFailure: () => onRequestFinished('failure'),
      }),
    );
  };

  return { uploadProjects, isLoading };
};
