import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TEvaluation } from '../../Dashboard/domain';
import { TId } from '../../../shared/domain';

export const myEvaluationsByProjectApi = ({
  id,
  authenticationToken,
}: {
  id: TId;
  authenticationToken: string;
}): AxiosPromise<{ evaluations: TEvaluation[] }> =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.myEvaluations(id)),
    method: httpMethods.get,
    authenticationToken,
  });
