import { reduceListToEntities } from '../../../shared/redux/reducers/reducerHelpers';
import { getDashboardDataActionTypes } from './actions';
import {
  deleteEvaluationActionTypes,
  editEvaluationActionTypes,
  getProjectEvaluationsActionTypes,
} from '../../Project/redux/actions';
import { ActionInterface, State } from './reducerTypes';
import {
  deleteEvaluationResponseInState,
  deleteProjectEvaluationInState,
  updateEvaluationResponseInState,
  updateProjectEvaluationInState,
} from './reducerHelpers';

export const initialState: State = {
  entities: {},
};

export const dashboardReducer = (
  state: State = initialState,
  action: ActionInterface,
): State => {
  switch (action.type) {
    case getDashboardDataActionTypes.success: {
      if (action.payload && action.payload.dashboardData) {
        return {
          ...state,
          entities: reduceListToEntities(action.payload.dashboardData),
        };
      }
    }

    case getProjectEvaluationsActionTypes.success: {
      if (action.payload && action.payload.projectEvaluations) {
        const entity = state.entities[action.requestParams.id];
        const updatedEntities = {
          ...state.entities,
          [action.requestParams.id]: {
            ...entity,
            evaluations: action.payload.projectEvaluations,
          },
        };

        return { ...state, entities: updatedEntities };
      }
    }

    case editEvaluationActionTypes.success: {
      const entity = state.entities[action.requestParams.id];

      if (entity) {
        const index = entity.evaluations.findIndex(
          e => e.id === action.requestParams.evaluationId,
        );

        if (index >= 0) {
          return updateProjectEvaluationInState({
            action,
            state,
            entity,
            index,
          });
        } else {
          return updateEvaluationResponseInState({
            entity,
            state,
            action,
          });
        }
      }

      return state;
    }

    case deleteEvaluationActionTypes.success: {
      const entity = state.entities[action.requestParams.id];

      if (entity) {
        const index = entity.evaluations.findIndex(
          e => e.id === action.requestParams.evaluationId,
        );

        if (index >= 0) {
          deleteProjectEvaluationInState({
            action,
            state,
            entity,
            index,
          });
        } else {
          deleteEvaluationResponseInState({
            action,
            state,
            entity,
          });
        }
      }

      return state;
    }
  }

  return state;
};

export default dashboardReducer;
