import React from 'react';
import { Box } from '@chakra-ui/react';
import { DashboardProject } from './sampleData';
import styles from './chart.module.scss';
import { chartSizeHorizontal } from './chartConstants';
import { joinClassNames } from '../../../../../shared/utils';
import {
  getConsortiumRatingsFromProject,
  getMarketRatingsFromProject,
  getTechnologyRatingsFromProject,
  ratingWithTooltip,
} from './utils';
import { Paragraph, Truncate } from '../../../../../shared/components';
import { getTranslateByScope } from '../../../../../shared/i18n';

export const translate = getTranslateByScope('ui.layouts.Dashboard');

export const ChartBubblesHorizontal: React.FC<{
  project: DashboardProject;
}> = ({ project }) => {
  const marketRatings = getMarketRatingsFromProject(project?.topicRatings);
  const consortiumRatings = getConsortiumRatingsFromProject(
    project?.topicRatings,
  );
  const technologyRatings = getTechnologyRatingsFromProject(
    project?.topicRatings,
  );
  const isEmptyProject = (): boolean => project?.id === '';

  const medianWrapper = (median: number): JSX.Element => {
    return (
      <div
        className={styles.bubble__medianContainer}
        style={{
          position: 'absolute',
          left: ((chartSizeHorizontal - 8) * median) / 100 + 2,
          top: 12,
        }}
      >
        <span className={styles.bubble__medianHorizontal} />
      </div>
    );
  };
  const medianPointMarket = (): JSX.Element => {
    if (project?.marketRatingMedian < 0) return <></>;
    return medianWrapper(project?.marketRatingMedian);
  };

  const medianPointTechnology = (): JSX.Element => {
    if (project?.technologyRatingsMedian < 0) return <></>;
    return medianWrapper(project?.technologyRatingsMedian);
  };

  const medianPointConsortium = (): JSX.Element => {
    if (project?.consortiumRatingsMedian < 0) return <></>;
    return medianWrapper(project?.consortiumRatingsMedian);
  };

  const bubblePoint = (): JSX.Element => {
    return (
      <div className={styles.bubble__dotContainer}>
        <span className={styles.bubble__dot} />
      </div>
    );
  };

  const itemsWrapper = (
    point: ratingWithTooltip,
    index: number,
  ): JSX.Element => {
    return (
      <div key={point?.name} className={styles.header__textContainerBubble}>
        <div
          style={{
            position: 'absolute',
            top: 30,
            left: (chartSizeHorizontal * point?.value) / 100,
          }}
          className={joinClassNames(styles.header__textTooltipBubble)}
        >
          <Box display={'flex'} opacity={0.6} justifyContent={'space-between'}>
            <Truncate maxLines={1}>
              <Paragraph
                size="body"
                weight="bold"
                className={styles.chartBubbleHorizontal}
              >
                {point?.name}
              </Paragraph>
            </Truncate>
            <Paragraph size="body" weight="bold">
              {point?.value + '/' + '10'}
            </Paragraph>
          </Box>
        </div>
        <div
          key={index}
          className={joinClassNames(
            styles.bubble__container,
            isEmptyProject() ? styles.bubble__containerEmpty : null,
          )}
          style={{
            position: 'absolute',
            left: (chartSizeHorizontal * point?.value) / 100,
            top: 16,
          }}
        >
          {bubblePoint()}
        </div>
      </div>
    );
  };

  const marketRatingsItems = marketRatings.map(
    (point: ratingWithTooltip, index) => {
      return <div key={index}>{itemsWrapper(point, index)}</div>;
    },
  );

  const technologyRatingsItems = technologyRatings.map(
    (point: ratingWithTooltip, index) => {
      return <div key={index}>{itemsWrapper(point, index)}</div>;
    },
  );

  const consortiumRatingsItems = consortiumRatings.map(
    (point: ratingWithTooltip, index) => {
      return <div key={index}>{itemsWrapper(point, index)}</div>;
    },
  );

  return (
    <Box display={'flex'} padding={'15px'} marginTop={'15px'}>
      <div style={{ position: 'relative', width: '100%' }}>
        <div
          style={{
            height: 40,
            position: 'relative',
            borderBottom: '1px solid var(--lightGrey2)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box minWidth={'150px'}>
            <Paragraph size={'small'} weight={'bold'}>
              {translate('chartHorizontal.technologyTitle')}
            </Paragraph>
          </Box>
          <div style={{ position: 'relative', height: 40 }}>
            {project && technologyRatingsItems}
            {project && medianPointTechnology()}
          </div>
        </div>
        <div
          style={{
            height: 40,
            position: 'relative',
            borderBottom: '1px solid var(--lightGrey2)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box minWidth={'150px'}>
            <Paragraph size={'small'} weight={'bold'}>
              {translate('chartHorizontal.marketTitle')}
            </Paragraph>
          </Box>
          <div style={{ position: 'relative', height: 40 }}>
            {project && marketRatingsItems}
            {project && medianPointMarket()}
          </div>
        </div>
        <div
          style={{
            height: 40,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box minWidth={'150px'}>
            <Paragraph size={'small'} weight={'bold'}>
              {translate('chartHorizontal.consortiumTitle')}
            </Paragraph>
          </Box>
          <div style={{ position: 'relative', height: 40 }}>
            {project && consortiumRatingsItems}
            {project && medianPointConsortium()}
          </div>
        </div>
      </div>
    </Box>
  );
};
