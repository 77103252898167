import React, { useState } from 'react';

import {
  Box,
  ButtonIcon,
  FlexBox,
  icons,
  PrimaryButton,
  Textarea,
} from '../../../../../shared/components';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../shared/components/spacers';
import { iconColors, iconSizes } from '../../../../../shared/constants';
import { TId } from '../../../../../shared/domain';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { EvaluationValues } from '../../../../Project/data/evaluateProjectApi';
import { useReplyToEvaluation } from '../../../application/use_reply_to_evaluation';
import { TEvaluation } from '../../../domain';
import styles from './commentReplyInput.module.scss';

export const CommentReplyInput: React.FC<{
  projectId: TId;
  evaluationId: TId;
  isVisible: boolean;
  onHide: () => void;
  response?: TEvaluation;
}> = props => {
  const [evaluation, setEvaluation] = useState<EvaluationValues | undefined>(
    props.response?.evaluation,
  );
  const [commentText, setCommentText] = useState(props.response?.comment || '');
  const translate = getTranslateByScope(
    'ui.layouts.Dashboard.comments.replyInput',
  );
  const { isLoading, replyToEvaluation } = useReplyToEvaluation();

  const isActive = (e: EvaluationValues): boolean => e === evaluation;

  const toggleEvaluation = (e: EvaluationValues): void => {
    if (e === evaluation) {
      setEvaluation(undefined);
    } else {
      setEvaluation(e);
    }
  };

  const onSubmit = (): void => {
    if (evaluation && commentText) {
      replyToEvaluation({
        evaluationResponse: {
          evaluation,
          comment: commentText,
        },
        evaluationId: props.evaluationId,
        projectId: props.projectId,
        onSuccess: () => {
          setEvaluation(undefined);
          setCommentText('');
          props.onHide();
        },
      });
    }
  };

  if (!props.isVisible) return null;

  return (
    <Box marginTop="xl">
      <FlexBox.Row>
        <FlexBox.Column>
          <ButtonIcon
            size={iconSizes.md}
            onClick={(): void => toggleEvaluation(EvaluationValues.KO)}
            active={isActive(EvaluationValues.KO)}
            icon={icons.dislike}
          />
          <VerticalSpacer size="md" />
          <ButtonIcon
            size={iconSizes.md}
            onClick={(): void => toggleEvaluation(EvaluationValues.DOUBT)}
            active={isActive(EvaluationValues.DOUBT)}
            icon={icons.questionCircle}
          />
          <VerticalSpacer size="md" />
          <ButtonIcon
            size={iconSizes.md}
            onClick={(): void => toggleEvaluation(EvaluationValues.OK)}
            active={isActive(EvaluationValues.OK)}
            icon={icons.like}
          />
        </FlexBox.Column>
        <HorizontalSpacer size="md" />
        <FlexBox.Column flex={1}>
          <Textarea
            containerClassName={styles.replyTextArea}
            rows={4}
            onChange={(v: string): void => {
              setCommentText(v);
            }}
            placeholder={translate('textArea.placeholder')}
          >
            {commentText}
          </Textarea>
          <VerticalSpacer size="md" />
          <Box>
            <PrimaryButton
              className={styles.submitButton}
              loading={isLoading}
              disabled={!(commentText && evaluation)}
              onClick={onSubmit}
            >
              {translate('button.caption')}
            </PrimaryButton>
          </Box>
        </FlexBox.Column>
        <FlexBox.Column paddingLeft="md">
          <ButtonIcon
            onClick={props.onHide}
            active
            activeColor={iconColors.grey}
            icon={icons.close}
          />
        </FlexBox.Column>
      </FlexBox.Row>
    </Box>
  );
};
