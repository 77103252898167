export type IpcDTO = { supplement?: string[]; concordance: string[] };

export class Ipc {
  constructor(public concordance: string[], public supplement?: string[]) {}

  isValid(): boolean {
    if (!this.concordance || this.concordance.length === 0) return false;

    return true;
  }

  invalidProperties(): string[] {
    const invalidProps: string[] = [];

    const attributes = Object.keys(this);
    for (const index in attributes) {
      const value = this[attributes[index] as keyof this];

      if (value === undefined) {
        invalidProps.push(attributes[index]);
      }
    }

    return invalidProps;
  }

  toDTO(): IpcDTO {
    return {
      supplement: this.supplement,
      concordance: this.concordance,
    };
  }

  formattedProperty(key: keyof this): string {
    return this[key] ? ((this[key] as unknown) as string[]).join(', ') : '';
  }

  static create(params: IpcDTO): Ipc {
    return new Ipc(params?.concordance, params?.supplement);
  }
}
