const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export class ResearchCoordinators {
  constructor(public emails: string[]) {}

  isValid(): boolean {
    if (!this.emails) return false;

    return this.emails.every(email => {
      if (!email || !EMAIL_REGEX.test(email)) return false;

      return true;
    });
  }

  toDTO(): string[] {
    return this.emails;
  }

  formattedEmails(): string {
    if (!this.emails) return '';

    return this.emails.join(', ');
  }

  static create(emails: string[]): ResearchCoordinators {
    return new ResearchCoordinators(emails);
  }
}
