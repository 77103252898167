import React from 'react';
import {
  FlexBox,
  H1,
  Image,
  Paragraph,
} from '../../../../../shared/components';
import styles from './adminProjectList.module.scss';
import EmptyListImage from '../../../../../shared/assets/images/EmptyList.png';
import { translate } from '../../../../Project/presentation/components/list/translate';

export const AdminProjectListEmptyState: React.FC = () => (
  <FlexBox flexDirection="column" alignItems="center" paddingVertical="5xl">
    <FlexBox.Row flex={1} paddingVertical="sm" alignItems="center">
      <Image className={styles.projectList__emptyImage} src={EmptyListImage} />
    </FlexBox.Row>
    <FlexBox.Row flex={1} paddingVertical="lg" alignItems="center">
      <H1>{translate('Dashboard')('empty.title')}</H1>
    </FlexBox.Row>
    <Paragraph color="black2" className={styles.projectList__subtitle}>
      {translate('Dashboard')('empty.subtitle')}
    </Paragraph>
  </FlexBox>
);
