import { TClusterInstitute } from '../../domain/cluster';
import { TStackedBarChart } from '../../domain/chart';
import { getTranslateByScope } from '../../../../shared/i18n';
import { chartColors } from '../../domain/constants';

export const StackedBarChartDTO = (
  institutes: TClusterInstitute[],
  PerInstitute?: boolean,
): TStackedBarChart => {
  const translate = getTranslateByScope('ui.layouts.Reports');

  const labels = institutes.map(e => {
    return !PerInstitute
      ? e.name.split(',')[0].substring(0, 10)
      : e.name.split(',')[0].substring(0, 10) + '...';
  });

  const data = institutes.map(e => e.approved);
  const data2 = institutes.map(e => e.total - e.approved);

  return {
    labels,
    datasets: [
      {
        label: translate('legend.approved'),
        backgroundColor: chartColors.green,
        borderColor: chartColors.green,
        borderWidth: 1,
        hoverBackgroundColor: chartColors.green,
        hoverBorderColor: 'transparent',

        data: data,
      },
      {
        label: translate('legend.total'),
        backgroundColor: chartColors.grey,
        borderColor: chartColors.grey,
        borderWidth: 1,
        hoverBackgroundColor: chartColors.grey,
        hoverBorderColor: 'transparent',
        data: data2,
      },
    ],
  };
};
