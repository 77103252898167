import React from 'react';
import {
  Box,
  BoxWithUnderlinedLabel,
  FlexBox,
  H4,
  Paragraph,
  Separator,
} from '../../../../../shared/components';
import { translate } from './translate';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { TProject } from '../../../domain';

const ListWithUnderlinedLabel: React.FC<{
  items: string[];
  label: string;
}> = ({ items, label }) => (
  <BoxWithUnderlinedLabel label={translate(label)}>
    <Box>
      {items &&
        items.map((element: any, index: any) => (
          <div key={index}>
            <Paragraph color="black2" key={index}>
              • {element}
            </Paragraph>
            <VerticalSpacer size="xxs" />
          </div>
        ))}
    </Box>
  </BoxWithUnderlinedLabel>
);

export const Details: React.FC<{ project: TProject }> = ({ project }) => (
  <Box>
    <Box marginTop="lg">
      <Separator.Light />
    </Box>
    <Box marginTop="lg">
      <H4>{translate('details.label')}</H4>
    </Box>
    <FlexBox>
      <FlexBox.Column fullWidth>
        <ListWithUnderlinedLabel
          items={project.ipcConcordance}
          label={'ipcConcordance.label'}
        />
        <ListWithUnderlinedLabel
          items={project.ipcSupplement}
          label={'ipcComplement.label'}
        />

        <BoxWithUnderlinedLabel label={translate('id.label')}>
          <Paragraph color="black2">{project.sector.join(', ')}</Paragraph>
        </BoxWithUnderlinedLabel>
        <BoxWithUnderlinedLabel label={translate('cluster.label')}>
          <Paragraph color="black2">{project.cluster}</Paragraph>
        </BoxWithUnderlinedLabel>
      </FlexBox.Column>

      <FlexBox.Column fullWidth marginLeft="lg">
        <ListWithUnderlinedLabel
          items={project.additionalIndustries}
          label={'otherIndustries.label'}
        />
      </FlexBox.Column>
    </FlexBox>
  </Box>
);
