import { AxiosPromise } from 'axios';
import { TCluster, TInstitute } from '../domain/cluster';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { httpMethods } from '../../../shared/constants';

export const reportsApi = ({
  authenticationToken,
}: {
  authenticationToken: string;
}): AxiosPromise<{ clusters: TCluster[]; institutes: TInstitute[] }> =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.reports.data),
    method: httpMethods.get,
    authenticationToken,
  });
