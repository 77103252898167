import { roles } from '../../app/Auth/domain';

export const checkIfRoleIsAllowed = ({
  currentRole,
  allows,
}: {
  currentRole: roles | null;
  allows: roles[];
}): boolean => {
  const isAllowed = !!currentRole && allows.includes(currentRole);

  return isAllowed;
};
