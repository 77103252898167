import { AxiosPromise } from 'axios';
import { fetchApi } from '../../../shared/api/fetchApi';
import { endpoints } from '../../../shared/api/endpoints';
import { httpMethods } from '../../../shared/api/constants';
import { apiUrl } from '../../../shared/api/apiUrl';

export interface Response {
  account: object;
  researchCoordinator: object;
  meta: object;
}

interface Account {
  email: string;
  password: string;
}

export const signinApi = ({ account }: { account: Account }): AxiosPromise =>
  fetchApi({
    url: apiUrl(endpoints.session.signin),
    method: httpMethods.post,
    data: {
      account,
    },
  });

export default signinApi;
