import React from 'react';
import {
  Image,
  ImageContainer,
  Paragraph,
  Title,
} from '../../../../shared/components';
import { getTranslateByScope } from '../../../../shared/i18n';
import styles from './LoginForm.module.scss';

import backgroundImage from '../../../../shared/assets/images/Background.png';
import backgroundImage2 from '../../../../shared/assets/images/Background@2x.png';
import backgroundImage3 from '../../../../shared/assets/images/Background@3x.png';

import fraunhoferLogo from '../../../../shared/assets/images/fraunhofer-logo.png';
import fraunhoferLogo2 from '../../../../shared/assets/images/fraunhofer-logo@2x.png';
import fraunhoferLogo3 from '../../../../shared/assets/images/fraunhofer-logo@3x.png';

export const translate = getTranslateByScope('ui.layouts.LoginPagesBackground');

export const SessionPagesBackground = () => (
  <ImageContainer
    backgroundImage={backgroundImage}
    srcSet={`
      ${backgroundImage} +
      ' 1x ' +
      ${backgroundImage2} +
      ' 2x ' +
      ${backgroundImage3} +
      ' 3x '
    `}
  >
    <div className={styles.auth__imageContainer}>
      <Image
        src={fraunhoferLogo}
        srcSet={`
        ${fraunhoferLogo} +
        ' 1x ' +
        ${fraunhoferLogo2} +
        ' 2x ' +
        ${fraunhoferLogo3} +
        ' 3x '
      `}
        style={{
          width: 186,
          height: 32,
        }}
      />
    </div>
    <div className={styles.auth__projectContainer}>
      <Paragraph color="black2" weight="bold">
        FRAUNHOFER-GESELLSCHAFT
      </Paragraph>
    </div>
    <div className={styles.auth__titleContainer}>
      <Title>{translate('centerTitle.text')}</Title>
    </div>
  </ImageContainer>
);
