import {
  take,
  put,
  call,
  select,
  race,
  fork,
  takeEvery,
} from 'redux-saga/effects';

import { getTranslateByScope } from '../../i18n';
import { showToasterAction } from '../actions';
import { httpStatus, toasterTypes } from '../../constants';
import {
  logoutActionTypes,
  refreshTokenActionTypes,
  resetPasswordActionTypes,
  signinActionTypes,
} from '../../../app/Auth/redux/actions';
import { refreshTokenAction } from '../../../app/Auth/redux/effects';
import {
  deleteEvaluationActionTypes,
  editEvaluationActionTypes,
  editTopicRatingActionTypes,
  editTrafficLightRatingActionTypes,
  evaluateProjectActionTypes,
  getProjectEvaluationsActionTypes,
  getTopicRatingActionTypes,
  getTrafficLightRatingActionTypes,
  listProjectActionTypes,
  myEvaluationsByProjectActionTypes,
  myProjectActionTypes,
  myProjectRankingActionTypes,
  myQualifiedProjectsActionTypes,
  projectDeleteActionTypes,
  projectDetailActionTypes,
  rankProjectActionTypes,
  topicRateProjectActionTypes,
  trafficLightRateProjectActionTypes,
  uploadProjectActionTypes,
  updateResearchCoordinatorProjectActionTypes,
} from '../../../app/Project/redux/actions';
import {
  adminRateProjectActionTypes,
  editAdminRatingActionTypes,
  getDashboardDataActionTypes,
  listAdminProjectActionTypes,
  reactToEvaluationActionTypes,
  replyToEvaluationActionTypes,
} from '../../../app/Dashboard/redux/actions';
import { sessionSelectors } from '../../../app/Auth/redux/selectors';
import { getReportsActionTypes } from '../../../app/Reports/redux/actions';
import { resetUserPasswordActionTypes } from '../../../app/User/redux/actions';

const translate = getTranslateByScope('sagas.requestSaga');

export const actionsTypes = [
  refreshTokenActionTypes.request,
  resetPasswordActionTypes.request,
  resetUserPasswordActionTypes.request,
  signinActionTypes.request,
  listProjectActionTypes.request,
  uploadProjectActionTypes.request,
  projectDetailActionTypes.request,
  projectDeleteActionTypes.request,
  myProjectActionTypes.request,
  evaluateProjectActionTypes.request,
  myProjectRankingActionTypes.request,
  rankProjectActionTypes.request,
  trafficLightRateProjectActionTypes.request,
  myQualifiedProjectsActionTypes.request,
  listAdminProjectActionTypes.request,
  adminRateProjectActionTypes.request,
  editAdminRatingActionTypes.request,
  getDashboardDataActionTypes.request,
  myEvaluationsByProjectActionTypes.request,
  editEvaluationActionTypes.request,
  deleteEvaluationActionTypes.request,
  getTrafficLightRatingActionTypes.request,
  editTrafficLightRatingActionTypes.request,
  topicRateProjectActionTypes.request,
  getTopicRatingActionTypes.request,
  editTopicRatingActionTypes.request,
  getReportsActionTypes.request,
  getProjectEvaluationsActionTypes.request,
  replyToEvaluationActionTypes.request,
  reactToEvaluationActionTypes.request,
  updateResearchCoordinatorProjectActionTypes.request,
];

export const isRequestAction = (action: any): boolean => {
  return actionsTypes.includes(action.type);
};

const isUnauthenticatedError = (e: any, action: any) => {
  if (!e.response) return false;
  return (
    e.response.status === httpStatus.unauthorized &&
    action.payload.isAuthenticated
  );
};

function* retryThreeTimesFailedAction(action: any): any {
  const retryCount = action.retryCount || 0;
  if (retryCount <= 3) {
    yield put({ ...action, retryCount: retryCount + 1 });
  }
}

function* logoutAndNotifyUserThatSessionExpired(): any {
  yield put({
    type: logoutActionTypes.request,
  });
  yield put(
    showToasterAction({
      description: translate('authenticationError'),
      type: toasterTypes.failure,
    }),
  );
}

function* handleUnauthenticated(action: any): any {
  yield put({
    type: action.payload.failureActionType,
  });
  const refreshToken = yield select(sessionSelectors.refreshToken);

  yield put(refreshTokenAction({ refreshToken }));

  const { successRefreshToken, failedRefreshToken } = yield race({
    failedRefreshToken: take(refreshTokenActionTypes.failure),
    successRefreshToken: take(refreshTokenActionTypes.success),
  });

  if (successRefreshToken) {
    yield* retryThreeTimesFailedAction(action);
  }

  if (failedRefreshToken) {
    yield* logoutAndNotifyUserThatSessionExpired();
  }
}

function* callFailureCallback(action: any): any {
  if (action.payload.onFailure) {
    yield call(action.payload.onFailure);
  }
}

function* callSuccessCallback(action: any): any {
  if (action.payload.onSuccess) {
    yield call(action.payload.onSuccess);
  }
}

export function* handleRequestSaga(action: any) {
  try {
    const params = action.payload.params || {};

    let response: unknown = {} as any;

    if (action.type === refreshTokenActionTypes.request) {
      const refreshToken: string = yield select(sessionSelectors.refreshToken);

      response = yield call(action.payload.apiMethod, {
        ...params,
        refreshToken,
      });
    } else if (action.payload.isAuthenticated) {
      const authenticationToken: string = yield select(
        sessionSelectors.authenticationToken,
      );

      response = yield call(action.payload.apiMethod, {
        ...params,
        authenticationToken,
      });
    } else {
      response = yield call(action.payload.apiMethod, {
        ...params,
      });
    }

    if (response && (response as any).error) {
      throw new Error((response as any).error);
    } else {
      yield put({
        type: action.payload.successActionType,
        payload: (response as any).data,
        requestParams: action.payload.params,
      });

      yield* callSuccessCallback(action);
    }
  } catch (e) {
    if (isUnauthenticatedError(e, action)) {
      yield* handleUnauthenticated(action);
    } else {
      yield put({
        type: action.payload.failureActionType,
        payload: { error: 'unexpectedError' },
      });

      yield* callFailureCallback(action);
    }
  }
}

export function* requestSaga() {
  while (true) {
    const action: unknown = yield take(isRequestAction);
    yield fork(handleRequestSaga, action);
  }
}
