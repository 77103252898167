import { TProject } from '../../../../Project/domain';
import { DashboardResponseData } from '../../../domain';

export const removeSelectedProject = (
  projects: TProject[],
  selectedProject: DashboardResponseData | null,
): TProject[] => {
  return selectedProject
    ? projects?.filter(project => project?.id !== selectedProject?.id)
    : projects;
};

export const getSelectedProject = (
  projects: TProject[],
  selectedProject: DashboardResponseData,
): TProject => {
  return projects?.filter(project => project?.id === selectedProject?.id)[0];
};
