import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { ProjectList } from '../components/list/ProjectList';
import { useRequestOnMount } from '../../../../shared/hooks';
import { projectSelectors } from '../../redux/projectSelectors';
import { routePaths } from '../../../../shared/routes/config';
import { ProjectsLayout } from '../components/list/ProjectsLayout';
import { projectActions } from '../../redux/effects';

export const MyProjects: React.FC = () => {
  const scope = 'MyProjects';

  useRequestOnMount(projectActions.mine);
  useRequestOnMount(projectActions.qualifiedProjects);
  const [perCluster, setPerCluster] = useState(false);
  const projectsClusters = Array.from(
    new Set(useSelector(projectSelectors.getProjectsClusters)),
  );
  const projectsIds = useSelector(projectSelectors.getMyProjectsFilteredIds);

  if (!projectsIds) return null;
  return (
    <ProjectsLayout
      setPerCluster={setPerCluster}
      scope={scope}
      projectsCount={projectsIds.length}
    >
      <ProjectList
        clusters={projectsClusters}
        projectIds={projectsIds}
        scope={scope}
        projectDetailRoute={routePaths.projects.myProjectDetail}
        projectListRoute={routePaths.projects.myProjects}
        perCluster={perCluster}
      />
    </ProjectsLayout>
  );
};
