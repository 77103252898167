import React from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import styles from './dimmers.module.scss';
import { addStyle } from '../../shared/utils';

interface GreyDimmerInterface {
  isVisible: boolean;
  onClick: any;
  className?: string;
  children?: JSX.Element;
}

export const GreyDimmer = ({
  isVisible,
  onClick,
  children,
  className,
}: GreyDimmerInterface) => {
  const Dimmer = (
    <div
      tabIndex={-1}
      role="button"
      onClick={onClick}
      className={cn(
        styles.dimmer,
        addStyle(isVisible, styles.dimmerVisible),
        className,
      )}
    >
      {children}
    </div>
  );
  return ReactDOM.createPortal(Dimmer, document.body);
};
