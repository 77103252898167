import { useSelector } from 'react-redux';
import { TId } from '../../../shared/domain';
import { BaseEvaluation, TEvaluation } from '../domain';
import { dashboardSelectors } from '../redux/selectors';

export const useGetOrderedEvaluations = (
  projectId: TId,
): { getOrderedEvaluations: () => TEvaluation[] } => {
  const evaluations = useSelector(
    dashboardSelectors.getCommentsForProject(projectId),
  );

  const getOrderedEvaluations = (): TEvaluation[] => {
    const sortEvaluationByCreationDate = (
      firstEl: BaseEvaluation,
      secondEl: BaseEvaluation,
    ): number => {
      const firstDate = new Date(firstEl.creationDate);
      const secondDate = new Date(secondEl.creationDate);

      return firstDate > secondDate ? 1 : -1;
    };

    const sortedEvaluations =
      evaluations?.sort(sortEvaluationByCreationDate) || [];

    sortedEvaluations.forEach(evaluation => {
      evaluation.responses =
        evaluation.responses?.sort(sortEvaluationByCreationDate) || [];
    });

    return sortedEvaluations;
  };

  return {
    getOrderedEvaluations,
  };
};
