import React from 'react';
import { FlexBox, icons } from '../../index';
import { Item } from './Item';
import { isRouteActive } from './isRouteActive';
import { routePaths } from '../../../routes/config';
import { translate } from './translate';
import { useLocationPath, useReplaceRoute } from '../../../hooks';

const SidebarItemDashboard: React.FC = () => {
  const locationPath = useLocationPath();
  const { replaceRoute } = useReplaceRoute();

  return (
    <Item
      IconComponent={icons.dashboard}
      active={isRouteActive(locationPath, routePaths.dashboard)}
      onClick={(): void => replaceRoute(routePaths.dashboard)}
      text={translate('routes.dashboard.label')}
    />
  );
};

const SidebarItemProjects: React.FC = () => {
  const locationPath = useLocationPath();
  const { replaceRoute } = useReplaceRoute();

  return (
    <Item
      IconComponent={icons.listView}
      active={
        isRouteActive(locationPath, routePaths.projects.list) ||
        isRouteActive(locationPath, routePaths.projects.detail(':id'))
      }
      onClick={(): void => replaceRoute(routePaths.projects.list)}
      text={translate('routes.projects.label')}
    />
  );
};

const SidebarItemReports: React.FC = () => {
  const locationPath = useLocationPath();
  const { replaceRoute } = useReplaceRoute();

  return (
    <Item
      IconComponent={icons.reports}
      active={isRouteActive(locationPath, routePaths.clusters)}
      onClick={(): void => replaceRoute(routePaths.clusters)}
      text={translate('routes.reports.label')}
    />
  );
};

export const SidebarItemMyProjects: React.FC = () => {
  const locationPath = useLocationPath();
  const { replaceRoute } = useReplaceRoute();

  return (
    <Item
      IconComponent={icons.person}
      active={
        isRouteActive(locationPath, routePaths.projects.myProjects) ||
        isRouteActive(locationPath, routePaths.projects.myProjectDetail(':id'))
      }
      onClick={(): void => replaceRoute(routePaths.projects.myProjects)}
      text={translate('routes.myProjects.label')}
    />
  );
};

export const SidebarItems: React.FC = props => (
  <FlexBox.Column
    marginTop="xl"
    fullHeight
    flexDirection="column"
    alignItems="flex-end"
    justifyContent="flex-start"
  >
    <SidebarItemDashboard />
    <SidebarItemProjects />
    <React.Fragment>{props.children}</React.Fragment>
    <SidebarItemReports />
  </FlexBox.Column>
);
