import React from 'react';
import cn from 'classnames';

import styles from './inputs.module.scss';

import { addStyle, joinClassNames } from '../../shared/utils';
import { icons } from './icons';
import { FlexBox } from './boxes';
import { iconColors } from '../../shared/constants';

export const BaseInput = ({
  onChange,
  value,
  placeholder,
  type,
  hasError,
  className = cn(styles.input, hasError ? styles.error : null),
}: {
  onChange: any;
  value: string;
  placeholder?: string;
  type: string;
  hasError?: boolean;
  className?: string;
}): JSX.Element => (
  <input
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    className={className}
    type={type}
  />
);

export const PasswordInput = ({
  onChangeText,
  value,
  placeholder,
  hasError,
}: {
  onChangeText: any;
  value: string;
  placeholder: string;
  hasError?: boolean;
}): JSX.Element => (
  <BaseInput
    hasError={hasError}
    onChange={(e: any): void => {
      onChangeText(e.target.value);
    }}
    value={value}
    placeholder={placeholder}
    type="password"
  />
);

export const EmailInput = ({
  onChangeText,
  value,
  placeholder,
  hasError,
}: {
  onChangeText: any;
  value: string;
  placeholder: string;
  hasError?: boolean;
}): JSX.Element => (
  <BaseInput
    hasError={hasError}
    onChange={(e: any): void => {
      onChangeText(e.target.value);
    }}
    value={value}
    placeholder={placeholder}
    type="email"
  />
);

export const TextInput = ({
  onChangeText,
  value,
  placeholder,
  hasError,
  type,
}: {
  onChangeText: any;
  value: string;
  placeholder?: string;
  hasError?: boolean;
  type: string;
}): JSX.Element => (
  <BaseInput
    hasError={hasError}
    onChange={(e: any): void => {
      onChangeText(e.target.value);
    }}
    value={value}
    placeholder={placeholder}
    type={type}
  />
);

TextInput.defaultProps = {
  type: 'text',
};

interface TextareaInterface {
  children: string;
  onChange: any;
  placeholder?: string;
  rows?: number;
  maxLength?: number;
  className?: any;
  containerClassName?: any;
  hasError: boolean;
}

export const Textarea = (props: TextareaInterface): JSX.Element => (
  <div
    className={joinClassNames(
      props.containerClassName,
      styles.textareaContainer,
    )}
  >
    <textarea
      value={props.children}
      onChange={e => props.onChange(e.target.value)}
      placeholder={props.placeholder}
      className={joinClassNames(
        styles.textarea,
        props.className,
        addStyle(props.hasError, styles.error),
      )}
      rows={props.rows}
      maxLength={props.maxLength}
    />
  </div>
);

Textarea.defaultProps = {
  hasError: false,
};

export const SearchInput = ({
  onChangeText,
  value,
  placeholder,
  type,
  handleSubmit,
}: {
  onChangeText: any;
  value: string;
  placeholder?: string;
  type: string;
  handleSubmit: (e: any) => void;
}): JSX.Element => {
  return (
    <form onSubmit={handleSubmit}>
      <FlexBox.Row className={joinClassNames(styles.input, styles.searchRow)}>
        <icons.search
          className={cn(styles.searchIcon)}
          color={iconColors.grey}
        />
        <input
          onChange={(e: any): void => {
            onChangeText(e.target.value);
          }}
          value={value}
          placeholder={placeholder}
          type={type}
          className={cn(styles.searchInput)}
        />
      </FlexBox.Row>
    </form>
  );
};
