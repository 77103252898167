import React from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import styles from './modals.module.scss';
import { GreyDimmer } from './dimmers';
import { addStyle } from '../../shared/utils';
import {
  Box,
  ButtonIcon,
  FlexBox,
  H4,
  icons,
  If,
  LinkBox,
  PrimaryButton,
  SeparatorLight,
} from '.';
import { useEscKeyPress } from '../../shared/hooks';
import { iconColors } from '../../shared/constants';
import { translate } from '../../app/Project/presentation/components/detail/translate';
import { HorizontalSpacer, VerticalSpacer } from './spacers';

interface ModalInterface {
  isOpen: any;
  onClose: any;
  children: JSX.Element | JSX.Element[];
  style?: any;
  headerTitle: string;
}

export const WhiteModal = ({
  isOpen,
  onClose: onCancel,
  children,
  style,
  headerTitle,
}: ModalInterface): JSX.Element => {
  useEscKeyPress(onCancel);

  const Modal = (
    <GreyDimmer isVisible={isOpen} onClick={onCancel}>
      <div
        style={style}
        tabIndex={-1}
        role="button"
        onClick={(e): void => {
          e.stopPropagation();
        }}
        className={cn(styles.modal, addStyle(isOpen, styles.modalVisible))}
      >
        <If condition={isOpen}>
          {(): JSX.Element => (
            <>
              <FlexBox
                fullWidth
                alignItems="center"
                justifyContent="center"
                paddingBottom="xl"
                paddingTop="xl"
                className={styles.modalHeader}
              >
                <FlexBox paddingLeft="xl" justifyContent="flex-end">
                  <icons.placeholder />
                </FlexBox>
                <FlexBox justifyContent="center" flex={1}>
                  <H4 color="black2">{headerTitle}</H4>
                </FlexBox>
                <FlexBox paddingRight="xl" justifyContent="flex-end">
                  <LinkBox onClick={onCancel}>
                    <icons.close color={iconColors.black2} />
                  </LinkBox>
                </FlexBox>
              </FlexBox>

              <SeparatorLight />
              <Box className={styles.modalContent}>{children}</Box>
            </>
          )}
        </If>
      </div>
    </GreyDimmer>
  );
  return ReactDOM.createPortal(Modal, document.body);
};

export const WhiteModalNoHeader = ({
  isOpen,
  onClose: onCancel,
  children,
  style,
}: {
  isOpen: any;
  onClose: any;
  children: JSX.Element | JSX.Element[];
  style?: any;
}): JSX.Element => {
  useEscKeyPress(onCancel);

  const Modal = (
    <GreyDimmer isVisible={isOpen} onClick={onCancel}>
      <div
        style={style}
        tabIndex={-1}
        role="button"
        onClick={(e): void => {
          e.stopPropagation();
        }}
        className={cn(styles.modal, addStyle(isOpen, styles.modalVisible))}
      >
        <If condition={isOpen}>
          {(): JSX.Element => (
            <>
              <FlexBox
                fullWidth
                flexDirection="column"
                alignItems="flex-end"
                justifyContent="center"
                paddingTop="md"
                className={styles.modalHeader}
              >
                <FlexBox paddingRight="md" justifyContent="flex-end">
                  <LinkBox onClick={onCancel}>
                    <icons.close color={iconColors.black2} />
                  </LinkBox>
                </FlexBox>
              </FlexBox>
              <Box className={styles.modalContent}>{children}</Box>
            </>
          )}
        </If>
      </div>
    </GreyDimmer>
  );
  return ReactDOM.createPortal(Modal, document.body);
};

interface FixedBottomModalInterface {
  bottom: JSX.Element | JSX.Element[];
}

const WhiteModalFixedBottom = ({
  isOpen,
  onClose: onCancel,
  style,
  headerTitle,
  bottom,
  children,
}: ModalInterface & FixedBottomModalInterface): JSX.Element => (
  <WhiteModal
    isOpen={isOpen}
    onClose={onCancel}
    style={style}
    headerTitle={headerTitle}
  >
    <Box>
      <>{children}</>
      <Box
        paddingTop="sm"
        paddingBottom="sm"
        className={styles.modalFixedBottom}
      >
        {bottom}
      </Box>
    </Box>
  </WhiteModal>
);

WhiteModal.FixedBottom = WhiteModalFixedBottom;

interface ConfirmationModalInterface {
  isOpen: any;
  onCancel: () => void;
  onConfirm: () => void;
  children: JSX.Element | JSX.Element[];
  style?: any;
  cancelText?: string;
  confirmText?: string;
}

export const ConfirmationModal = ({
  cancelText,
  confirmText,
  isOpen,
  onConfirm,
  onCancel,
  children,
  style,
}: ConfirmationModalInterface): JSX.Element => {
  useEscKeyPress(onCancel);

  const Modal = (
    <GreyDimmer isVisible={isOpen} onClick={onCancel}>
      <div
        style={style}
        tabIndex={-1}
        role="button"
        onClick={(e): void => {
          e.stopPropagation();
        }}
        className={cn(
          styles.modal,
          addStyle(isOpen, styles.modalVisible),
          styles.confirmationModal,
        )}
      >
        <If condition={isOpen}>
          {(): JSX.Element => (
            <div className={styles.confirmationModal__container}>
              <div className={styles.confirmationModal__headerButton}>
                <ButtonIcon
                  onClick={onCancel}
                  active
                  icon={icons.close}
                  activeColor={iconColors.black2}
                />
              </div>
              {children}
              <VerticalSpacer size="xl" />
              <div className={styles.confirmationModal__buttonsSection}>
                <PrimaryButton
                  className={styles.confirmationModal__cancelButton}
                  style={{ paddingLeft: '24px', paddingRight: '24px' }}
                  onClick={onCancel}
                >
                  {cancelText || translate('modal.cancelButton')}
                </PrimaryButton>
                <HorizontalSpacer size="md" />
                <PrimaryButton
                  onClick={onConfirm}
                  style={{ paddingLeft: '24px', paddingRight: '24px' }}
                >
                  {confirmText || translate('modal.confirmButton')}
                </PrimaryButton>
              </div>
            </div>
          )}
        </If>
      </div>
    </GreyDimmer>
  );
  return ReactDOM.createPortal(Modal, document.body);
};
