import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  H1,
  Paragraph,
  Box,
  FlexBox,
  PrimaryButton,
  MidHeightSeparatorDark,
} from '../../../../shared/components';

import FormInputs from './FormInputs';

import { validateEmail } from './validateEmail';

import { toasterTypes } from '../../../../shared/constants';

import { getTranslateByScope } from '../../../../shared/i18n';

import { showToasterAction } from '../../../../shared/redux/actions';

import styles from './LoginForm.module.scss';
import { signinAction } from '../../redux/effects';
import { apiUrl } from '../../../../shared/api/apiUrl';
import { endpoints } from '../../../../shared/api/endpoints';
import { VerticalSpacer } from '../../../../shared/components/spacers';
import { useExternalLocation } from '../../../../shared/hooks';

export const translate = getTranslateByScope('ui.layouts.Login');

const LoginForm = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const { goTo } = useExternalLocation();
  const [hasSubmittedWithErrors, setHasSubmittedWithErrors] = useState(false);
  const dispatch = useDispatch();

  const handleSuccessfulLogin = (): void => {
    dispatch(
      showToasterAction({
        description: translate('toasts.successfulLogin.description'),
        type: toasterTypes.success,
      }),
    );
  };

  const handleFailedLogin = (): void => {
    dispatch(
      showToasterAction({
        description: translate('toasts.failedLogin.description'),
        type: toasterTypes.failure,
      }),
    );
  };

  const handleClick = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    const isValidEmail = validateEmail(email);
    if (isValidEmail) {
      setHasSubmittedWithErrors(false);
      dispatch(
        signinAction({
          email,
          password,
          onSuccess: handleSuccessfulLogin,
          onFailure: handleFailedLogin,
        }),
      );
    } else {
      setHasSubmittedWithErrors(true);
    }
  };

  const handleClickSSO = (): void => {
    goTo(apiUrl(endpoints.session.sso));
  };

  return (
    <FlexBox className={styles.loginForm} flexDirection="column">
      <H1 color="black">{translate('header.title')}</H1>
      <VerticalSpacer size="lg" />
      <Paragraph color="black" size="body">
        {translate('header.ssoDescription')}:
      </Paragraph>
      <VerticalSpacer size="lg" />
      <Box>
        <PrimaryButton onClick={handleClickSSO}>
          {translate('button.sso')}
        </PrimaryButton>
      </Box>
      <VerticalSpacer size="xl" />

      <FlexBox.Row className={styles.loginSeparator}>
        <MidHeightSeparatorDark />
        <Paragraph className={styles.loginSeparatorText}>oder</Paragraph>
        <MidHeightSeparatorDark />
      </FlexBox.Row>

      <VerticalSpacer size="xxxl" />
      <Paragraph color="black" size="body">
        {translate('header.description')}:
      </Paragraph>
      <FormInputs
        password={password}
        setPassword={setPassword}
        email={email}
        setEmail={setEmail}
        hasSubmittedWithErrors={hasSubmittedWithErrors}
      />
      <Box marginTop="xl">
        <PrimaryButton onClick={handleClick}>
          {translate('button.caption')}
        </PrimaryButton>
      </Box>
    </FlexBox>
  );
};

export default LoginForm;
