import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const Link = ({
  children,
  to,
  style,
}: {
  children: React.ReactNode;
  to: any;
  style?: React.CSSProperties;
}): JSX.Element => (
  <RouterLink
    style={{ ...style, textDecoration: 'none', cursor: 'pointer' }}
    to={to}
  >
    {children}
  </RouterLink>
);
