import axios, { AxiosPromise } from 'axios';
import { httpMethods } from '../constants';

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const fetchApi = ({
  url,
  data,
  method,
}: {
  url: string;
  data?: any;
  method: any;
}): AxiosPromise =>
  axios({
    method: method || httpMethods.get,
    url,
    data,
    headers: DEFAULT_HEADERS,
  });

export const fetchApiWithAuthRequest = ({
  url,
  data,
  method,
  authenticationToken,
}: {
  url: string;
  data?: any;
  method: any;
  authenticationToken: string;
}): AxiosPromise =>
  axios({
    method: method || httpMethods.get,
    url,
    data,
    headers: {
      ...DEFAULT_HEADERS,
      Authorization: `Bearer ${authenticationToken}`,
    },
  });

export const fetchApiForAWS = ({
  url,
  file,
}: {
  url: string;
  file: File;
}): AxiosPromise =>
  axios({
    method: 'PUT',
    url,
    data: file,
    headers: {
      'Content-Type': file.type,
    },
  });
