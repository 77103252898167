import React from 'react';
import { useParams, useSelector } from '../../../../shared/hooks';
import { projectSelectors } from '../../redux/projectSelectors';
import { EvaluationHeader } from '../components/evaluation/components/evaluationHeader';
import { EvaluationTTandPM } from '../components/evaluation/evaluationTTandPM';
import { EvaluationTTandPMAdmins } from '../components/evaluation/evaluationTTandPMAdmins';
import { EvaluationRC } from '../components/evaluation/evaluationRC';
import styles from '../components/evaluation/index.module.scss';
import { roleSelectors } from '../../../Auth/redux/roles';
import { TProject } from '../../domain';
import { roles } from '../../../Auth/domain';
import { EvaluationBottomBar } from '../components/evaluation/components/evaluationBottomBar';

export const ProjectEvaluation: React.FC = () => {
  const role = useSelector(roleSelectors.getRole);
  const { id = '' } = useParams<{ id: string }>();
  const project: TProject | null = useSelector(projectSelectors.getForId(id));

  const isTTorPMAdmin = (): boolean =>
    role === roles.thinkTankAdmin || role === roles.programManagerAdmin;

  const isAdmin = (): boolean => role === roles.admin;

  const isTTorPM = (): boolean =>
    role === roles.thinkTank || role === roles.programManager;

  const isRC = (): boolean => role === roles.researchCoordinator;

  if (!project) return null;

  const RoleScene = (): JSX.Element => {
    if (isTTorPM()) return <EvaluationTTandPM project={project} />;
    if (isTTorPMAdmin() || isAdmin())
      return <EvaluationTTandPMAdmins project={project} />;
    if (isRC()) return <EvaluationRC project={project} />;
    return <></>;
  };

  return (
    <div className={styles.body}>
      <EvaluationHeader project={project} />
      <RoleScene />
      <EvaluationBottomBar />
    </div>
  );
};
