import React from 'react';

import { Paragraph } from './typographies';
import { Box, FlexBox } from './boxes';

export const InputWithLabel = ({
  InputComponent,
  label,
}: {
  InputComponent: JSX.Element;
  label: string;
}): JSX.Element => (
  <FlexBox.Column fullWidth>
    <Box marginBottom="sm">
      <Paragraph size="small" color="black">
        {label}
      </Paragraph>
    </Box>
    {InputComponent}
  </FlexBox.Column>
);
