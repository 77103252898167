import React from 'react';
import { useSelector } from 'react-redux';
import { FlexBox, Paragraph } from '../../../../../shared/components';
import { HorizontalSpacer } from '../../../../../shared/components/spacers';
import { joinClassNames } from '../../../../../shared/utils';
import styles from './adminProjectList.module.scss';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { adminProjectSelectors } from '../../../redux/selectors';

const translationScope = 'ui.layouts.Dashboard';

const SummaryItem = ({
  text,
  number,
  color,
}: {
  text: string;
  number: number;
  color: string;
}) => {
  return (
    <>
      <Paragraph size="small">{text}</Paragraph>
      <HorizontalSpacer size="sm" />
      <FlexBox
        padding="xs2"
        justifyContent="center"
        alignItems="center"
        className={joinClassNames(styles.projectList__itemImage, styles[color])}
      >
        <Paragraph size="small" color="white">
          {number}
        </Paragraph>
      </FlexBox>
    </>
  );
};

export const Summary = (): JSX.Element => {
  const summary = useSelector(adminProjectSelectors.getSummary);

  return (
    <>
      <SummaryItem
        text={getTranslateByScope(translationScope)('summary.accepted')}
        number={summary.accepted}
        color="green"
      />
      <HorizontalSpacer size="lg" />
      <SummaryItem
        text={getTranslateByScope(translationScope)('summary.rejected')}
        number={summary.rejected}
        color="red"
      />
      <HorizontalSpacer size="lg" />
      <SummaryItem
        text={getTranslateByScope(translationScope)('summary.onHold')}
        number={summary.onHold}
        color="yellow"
      />
      <HorizontalSpacer size="xxl" />
    </>
  );
};
