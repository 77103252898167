import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  FlexBox,
  H4,
  If,
  Pagination,
  SearchInput,
} from '../../../../../shared/components';
import styles from './adminProjectList.module.scss';
import { DropdownItem } from '../../../../../shared/components/dropdown/dropdown.base';
import { DropdownBasicSmall } from '../../../../../shared/components/dropdown/dropdown';
import { HorizontalSpacer } from '../../../../../shared/components/spacers';
import { AdminSegments } from '../../../../../shared/constants/segments';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { useRequestOnMount } from '../../../../../shared/hooks';
import { getPaginationData } from '../../../../../shared/utils/pagination';
import { filterAdminProjectListBySegmentAction } from '../../../redux/effects';
import { AdminProjectListItem } from './adminProjectListItem';
import { AdminProjectListEmptyState } from './adminProjectListEmpty';
import { projectSelectors } from '../../../../Project/redux/projectSelectors';
import { Summary } from './summary';
import { adminProjectSelectors } from '../../../redux/selectors';
import {
  filterProjectListByTextAction,
  projectActions,
} from '../../../../Project/redux/effects';
import { TProject } from '../../../../Project/domain';
import { DashboardResponseData } from '../../../domain';
import { removeSelectedProject } from './utils';

const translationScope = 'ui.layouts.Dashboard';

const segmentTranslation = (name: string) =>
  getTranslateByScope(translationScope)(`projectSegmentDropdown.${name}`);

const ITEMS_PER_PAGE = 10;

const filterItems: DropdownItem[] = [
  {
    id: AdminSegments.allProjects,
    value: segmentTranslation(AdminSegments.allProjects),
    disabled: false,
  },
  {
    id: AdminSegments.acceptedProjects,
    value: segmentTranslation(AdminSegments.acceptedProjects),
    disabled: false,
  },
  {
    id: AdminSegments.rejectedProjects,
    value: segmentTranslation(AdminSegments.rejectedProjects),
    disabled: false,
  },
  {
    id: AdminSegments.onholdProjects,
    value: segmentTranslation(AdminSegments.onholdProjects),
    disabled: false,
  },
];

export const AdminProjectList: React.FC<{
  selectedProject: DashboardResponseData | null;
}> = ({ selectedProject }) => {
  useRequestOnMount(projectActions.adminProjects);
  const projects = useSelector(adminProjectSelectors.getProjectsFiltered);
  const filter = useSelector(adminProjectSelectors.getSegmentFilter);
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState(
    useSelector(projectSelectors.getTextFilter),
  );

  const handleChange = (e: string): void => {
    setSearchText(e);
    _.debounce(() => dispatch(filterProjectListByTextAction(e)), 150)();
  };

  const handleSearch = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    dispatch(filterProjectListByTextAction(searchText));
  };

  const {
    itemsForPage: items,
    pages,
    fromToDescription,
    totalOfPages,
  } = getPaginationData({
    pageIndex,
    itemsPerPage: ITEMS_PER_PAGE,
    items: removeSelectedProject(projects, selectedProject),
  });

  const content = (items: TProject[]) => {
    if (items.length === 0) return <AdminProjectListEmptyState />;
    return items.map(e => <AdminProjectListItem key={e.id} project={e} />);
  };

  return (
    <div className={styles.projectList__container}>
      <FlexBox justifyContent="space-between">
        <FlexBox alignItems="center">
          <H4 color="black2">{segmentTranslation('title')}</H4>
          <HorizontalSpacer size="md" />
          <DropdownBasicSmall
            initialItem={filterItems.find((e: DropdownItem) => e.id === filter)}
            placeholder={filterItems[0].value}
            items={filterItems}
            onChange={item =>
              dispatch(filterAdminProjectListBySegmentAction(item.id))
            }
          />
        </FlexBox>
        <FlexBox alignItems="center">
          <Summary />
          <SearchInput
            handleSubmit={handleSearch}
            onChangeText={handleChange}
            value={searchText}
            placeholder={'Search'}
            type={'text'}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox.Column>{content(items)}</FlexBox.Column>
      <If condition={items.length > 0}>
        {() => (
          <Pagination
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pages={pages}
            totalOfPages={totalOfPages}
            fromToDescription={fromToDescription}
            totalLength={projects.length}
            separatorLabel={getTranslateByScope(translationScope)(
              'records.separate',
            )}
            fromLabel={getTranslateByScope(translationScope)('records.from')}
            label={getTranslateByScope(translationScope)('records.projects')}
          />
        )}
      </If>
    </div>
  );
};
