import React from 'react';

import {
  ConfirmationModal,
  H4,
  Paragraph,
} from '../../../../../shared/components';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { getTranslateByScope } from '../../../../../shared/i18n';
import styles from './index.module.scss';

const translate = getTranslateByScope('ui.layouts.Projects');

export const DeleteModal: React.FC<{ modal: any; onConfirm: () => void }> = ({
  modal,
  onConfirm,
}) => {
  return (
    <ConfirmationModal
      onCancel={modal.close}
      onConfirm={onConfirm}
      isOpen={modal.isOpen}
    >
      {modal.isOpen && (
        <div className={styles.confirmationModal}>
          <H4 color="black2">{translate('delete.modal.headline')}</H4>
          <VerticalSpacer size="md" />
          <Paragraph color="black2">
            {translate('delete.modal.subheadline')}
          </Paragraph>
        </div>
      )}
    </ConfirmationModal>
  );
};
