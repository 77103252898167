import React from 'react';
import { FlexBox, Paragraph } from '../../../../../../shared/components';
import { VerticalSpacer } from '../../../../../../shared/components/spacers';
import { TopicRatingValue } from '../../../../domain';
import { RatingSelectionOption } from './ratingSelectionOption';

export const RatingSelector: React.FC<{
  title: string;
  selectedValue: TopicRatingValue | null;
  options: { [key: string]: TopicRatingValue };
  onChange: (value: TopicRatingValue | null) => void;
}> = ({ title, selectedValue, options, onChange }) => {
  return (
    <>
      <VerticalSpacer size={'lg'} />
      <Paragraph>{title}</Paragraph>
      <VerticalSpacer size={'md'} />
      <FlexBox.Row>
        {options &&
          Object.keys(options).map(option => (
            <RatingSelectionOption
              key={option}
              valueKey={option}
              value={options[option]}
              isSelected={selectedValue === options[option]}
              onClick={(value): void => onChange(value)}
            />
          ))}
      </FlexBox.Row>
    </>
  );
};
