import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { StackedBarChart } from './stackedBarChart/stackedBarChart';
import { TCluster } from '../../../domain/cluster';
import {
  FlexBox,
  icons,
  Paragraph,
  PrimaryButtonWithWidth,
} from '../../../../../shared/components';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../shared/components/spacers';
import { StackedBarChartDTO } from '../../../data/models/stackedBarChartDTO';
import { PieChart } from './pieChart/pieChart';
import { PieChartDTO } from '../../../data/models/pieChartDTO';
import { ChartLegend } from './chartLegend';
import styles from './chartReport.module.scss';
import { iconColors, iconSizes } from '../../../../../shared/constants';
import { ScreenshotService } from '../../../utils/screenshotService';
import {
  DropdownContainer,
  DropdownOutlined,
} from '../../../../../shared/components/dropdown/dropdown';
import { DropdownItem } from '../../../../../shared/components/dropdown/dropdown.base';
import { EXPORT_TYPES } from './constants';
import { getTranslateByScope } from '../../../../../shared/i18n';

export const translate = getTranslateByScope('ui.layouts.Reports.header');

export const ChartRow: React.FC<{
  cluster: TCluster;
  totalApproved: number;
}> = ({ cluster, totalApproved }) => {
  const screenshotService = new ScreenshotService();
  const data = StackedBarChartDTO(cluster.institutes);

  const [selectedExportType, setSelectedExportType] = useState<DropdownItem>(
    EXPORT_TYPES[0],
  );

  const exportScreenshot = () => {
    const element: HTMLInputElement = document.getElementById(
      cluster.name,
    ) as HTMLInputElement;
    element.checked = false;

    setTimeout(() => {
      screenshotService.captureReport(
        `capture${cluster.name}`,
        cluster.name,
        selectedExportType.value,
      );
    }, 400);
  };

  return (
    <div className={styles.report__container} id={`capture${cluster.name}`}>
      <FlexBox justifyContent="space-between">
        <Paragraph color="black2" weight="bold">
          {cluster.name}
        </Paragraph>
        <DropdownContainer
          id={`${cluster.name}`}
          label={
            <Box cursor={'pointer'} id={`${cluster.name}`}>
              <icons.download size={iconSizes.md} color={iconColors.darkGrey} />
            </Box>
          }
        >
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
            <Box marginBottom={5}>
              <Paragraph>{translate('download')}</Paragraph>
            </Box>
            <DropdownOutlined
              width={250}
              initialItem={EXPORT_TYPES[0]}
              placeholder={EXPORT_TYPES[0].value}
              items={EXPORT_TYPES}
              onChange={(item): void => {
                setSelectedExportType(item);
              }}
            />
            <Box w={'100%'} marginTop={10}>
              <PrimaryButtonWithWidth onClick={exportScreenshot}>
                {translate('downloadButton')}
              </PrimaryButtonWithWidth>
            </Box>
          </Box>
        </DropdownContainer>
      </FlexBox>
      <VerticalSpacer size="lg" />
      <FlexBox justifyContent="space-between">
        <StackedBarChart data={data} />

        <PieChart
          data={PieChartDTO(cluster)}
          title={'legend.ratioWithinCluster'}
        />
        <PieChart
          data={PieChartDTO(cluster, totalApproved)}
          title={'legend.ratioOverall'}
        />
      </FlexBox>
      <VerticalSpacer size="lg" />
      <ChartLegend />
    </div>
  );
};
