import {
  RouteInterface,
  RouteVisibilityAuthentication,
} from './RouteVisibility';
import {
  loggedOutRouteForCurrentRole,
  loggedInRouteForCurrentRole,
} from '../../constants';
import { roles } from '../../../app/Auth/domain';

const isUnauthenticatedOrMissingRoute = (
  currentLocation: RouteInterface | undefined,
): boolean =>
  currentLocation
    ? currentLocation.visibility.authentication ===
      RouteVisibilityAuthentication.unauthenticatedOnly
    : false;

const isAuthenticatedOrMissingRoute = (
  currentLocation: RouteInterface | undefined,
): boolean =>
  currentLocation
    ? currentLocation.visibility.authentication ===
      RouteVisibilityAuthentication.authenticatedOnly
    : false;

let timeout = null as any;

export const replaceRouteIfNeeded = ({
  currentRole,
  previousRole,
  isAuthenticated,
  currentLocation,
  replaceRoute,
}: {
  currentRole: roles | null;
  previousRole: roles | null;
  isAuthenticated: any;
  currentLocation: RouteInterface | undefined;
  replaceRoute: (arg1: string) => void;
}): void => {
  clearTimeout(timeout);
  const routeToReplace = () => {
    const roleToUse = currentRole || roles.researchCoordinator;
    const roleLoggedToUse = previousRole || roles.researchCoordinator;
    return isAuthenticated
      ? loggedInRouteForCurrentRole[roleToUse]
      : loggedOutRouteForCurrentRole[roleLoggedToUse];
  };
  const replaceToLoggedInRoute =
    isAuthenticated && isUnauthenticatedOrMissingRoute(currentLocation);
  const replaceToLoggedOutRoute =
    !isAuthenticated && isAuthenticatedOrMissingRoute(currentLocation);
  const shouldReplaceRoute = replaceToLoggedInRoute || replaceToLoggedOutRoute;
  if (shouldReplaceRoute) {
    timeout = setTimeout(() => {
      replaceRoute(routeToReplace());
    }, 0);
  }
};
