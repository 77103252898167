import React from 'react';

import { FlexBox, Box, Paragraph, icons } from './';

import styles from './pagination.module.scss';
import { joinClassNames, addStyle } from '../../shared/utils';

const PaginationItem = (props: {
  isActive: boolean;
  index: string;
  onClick: () => void;
}): JSX.Element => (
  <div
    tabIndex={props.isActive ? -1 : 0}
    role="button"
    onClick={props.onClick}
    className={joinClassNames(
      styles.paginationNumbers,
      addStyle(props.isActive, styles.active),
    )}
  >
    <Paragraph color={props.isActive ? 'white' : 'black'}>
      {props.index}
    </Paragraph>
  </div>
);

const PaginationNavigationItem = (props: {
  onClick: () => void;
  hasNext: boolean;
  icon: any;
  style?: any;
}): JSX.Element => (
  <div
    style={props.style}
    role="button"
    onClick={props.onClick}
    className={joinClassNames(
      styles.paginationNavigationItem,
      addStyle(!props.hasNext, styles.hidden),
    )}
  >
    <props.icon size="sm" />
  </div>
);

export const Pagination = (props: {
  pageIndex: number;
  setPageIndex: (arg1: number) => any;
  pages: object;
  totalOfPages: number;
  fromToDescription: {
    from: number;
    to: number;
  };
  totalLength: number;
  separatorLabel: string;
  fromLabel: string;
  label: string;
}): JSX.Element => (
  <FlexBox.Column justifyContent="center" alignItems="center">
    <FlexBox marginTop="lg" justifyContent="center">
      <PaginationNavigationItem
        style={{ paddingRight: 2 }}
        hasNext={props.pageIndex !== 0}
        onClick={(): void => {
          props.setPageIndex(props.pageIndex - 1);
        }}
        icon={icons.chevronLeft}
      />
      <FlexBox>
        {Object.values(props.pages).map((p: any) => (
          <Box key={p} marginLeft="lg">
            <PaginationItem
              onClick={(): void => props.setPageIndex(p - 1)}
              index={p}
              isActive={props.pageIndex === p - 1}
            />
          </Box>
        ))}
      </FlexBox>
      <Box marginLeft="lg">
        <PaginationNavigationItem
          style={{ paddingLeft: 2 }}
          hasNext={props.pageIndex + 1 < props.totalOfPages}
          onClick={() => {
            props.setPageIndex(props.pageIndex + 1);
          }}
          icon={icons.chevronRight}
        />
      </Box>
    </FlexBox>
    <FlexBox
      style={{ userSelect: 'none' }}
      marginTop="sm"
      justifyContent="center"
    >
      <Box marginRight="xs">
        <Paragraph color="darkGrey" size="tiny">
          {props.fromToDescription.from}
        </Paragraph>
      </Box>
      <Box marginRight="xs">
        <Paragraph color="darkGrey" size="tiny">
          {props.separatorLabel}
        </Paragraph>
      </Box>
      <Box marginRight="xs">
        <Paragraph color="darkGrey" size="tiny">
          {props.fromToDescription.to}
        </Paragraph>
      </Box>
      <Box marginRight="xs">
        <Paragraph color="darkGrey" size="tiny">
          {props.fromLabel}
        </Paragraph>
      </Box>
      <Paragraph color="darkGrey" size="tiny">
        {`${props.totalLength} ${props.label}`}
      </Paragraph>
    </FlexBox>
  </FlexBox.Column>
);
