import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { ProjectList } from '../components/list/ProjectList';
import { projectSelectors } from '../../redux/projectSelectors';
import { routePaths } from '../../../../shared/routes/config';
import { ProjectsLayout } from '../components/list/ProjectsLayout';
import { useRequestOnMount } from '../../../../shared/hooks';
import { projectActions } from '../../redux/effects';

export const Projects: React.FC = () => {
  const scope = 'Projects';
  useRequestOnMount(projectActions.listAll);
  useRequestOnMount(projectActions.mine);
  useRequestOnMount(projectActions.qualifiedProjects);
  const projectsIds = useSelector(projectSelectors.getProjectsFilteredIds);
  const projectsClusters = Array.from(
    new Set(useSelector(projectSelectors.getProjectsClusters)),
  );

  const projects = useSelector(projectSelectors.getMyProjects);

  const [perCluster, setPerCluster] = useState<boolean>(false);

  if (!projectsIds) return null;
  return (
    <ProjectsLayout
      setPerCluster={setPerCluster}
      scope={scope}
      projectsCount={projectsIds.length}
    >
      <ProjectList
        clusters={projectsClusters}
        perCluster={perCluster}
        projectIds={projectsIds}
        scope={scope}
        projectDetailRoute={routePaths.projects.detail}
        projectListRoute={routePaths.projects.list}
      />
    </ProjectsLayout>
  );
};
