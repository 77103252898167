import React from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line @typescript-eslint/camelcase
import jwt_decode from 'jwt-decode';
import { JWTData } from '../../redux/reducers';
import { signinActionTypes } from '../../redux/actions';
import { Paragraph } from '../../../../shared/components';

export const SSOLogin = (): JSX.Element => {
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);

  const accessToken = params.get('access_token') as string;
  const refreshToken = params.get('refresh_token');

  const decoded: JWTData = jwt_decode(accessToken);

  const data = {
    meta: {
      authenticationToken: accessToken,
      refreshToken: refreshToken,
    },
    [decoded.role as string]: {
      id: decoded.userId,
      firstName: decoded.firstName,
      lastName: decoded.lastName,
      accountId: '',
    },
  };

  dispatch({
    type: signinActionTypes.success,
    payload: data,
  });

  return <Paragraph>{}</Paragraph>;
};

export default SSOLogin;
