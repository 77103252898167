import React from 'react';
import styles from './separators.module.scss';
import { joinClassNames } from '../utils';

export const SeparatorLight = () => <div className={styles.separatorLight} />;

export const SeparatorDark = () => <div className={styles.separatorDark} />;

export const MidHeightSeparatorDark = () => (
  <div className={joinClassNames(styles.separatorDark, styles.midHeight)} />
);

export const SeparatorPrimaryColor = () => (
  <div className={styles.separatorPrimaryColor} />
);

export const Separator = {
  Light: SeparatorLight,
  Dark: SeparatorDark,
  PrimaryColor: SeparatorPrimaryColor,
};
