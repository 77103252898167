import { useDispatch } from 'react-redux';
import { TrafficLightRating } from '../domain';
import { projectActions } from '../redux/effects';

type Params = {
  projectId: string;
  trafficLightRatingId: string;
  rating: TrafficLightRating;
};

export const useUpdateTrafficLightRating = (): {
  updateTrafficLightRating: (params: Params) => void;
} => {
  const dispatch = useDispatch();

  const updateTrafficLightRating = ({
    projectId,
    trafficLightRatingId,
    rating,
  }: Params): void => {
    dispatch(
      projectActions.editTrafficLightRatingAction({
        id: projectId,
        trafficLightRatingId,
        rating,
      }),
    );
  };

  return {
    updateTrafficLightRating,
  };
};
