import React, { useState } from 'react';
import {
  Box,
  PrimaryButton,
  FlexBox,
  H1,
  PageContainer,
  TransparentButtonIconText,
  icons,
} from '../../../../../shared/components';
import { translate } from './translate';
import { SidebarLayout } from '../../../../../shared/components/sidebar-layout';
import { HorizontalSpacer } from '../../../../../shared/components/spacers';
import { iconSizes } from '../../../../../shared/constants';
import { ProjectImportModal } from '../list/ProjectImportModal';

export const ImportLayout = ({
  canImport,
  onUploadProjects,
  isLoading,
  children,
}: {
  children: JSX.Element[] | JSX.Element;
  onUploadProjects: () => void;
  isLoading: boolean;
  canImport: boolean;
}): JSX.Element => {
  const [importModalVisible, setImportModalVisible] = useState(false);

  return (
    <SidebarLayout title={translate('header.title')}>
      <>
        <ProjectImportModal
          isOpen={importModalVisible}
          onClose={(): void => setImportModalVisible(false)}
        />
        <PageContainer type="asFullWidthInBigScreen">
          <FlexBox>
            <FlexBox
              flex={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <H1 color={'black2'}>{translate('header.title')}</H1>
              <FlexBox.Row>
                <PrimaryButton
                  disabled={!canImport}
                  onClick={onUploadProjects}
                  loading={isLoading}
                >
                  {translate('buttons.import')}
                </PrimaryButton>
                <HorizontalSpacer size="lg" />
                <TransparentButtonIconText
                  onClick={(): void => setImportModalVisible(true)}
                  icon={icons.import}
                  size={iconSizes.md}
                  text={translate('buttons.chooseFile')}
                />
              </FlexBox.Row>
            </FlexBox>
          </FlexBox>
          <Box marginTop="lg">
            <React.Fragment>{children}</React.Fragment>
          </Box>
        </PageContainer>
      </>
    </SidebarLayout>
  );
};
