import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem } from '../../../../../shared/components/dropdown/dropdown.base';
import styles from './adminProjectList.module.scss';
import {
  FlexBox,
  Paragraph,
  PrimaryButton,
  Truncate,
} from '../../../../../shared/components';
import { HorizontalSpacer } from '../../../../../shared/components/spacers';
import { DropdownOutlined } from '../../../../../shared/components/dropdown/dropdown';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { showToasterAction } from '../../../../../shared/redux/actions';
import { toasterTypes } from '../../../../../shared/constants';
import { ProjectImagePlaceHolder } from '../../../../Project/presentation/components/list/ProjectImagePlaceholder';
import { AdminRating, TProjectAdminRating } from '../../../domain';
import { projectActions } from '../../../../Project/redux/effects';
import { TProject } from '../../../../Project/domain';

const translationScope = 'ui.layouts.Dashboard';

const ratingTranslation = (name: string) =>
  getTranslateByScope(translationScope)(`projectRatingDropdown.${name}`);

const dropdownItems: DropdownItem[] = [
  {
    id: AdminRating.ACCEPT,
    value: ratingTranslation(AdminRating.ACCEPT),
    disabled: false,
  },
  {
    id: AdminRating.REJECT,
    value: ratingTranslation(AdminRating.REJECT),
    disabled: false,
  },
  {
    id: AdminRating.ON_HOLD,
    value: ratingTranslation(AdminRating.ON_HOLD),
    disabled: false,
  },
];

export const AdminProjectListItem: React.FC<{
  project: TProject;
  highlitedProject?: boolean;
}> = ({ project, highlitedProject }) => {
  const dispatch = useDispatch();

  const [rating, setRating] = useState<AdminRating>();

  const onSuccess = (): void => {
    dispatch(
      showToasterAction({
        description: ratingTranslation('success'),
        type: toasterTypes.success,
      }),
    );
    dispatch(projectActions.adminProjects());
  };

  const onFailure = (): void => {
    dispatch(
      showToasterAction({
        description: ratingTranslation('error'),
        type: toasterTypes.failure,
      }),
    );
  };

  const getAdminRating = (): TProjectAdminRating | undefined => {
    const projectRatings: TProjectAdminRating[] =
      project.projectAdminRatings || [];
    return projectRatings[0];
  };

  const rate = (): void => {
    if (rating) {
      const adminRating = getAdminRating();

      if (adminRating) {
        dispatch(
          projectActions.editAdminRateProject({
            id: project.id,
            adminRatingId: adminRating.id,
            rating,
            onSuccess,
            onFailure,
          }),
        );
      } else {
        dispatch(
          projectActions.adminRateProject({
            id: project.id,
            rating,
            onSuccess,
            onFailure,
          }),
        );
      }
    }
  };

  const getInitialItem = (): DropdownItem | undefined => {
    const adminRating = getAdminRating();
    return dropdownItems.find(e => e.id === adminRating?.rating) || undefined;
  };

  return (
    <div
      className={
        highlitedProject
          ? styles.projectList__highlightedproject
          : styles.projectList__item
      }
    >
      <FlexBox alignItems="center">
        <ProjectImagePlaceHolder shortName={project.shortName} />
        <HorizontalSpacer size="md" />
        <Truncate maxLines={1}>
          <Paragraph color="black2">{project.name}</Paragraph>
        </Truncate>
      </FlexBox>
      <FlexBox alignItems="center">
        <HorizontalSpacer size="lg" />
        <DropdownOutlined
          width={250}
          initialItem={getInitialItem()}
          placeholder={ratingTranslation('placeholder')}
          items={dropdownItems}
          onChange={(item): void => setRating(item.id)}
        />
        <HorizontalSpacer size="lg" />
        <PrimaryButton onClick={rate}>Senden</PrimaryButton>
      </FlexBox>
    </div>
  );
};
