import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { Paragraph, Truncate } from '../../../../../shared/components';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { ProjectJson } from '../../../domain';
import { ErrorMessage } from '../../../domain/errorMessage';
import { joinClassNames } from '../../../../../shared/utils';
import styles from './index.module.scss';

export const ImportProjectRow = ({
  project,
}: {
  project: ProjectJson;
}): JSX.Element => {
  const translate = getTranslateByScope('ui.layouts.Import.projectsList');
  const [errors, seterrors] = useState<ErrorMessage[]>(
    project.formattedInvalidProperties(),
  );

  const checkExisting = (field: string): boolean => {
    const log = errors?.filter(val => val.attribute === field);

    return !!log && log?.length > 0;
  };

  const items = (errors: ErrorMessage[]): JSX.Element => {
    return (
      <>
        {!!errors?.length && (
          <div
            style={{
              position: 'absolute',
              left: 200,
            }}
            className={styles.header__textTooltip}
          >
            <span />

            <Box display={'flex'} justifyContent={'space-between'}>
              <Truncate maxLines={1}>
                <Paragraph
                  size="body"
                  weight="bold"
                  style={{ marginRight: '8px' }}
                >
                  {' '}
                  Errors:
                  <span>
                    {errors?.map((err, index) => {
                      return (
                        <span key={index} className={styles.errorItem}>
                          <span>
                            <span className={styles.errorItem__prop}>
                              {err.attribute}:
                            </span>
                            {err.message}
                          </span>
                        </span>
                      );
                    })}
                  </span>
                </Paragraph>
              </Truncate>
            </Box>
          </div>
        )}{' '}
      </>
    );
  };

  return (
    <>
      <tr className={styles.importProjectList__row}>
        <td
          className={joinClassNames(
            styles.importProjectList__column,
            styles.header__textContainer,
          )}
        >
          <span style={{ position: 'relative' }}> {items(errors)} </span>

          <Paragraph>
            {project.isValidImport() && errors.length != 0
              ? translate('hoverForError')
              : translate('noErrors')}
          </Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('shortName') && '#ED4337'}`,
          }}
        >
          <Paragraph>{project.shortName}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('name') && '#ED4337'}`,
          }}
        >
          <Paragraph>{project.name}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('additionalInstitutes') &&
              '#ED4337'}`,
          }}
        >
          <Paragraph>
            {project.formattedProperty('additionalInstitutes')}
          </Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('sectors') && '#ED4337'}`,
          }}
        >
          <Paragraph>{project.formattedProperty('sectors')}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('cluster') && '#ED4337'}`,
          }}
        >
          <Paragraph>{project.formattedProperty('cluster')}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('leadingInstitute') &&
              '#ED4337'}`,
          }}
        >
          <Paragraph>{project.formattedProperty('leadingInstitute')}</Paragraph>
        </td>
        <td className={styles.importProjectList__column}>
          <Paragraph>{project.applicant.fullName()}</Paragraph>
        </td>
        <td className={styles.importProjectList__column}>
          <Paragraph>{project.applicant.email}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('industries') && '#ED4337'}`,
          }}
        >
          <Paragraph>{project.formattedProperty('industries')}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('additionalIndustries') &&
              '#ED4337'}`,
          }}
        >
          <Paragraph>
            {project.formattedProperty('additionalIndustries')}
          </Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('description') && '#ED4337'}`,
          }}
        >
          <Paragraph>{project.formattedProperty('description')}</Paragraph>
        </td>
        <td className={styles.importProjectList__column}>
          <Paragraph>{project.ipc.formattedProperty('supplement')}</Paragraph>
        </td>
        <td className={styles.importProjectList__column}>
          <Paragraph>{project.ipc.formattedProperty('concordance')}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('tags') && '#ED4337'}`,
          }}
        >
          <Paragraph>{project.formattedProperty('tags')}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{
            backgroundColor: `${checkExisting('researchCoordinators') &&
              '#ED4337'}`,
          }}
        >
          <Paragraph>
            {project.researchCoordinators.formattedEmails()}
          </Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{ backgroundColor: `${checkExisting('roi') && '#ED4337'}` }}
        >
          <Paragraph>{project.formattedProperty('roi')}</Paragraph>
        </td>
        <td
          className={styles.importProjectList__column}
          style={{ backgroundColor: `${checkExisting('url') && '#ED4337'}` }}
        >
          <Paragraph>{project.formattedProperty('url')}</Paragraph>
        </td>
      </tr>
    </>
  );
};
