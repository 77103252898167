import React from 'react';
import { Paragraph } from '../../../../../shared/components';
import styles from './chart.module.scss';
import { columnSize } from './chartConstants';
import { joinClassNames } from '../../../../../shared/utils';

export const ChartColored: React.FC<{ color: string; title?: string }> = ({
  color,
  title,
}) => {
  const titleElement = (): JSX.Element => {
    if (title) {
      return (
        <Paragraph size="tiny" className={styles.coloredChart__title}>
          {title}
        </Paragraph>
      );
    }
    return <></>;
  };
  return (
    <div className={styles.coloredChart__container}>
      {titleElement()}
      <div
        className={joinClassNames(
          styles.coloredChart__box,
          styles[`coloredChart__${color.toLowerCase()}`],
        )}
        style={{ width: columnSize - 8 }}
      />
    </div>
  );
};
