/* eslint-disable @typescript-eslint/no-explicit-any */

export const getSlice = ({
  array,
  numberOfElements,
}: {
  array: any[];
  numberOfElements: number;
}): any[] => {
  if (!array) return [];
  const newArray = [...array];
  return newArray.slice(0, numberOfElements);
};
