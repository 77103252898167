import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toasterTypes } from '../../../shared/constants';
import { TId } from '../../../shared/domain';
import { getTranslateByScope } from '../../../shared/i18n';
import { showToasterAction } from '../../../shared/redux/actions';
import { projectActions } from '../redux/effects';

type Params = {
  projectId: TId;
  researchCoordinatorEmail: string;
  toRemoveResearchCoordinatorId: TId;
};

const translate = getTranslateByScope('ui.layouts.ProjectDetail');

export const useUpdateResearchCoordinatorProject = (
  onClose: Function,
): {
  updateResearchCoordinatorProject: (params: Params) => void;
} => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onRequestFinished = (status: 'success' | 'failure'): void => {
    dispatch(
      showToasterAction({
        description:
          status === 'failure'
            ? translate('changeCoordinatorModal.errorMessage')
            : translate('changeCoordinatorModal.successMessage'),
        type: toasterTypes[status],
      }),
    );

    onClose();
    setIsLoading(false);
  };

  const updateResearchCoordinatorProject = ({
    projectId,
    researchCoordinatorEmail,
    toRemoveResearchCoordinatorId,
  }: Params): void => {
    setIsLoading(true);

    dispatch(
      projectActions.updateResearchCoordinatorProject({
        projectId: projectId,
        researchCoordinatorEmail: researchCoordinatorEmail,
        toRemoveResearchCoordinatorId: toRemoveResearchCoordinatorId,
        onSuccess: () => {
          onRequestFinished('success');
        },
        onFailure: () => onRequestFinished('failure'),
      }),
    );
  };

  return {
    updateResearchCoordinatorProject,
  };
};
