import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TId } from '../../../shared/domain';
import { TopicRatingValue } from '../domain';

export const topicRatingProjectApi = ({
  id,
  authenticationToken,
  technologyRating,
  marketRating,
  consortiumRating,
}: {
  id: TId;
  authenticationToken: string;
  technologyRating: TopicRatingValue;
  marketRating: TopicRatingValue;
  consortiumRating: TopicRatingValue;
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.topicRate(id)),
    method: httpMethods.post,
    authenticationToken,
    data: { technologyRating, marketRating, consortiumRating },
  });
