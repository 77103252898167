export enum Segments {
  allProjects = 'allProjects',
  alreadyEvaluated = 'alreadyEvaluated',
  evaluationMissing = 'evaluationMissing',
  myProjects = 'myProjects',
  alreadyCommented = 'alreadyCommented',
  commentMissing = 'commentMissing',
  recentlyImported = 'recentlyImported',
  projectsPerCluster = 'projectsPerCluster',
}

export enum AdminSegments {
  allProjects = 'allProjects',
  acceptedProjects = 'acceptedProjects',
  rejectedProjects = 'rejectedProjects',
  onholdProjects = 'onholdProjects',
  projectsPerCluster = 'projectsPerCluster',
}
