/* eslint-disable @typescript-eslint/camelcase */

import { myProjectRankingActionTypes, rankProjectActionTypes } from './actions';
import { TId } from '../../../shared/domain';

export type Ranking = { [key: string]: any };

export interface State {
  by_position: Ranking;
}

interface ActionInterface {
  type: string;
  payload?: any;
  requestParams?: any;
}

export const initialState = {
  by_position: {},
};

export const projectsRankingReducer = (
  state: State = initialState,
  action: ActionInterface,
): State => {
  switch (action.type) {
    case rankProjectActionTypes.success: {
      const newRanking = action.requestParams.rankings.reduce(
        (acc: any, e: any, index: number) => {
          return { ...acc, [index + 1]: e.projectId };
        },
        {},
      );
      return {
        ...state,
        by_position: newRanking,
      };
    }
    case myProjectRankingActionTypes.success: {
      if (action.payload && action.payload.projectRanking) {
        const newRanking: Ranking = {};
        action.payload.projectRanking.forEach(
          (rank: { position: number; projectId: TId }) => {
            newRanking[rank.position] = rank.projectId;
          },
        );

        return {
          ...state,
          by_position: newRanking,
        };
      }
    }
  }

  return state;
};

export default projectsRankingReducer;
