import { Clusters } from './pages';
import { RouteVisibilityAuthentication } from '../../../shared/routes/utils/RouteVisibility';
import { roles } from '../../Auth/domain';

export const ReportsRoutes = {
  clusters: '/clusters',
};

export const ReportsRoutesConfig = [
  {
    path: ReportsRoutes.clusters,
    Component: Clusters,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [...Object.keys(roles)],
    },
  },
];
