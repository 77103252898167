export type ApplicantDTO = {
  title: string;
  email: string;
  lastName: string;
  firstName: string;
};

export class Applicant {
  constructor(
    public title: string,
    public email: string,
    public lastName: string,
    public firstName: string,
  ) {}

  isValid(): boolean {
    const attributeValues = Object.values(this);
    for (const index in attributeValues) {
      if (attributeValues[index] === undefined) return false;
    }

    return true;
  }

  invalidProperties(): string[] {
    const invalidProps: string[] = [];

    const attributes = Object.keys(this);
    for (const index in attributes) {
      const value = this[attributes[index] as keyof this];

      if (value === undefined) {
        invalidProps.push(attributes[index]);
      }
    }

    return invalidProps;
  }

  toDTO(): ApplicantDTO {
    return {
      title: this.title,
      email: this.email,
      lastName: this.lastName,
      firstName: this.firstName,
    };
  }

  fullName(): string {
    return `${this.title} ${this.firstName} ${this.lastName}`;
  }

  static create(params: {
    title: string;
    email: string;
    lastName: string;
    firstName: string;
  }): Applicant {
    return new Applicant(
      params?.title,
      params?.email,
      params?.lastName,
      params?.firstName,
    );
  }
}
