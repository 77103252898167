import React from 'react';
import { Box, FlexBox, Paragraph, Truncate } from '.';
import styles from './tags.module.scss';
import {
  getRemainingTagsNumberText,
  getSlice,
  joinClassNames,
} from '../../shared/utils';
import styles2 from '../../app/Project/presentation/components/list/index.module.scss';
import { TTag } from '../domain';
import { TProject } from '../../app/Project/domain';

const DEFAULT_NUMBER_OF_DISPLAYED_TAGS = 2;
export const Tags = ({
  tags,
  numberOfDisplayTags = DEFAULT_NUMBER_OF_DISPLAYED_TAGS,
  truncateTags,
}: {
  tags: TTag[];
  numberOfDisplayTags: number;
  truncateTags: boolean;
}): JSX.Element => {
  const slicedTags = getSlice({
    array: tags,
    numberOfElements: numberOfDisplayTags,
  });

  return (
    <FlexBox className={styles2.projectList__tags}>
      {slicedTags.map((tag: TTag) => (
        <Box key={tag.id} marginRight="sm" marginBottom="xs">
          <div className={joinClassNames(styles.tag, styles.industry)}>
            <div className={truncateTags ? styles2.projectList__card__tag : ''}>
              <Truncate maxLines={1}>
                <Paragraph size="tiny" color="white">
                  {tag.name}
                </Paragraph>
              </Truncate>
            </div>
          </div>
        </Box>
      ))}
    </FlexBox>
  );
};

interface TagsContainerInterface {
  projectTags: string[];
  truncate: boolean;
}

const extractTags = (list: string[]): TTag[] =>
  list.map((it: string) => ({ name: it, id: it } as TTag));

export const ProjectTagsContainer: React.FC<TagsContainerInterface> = props => (
  <FlexBox.Row flexWrap={true}>
    <Tags
      numberOfDisplayTags={DEFAULT_NUMBER_OF_DISPLAYED_TAGS}
      tags={extractTags(props.projectTags)}
      truncateTags={props.truncate}
    />
    <RemainingTags
      remaining={getRemainingTagsNumberText(
        props.projectTags.length,
        DEFAULT_NUMBER_OF_DISPLAYED_TAGS,
      )}
    />
  </FlexBox.Row>
);

const RemainingTags: React.FC<{ remaining: string }> = props => (
  <Box>
    <Paragraph size="tiny">{props.remaining}</Paragraph>
  </Box>
);

export const ProjectDetailTags: React.FC<{ project: TProject }> = ({
  project,
}) => (
  <Box>
    <Tags
      numberOfDisplayTags={99}
      tags={extractTags(project.tags)}
      truncateTags={false}
    />
  </Box>
);
