import React from 'react';
import styles from './radio.module.scss';
import { Paragraph } from './typographies';

export interface RadioButtonItem {
  id: any;
  value: string;
}

export const RadioButtons: React.FC<{
  items: RadioButtonItem[];
  selectedItem: RadioButtonItem | undefined;
  onChange: Function;
}> = ({ items, selectedItem, onChange }) => {
  const name = Math.random().toString();

  return (
    <>
      {items.map((e: RadioButtonItem) => (
        <label key={e.id} className={styles.container}>
          <Paragraph color="black2">{e.value}</Paragraph>
          <input
            type="radio"
            name={name}
            onChange={() => onChange(e)}
            checked={selectedItem?.id === e.id}
          />
          <span className={styles.checkmark}></span>
        </label>
      ))}
    </>
  );
};
