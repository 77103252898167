import React from 'react';
import {
  Box,
  FlexBox,
  Paragraph,
  Truncate,
} from '../../../../../shared/components';
import styles from './index.module.scss';

export const ProjectImagePlaceHolder: React.FC<{
  shortName: string;
  removeTruncate?: boolean;
}> = ({ shortName, removeTruncate }) =>
  removeTruncate ? (
    <Box className={styles.projectList__itemImageProjectDetail} padding="xs2">
      <Truncate maxLines={1}>
        <Paragraph size="tiny" color="white">
          {shortName}
        </Paragraph>
      </Truncate>
    </Box>
  ) : (
    <FlexBox
      padding="xs2"
      justifyContent="center"
      alignItems="center"
      className={styles.projectList__itemImage}
    >
      <Truncate maxLines={1}>
        <Paragraph size="tiny" color="white">
          {shortName}
        </Paragraph>
      </Truncate>
    </FlexBox>
  );
