import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { icons } from './icons';
import { iconColors, iconSizes } from '../../shared/constants';
import styles from './comment.module.scss';
import { FlexBox } from './boxes';
import { HorizontalSpacer, VerticalSpacer } from './spacers';
import { H4, Paragraph } from './typographies';
import { ButtonIcon } from './buttons';
import { ConfirmationModal } from './modals';
import { getTranslateByScope } from '../../shared/i18n';
import { EvaluationValues } from '../../app/Project/data/evaluateProjectApi';
import { TEvaluation } from '../../app/Dashboard/domain';
import { useModal } from '../hooks';
import { EvaluationCommentEdit } from '../../app/Project/presentation/components/evaluation/components/evaluationCommentEdit';
import { deleteEvaluationProjectAction } from '../../app/Project/redux/effects';
import { TProject } from '../../app/Project/domain';
import { Reactions } from './reactions';
import { TId } from '../domain';
import { Separator } from '.';

export const translate = getTranslateByScope('ui.layouts.ProjectDetail');

const CommentIcon: React.FC<{ evaluation: EvaluationValues }> = ({
  evaluation,
}) => {
  if (evaluation === EvaluationValues.OK)
    return <icons.like color={iconColors.grey} size={iconSizes.sm} />;
  if (evaluation === EvaluationValues.DOUBT)
    return <icons.questionCircle color={iconColors.grey} size={iconSizes.sm} />;
  return <icons.dislike color={iconColors.grey} size={iconSizes.sm} />;
};

export const DeleteModal: React.FC<{ modal: any; onConfirm: () => void }> = ({
  modal,
  onConfirm,
}) => {
  return (
    <ConfirmationModal
      onCancel={modal.close}
      onConfirm={onConfirm}
      isOpen={modal.isOpen}
    >
      {modal.isOpen && (
        <div>
          <H4 color="black2" style={{ textAlign: 'center' }}>
            {translate('modal.deleteCommentTitle')}
          </H4>
          <VerticalSpacer size="lg" />
          <Separator.Light />
          <VerticalSpacer size="lg" />
          <Paragraph color="black2">
            {translate('modal.deleteCommentConfirmation')}
          </Paragraph>
        </div>
      )}
    </ConfirmationModal>
  );
};

const EditButtons: React.FC<{ onEdit: Function; onDelete: Function }> = ({
  onEdit,
  onDelete,
}) => (
  <div className={styles.commentIcons}>
    <ButtonIcon
      activeColor={iconColors.grey}
      size={iconSizes.sm}
      onClick={onEdit}
      active={true}
      icon={icons.edit}
    />
    <HorizontalSpacer size="sm" />
    <ButtonIcon
      activeColor={iconColors.grey}
      size={iconSizes.sm}
      onClick={onDelete}
      active={true}
      icon={icons.delete}
    />
  </div>
);

export const Comment: React.FC<{
  evaluation: TEvaluation;
  edit?: boolean;
  reactions?: boolean;
  projectId?: TId;
  onToggleCommentReply?: () => void;
  isListLayout?: boolean;
}> = ({
  evaluation,
  projectId,
  edit = false,
  reactions = false,
  onToggleCommentReply,
  isListLayout,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const dispatch = useDispatch();
  const modal = useModal(false);
  const onConfirm = (): void => {
    if (projectId) {
      dispatch(
        deleteEvaluationProjectAction({
          id: projectId,
          evaluationId: evaluation.id,
        }),
      );
      modal.close();
    }
  };

  if (!editMode)
    return (
      <div className={styles.comment}>
        <DeleteModal modal={modal} onConfirm={onConfirm} />
        <FlexBox alignItems="flex-start">
          <div className={styles.commentContent}>
            <CommentIcon evaluation={evaluation.evaluation} />
          </div>
          <HorizontalSpacer size="lg" />
          <FlexBox.Column>
            <FlexBox alignItems="center">
              <Paragraph color="black2" weight="bold">
                {evaluation.author}
              </Paragraph>
              <HorizontalSpacer size="sm" />
              <Paragraph color="black2" size="tiny">
                ({getTranslateByScope('roles')(evaluation.role)})
              </Paragraph>
            </FlexBox>
            <VerticalSpacer size="xxs" />
            <Paragraph color="black2" size="small">
              {evaluation.comment}
            </Paragraph>
            {reactions && projectId && (
              <Reactions
                onToggleCommentReply={onToggleCommentReply}
                evaluation={evaluation}
                projectId={projectId}
              />
            )}
          </FlexBox.Column>
        </FlexBox>
        <FlexBox flexDirection="column" alignItems="flex-end" paddingRight="md">
          <Paragraph color="grey" size="tiny">
            {moment(evaluation.creationDate).format('DD.MM.YY - HH:mm')}
          </Paragraph>
          {edit && (
            <EditButtons
              onEdit={(): void => setEditMode(true)}
              onDelete={(): void => modal.open()}
            />
          )}
        </FlexBox>
      </div>
    );
  else
    return (
      <EvaluationCommentEdit
        closeEdit={(): void => setEditMode(false)}
        projectId={projectId}
        evaluationToEdit={evaluation}
        isListLayout={isListLayout}
        author={evaluation.author}
      />
    );
};
