import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  ButtonIconText,
  H4,
  icons,
  If,
  Paragraph,
} from '../../../../../shared/components';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { Graph } from './chart';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { useRequestOnMount } from '../../../../../shared/hooks';
import { Comments } from '../comments/comments';
import { DashboardResponseData } from '../../../domain';
import styles from '../comments/comments.module.scss';
import { projectActions } from '../../../../Project/redux/effects';
import { AdminProjectListItem } from '../adminProjectList/adminProjectListItem';
import { adminProjectSelectors } from '../../../redux/selectors';

import { getSelectedProject } from '../adminProjectList/utils';
import { VotesGraph } from './votesGraph';
import { DropdownOutlined } from '../../../../../shared/components/dropdown/dropdown';
import { iconSizes } from '../../../../../shared/constants';

export const translate = getTranslateByScope('ui.layouts.Dashboard');
const BUTTON_WIDTH = '152px';
const Chart: React.FC<{
  list: DashboardResponseData[];
  filteredList: DashboardResponseData[];
  project: DashboardResponseData | null;
  setProject: (project: DashboardResponseData) => void;
  filter: string | null;
}> = ({ list, filteredList, setProject, project, filter }): JSX.Element => {
  useRequestOnMount(projectActions.dashboardData);

  const [orderByRating, setOrderByRating] = useState(true);
  const projects = useSelector(adminProjectSelectors.getProjectsFiltered);

  return (
    <If condition={list !== undefined && list.length > 0}>
      {(): JSX.Element => (
        <>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Paragraph style={{ display: 'flex', alignItems: 'flex-end' }}>
              {!!filter ? filter : translate('clusterFilter.labelAll')}{' '}
            </Paragraph>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <VerticalSpacer size="sm" />
              <ButtonIconText
                onClick={(): void => setOrderByRating(!orderByRating)}
                icon={icons.order}
                size={iconSizes.md}
                text={translate(
                  `clusterFilter.${
                    orderByRating ? 'labelOrder' : 'labelCluster'
                  }`,
                )}
                style={{
                  width: BUTTON_WIDTH,
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              />
            </div>
          </Box>
          <VerticalSpacer size="lg" />
          <Graph
            projects={filteredList}
            selectedItem={project ? project : filteredList[0]}
            onItemClick={it => (it ? setProject(it) : filteredList[0])}
            orderByRating={orderByRating}
          />
          <VerticalSpacer size="lg" />
          {project && (
            <div className={styles.commentBox}>
              <H4 color="black2">{translate('chartHorizontal.title')}</H4>
              <VotesGraph projects={list} selectedProject={project} />
            </div>
          )}
          <VerticalSpacer size="lg" />
          {project && projects.length ? (
            <div className={styles.singleCommentContainer}>
              <AdminProjectListItem
                highlitedProject
                project={getSelectedProject(projects, project)}
              />
            </div>
          ) : null}
          <VerticalSpacer size="lg" />
          {project && (
            <div className={styles.commentBox}>
              <H4 color="black2">{translate('comments.title')}</H4>
              <VerticalSpacer size="sm" />
              <Comments projectId={project.id} />
            </div>
          )}
        </>
      )}
    </If>
  );
};

export default Chart;
