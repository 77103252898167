import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getTranslateByScope } from '../../../../../shared/i18n';
import { TEvaluation } from '../../../domain';
import { Comment } from '../../../../../shared/components/comment';
import styles from './comments.module.scss';
import EmptyProjectsImage from '../../../../../shared/assets/images/EmptyProjects.png';
import {
  Box,
  FlexBox,
  icons,
  Image,
  Paragraph,
  Separator,
} from '../../../../../shared/components';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../shared/components/spacers';
import { CommentReplyInput } from './commentReplyInput';
import { TId } from '../../../../../shared/domain';
import { Evaluation as DMEvaluation } from '../../../domain/evaluation';
import { useGetOrderedEvaluations } from '../../../application/use_get_ordered_evaluations';
import { iconColors, iconSizes } from '../../../../../shared/constants';
import { sessionSelectors } from '../../../../Auth/redux/selectors';
import { TUser } from '../../../../Auth/domain';

export const translate = getTranslateByScope('ui.layouts.Dashboard.comments');

const Response: React.FC<{ response: DMEvaluation; projectId: TId }> = ({
  response,
  projectId,
}) => {
  const currentUser = useSelector(sessionSelectors.currentUser);

  return (
    <Comment
      projectId={projectId}
      evaluation={response.toJSON()}
      reactions
      edit={response.belongsToUser(currentUser?.id)}
    />
  );
};

const Evaluation: React.FC<{ evaluation: TEvaluation; projectId: TId }> = ({
  evaluation,
  projectId,
}) => {
  const [isCommentReplyVisible, setIsCommentReplyVisible] = useState(false);
  const [areSubcommentsVisible, setAreSubcommentsVisible] = useState(false);
  const [shouldScrollToComment, setShouldScrollToComment] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const currentUser: TUser | null = useSelector(sessionSelectors.currentUser);
  const isUserAuthor = evaluation?.authorId === currentUser?.id;

  useEffect(() => {
    if (shouldScrollToComment) {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setShouldScrollToComment(false);
    }
  }, [shouldScrollToComment]);

  return (
    <>
      <Comment
        projectId={projectId}
        reactions
        evaluation={evaluation}
        onToggleCommentReply={(): void => {
          setAreSubcommentsVisible(true);
          setIsCommentReplyVisible(true);
          setShouldScrollToComment(true);
        }}
        edit={isUserAuthor}
        isListLayout
      />
      <Box marginLeft="5xl2">
        {evaluation.responses.length > 0 && (
          <FlexBox.Row
            className={styles.collapseToggle}
            onClick={(): void =>
              setAreSubcommentsVisible(!areSubcommentsVisible)
            }
          >
            {areSubcommentsVisible ? (
              <icons.chevronUp size={iconSizes.xs2} color={iconColors.blue} />
            ) : (
              <icons.chevronDown size={iconSizes.xs2} color={iconColors.blue} />
            )}
            <HorizontalSpacer size="sm" />
            <Paragraph color="blue">
              {areSubcommentsVisible
                ? translate('collapseOff')
                : translate('collapseOn')}
            </Paragraph>
          </FlexBox.Row>
        )}
        {areSubcommentsVisible &&
          evaluation.responses.map(response => (
            <Response
              key={response.id}
              projectId={projectId}
              response={DMEvaluation.create(response)}
            />
          ))}
        {areSubcommentsVisible && isCommentReplyVisible && <Separator.Light />}
        <CommentReplyInput
          projectId={projectId}
          evaluationId={evaluation.id}
          isVisible={isCommentReplyVisible}
          onHide={(): void => setIsCommentReplyVisible(false)}
        />
        <div ref={scrollRef} />
      </Box>
    </>
  );
};

export const Comments: React.FC<{
  projectId: TId;
}> = ({ projectId }) => {
  const { getOrderedEvaluations } = useGetOrderedEvaluations(projectId);
  const evaluations = getOrderedEvaluations();

  return evaluations.length ? (
    <>
      {evaluations.map((evaluation: TEvaluation, index: number) => (
        <div key={evaluation.id}>
          <Evaluation evaluation={evaluation} projectId={projectId} />
          <VerticalSpacer size="lg" />
          {index !== evaluations.length - 1 && <Separator.Light />}
        </div>
      ))}
    </>
  ) : (
    <EmptyComments />
  );
};

const EmptyComments: React.FC<{}> = () => {
  return (
    <>
      <div className={styles.empty__container}>
        <Image className={styles.empty__image} src={EmptyProjectsImage} />
        <HorizontalSpacer size="6xl" />
        <div>
          <Paragraph weight="bold">{translate('emptyTitle')}</Paragraph>
          <VerticalSpacer size="sm" />
          <Paragraph size="small">{translate('emptyText')}</Paragraph>
        </div>
      </div>
    </>
  );
};
