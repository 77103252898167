import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TId } from '../../../shared/domain';
import { ReactionValues } from '../domain';

export const reactToProjectEvaluationApi = ({
  projectId,
  evaluationId,
  authenticationToken,
  evaluationReaction,
}: {
  projectId: TId;
  evaluationId: TId;
  authenticationToken: string;
  evaluationReaction: {
    reaction: ReactionValues;
  };
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.reactToEvaluation(projectId, evaluationId)),
    method: httpMethods.post,
    authenticationToken,
    data: { evaluationReaction },
  });
