import React from 'react';
import { translate } from './translate';
import { ProjectImport } from './ProjectImport';

export const ProjectListEmptyState: React.FC = () => {
  return (
    <ProjectImport
      title={translate('Projects')('empty.title')}
      subtitle={translate('Projects')('empty.subtitle')}
    />
  );
};
