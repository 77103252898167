/* eslint-disable @typescript-eslint/camelcase */

import jwt_decode from 'jwt-decode';
import { refreshTokenActionTypes, signinActionTypes } from './actions';
import {
  TAdmin,
  TProgramManager,
  TProgramManagerAdmin,
  TResearchCoordinator,
  TThinkTank,
  TThinkTankAdmin,
} from '../domain';

export type State = {
  authenticationToken?: string;
  refreshToken?: string;
  currentUser:
    | TThinkTank
    | TProgramManager
    | TResearchCoordinator
    | TProgramManagerAdmin
    | TThinkTankAdmin
    | TAdmin
    | null;
};

export interface JWTData {
  userId: string;
  firstName: string;
  lastName: string;
  email?: string;
  role: string;
}

type Action = {
  type: string;
  payload: {
    meta?: {
      authenticationToken: string;
      refreshToken: string;
    };
    researchCoordinator?: TResearchCoordinator;
    thinkTank?: TThinkTank;
    programManager?: TProgramManager;
    thinkTankAdmin?: TThinkTankAdmin;
    programManagerAdmin?: TProgramManagerAdmin;
    admin?: TAdmin;
  };
};

export const initialState: State = {
  authenticationToken: undefined,
  refreshToken: undefined,
  currentUser: null,
};

const extractMeta = (action: Action) => {
  const { meta } = action.payload;

  return {
    authenticationToken: meta && meta.authenticationToken,
    refreshToken: meta && meta.refreshToken,
  };
};

export const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case refreshTokenActionTypes.success: {
      return { ...state, ...extractMeta(action) };
    }
    case signinActionTypes.success: {
      const token = action.payload.meta?.authenticationToken || null;
      if (token) {
        const decoded: JWTData = jwt_decode(token);

        if (decoded.userId)
          return {
            ...state,
            ...extractMeta(action),
            currentUser: {
              id: decoded.userId,
              firstName: decoded.firstName,
              lastName: decoded.lastName,
              email: decoded?.email,
            },
          };
      }

      return state;
    }

    default:
      return state;
  }
};
