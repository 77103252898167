/* eslint-disable @typescript-eslint/camelcase */

import { getTrafficLightRatingActionTypes } from './actions';
import { TrafficLightRating } from '../domain';
import { TId } from '../../../shared/domain';

export type TrafficLightRate = {
  id: TId;
  rating: TrafficLightRating;
  projectId: TId;
};

export interface State {
  by_project_id: { [key: string]: TrafficLightRate };
}

interface ActionInterface {
  type: string;
  payload?: any;
}

export const initialState: State = {
  by_project_id: {},
};

export const projectTrafficLightRatingReducer = (
  state: State = initialState,
  action: ActionInterface,
): State => {
  switch (action.type) {
    case getTrafficLightRatingActionTypes.success: {
      if (action.payload && action.payload.trafficLightRating) {
        const rating: TrafficLightRate = action.payload.trafficLightRating;
        return {
          ...state,
          by_project_id: { ...state.by_project_id, [rating.projectId]: rating },
        };
      }
    }
  }

  return state;
};

export default projectTrafficLightRatingReducer;
