import { ReactionValues, TEvaluation } from '.';
import { TId } from '../../../shared/domain';
import { EvaluationValues } from '../../Project/data/evaluateProjectApi';
import { Reaction } from './reaction';

export class Evaluation {
  private constructor(
    public id: string,
    public comment: string,
    public evaluation: EvaluationValues,
    public creationDate: string,
    public author: string,
    public authorId: string,
    public role: string,
    public reactions: Reaction[],
    public responses: Evaluation[],
  ) {}

  public static create(params: TEvaluation): Evaluation {
    return new Evaluation(
      params.id,
      params.comment,
      params.evaluation,
      params.creationDate,
      params.author,
      params.authorId,
      params.role,
      params.reactions?.map(r => Reaction.create(r)) || [],
      params.responses?.map(r => Evaluation.create(r)) || [],
    );
  }

  getLikes(): Reaction[] {
    return this.reactions.filter(r => r.reaction === ReactionValues.AGREE);
  }

  getDislikes(): Reaction[] {
    return this.reactions.filter(r => r.reaction === ReactionValues.DISAGREE);
  }

  toJSON(): TEvaluation {
    return {
      id: this.id,
      comment: this.comment,
      evaluation: this.evaluation,
      creationDate: this.creationDate,
      author: this.author,
      authorId: this.authorId,
      role: this.role,
      reactions: this.reactions.map(reaction => reaction.toJSON()),
      responses: this.responses.map(response => response.toJSON()),
    };
  }

  belongsToUser(userId?: TId): boolean {
    return userId === this.authorId;
  }
}
