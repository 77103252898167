import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { Ranking } from '../redux/projectsRankingReducer';

export const rankProjectApi = ({
  rankings,
  authenticationToken,
}: {
  authenticationToken: string;
  rankings: Ranking[];
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.rank),
    method: httpMethods.post,
    authenticationToken,
    data: { ranking: rankings },
  });
