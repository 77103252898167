import React from 'react';
import { Paragraph } from '../../index';
import styles from '../styles.module.scss';
import { addStyle, joinClassNames } from '../../../utils';
import { iconColors, keys } from '../../../constants';

export const Item = ({
  text,
  active,
  onClick,
  IconComponent,
  iconColor,
}: {
  text: string;
  active?: boolean;
  onClick?: any;
  IconComponent: any;
  iconColor?: iconColors;
}): JSX.Element => (
  <div
    role="button"
    tabIndex={active ? -1 : 0}
    onKeyPress={e => {
      if (e.key === keys.Enter) onClick();
    }}
    onClick={onClick}
    className={joinClassNames(
      styles.itemContainer,
      addStyle(!!active, styles.activeItemContainer),
    )}
  >
    <IconComponent
      color={iconColor || iconColors.black}
      size="sm"
      className={active ? styles.sidebarActiveItem : undefined}
    />
    <div className={styles.item}>
      <Paragraph className={active ? styles.sidebarActiveItem : undefined}>
        {text}
      </Paragraph>
    </div>
  </div>
);
