import React from 'react';
import { SpacingsEnum } from './boxesStyles';
import { spacings } from '../../shared/constants';

interface SpaceVerticalProps {
  size: SpacingsEnum;
}
export const VerticalSpacer: React.FC<SpaceVerticalProps> = props => {
  return (
    <div
      style={{ height: spacings[props.size], minHeight: spacings[props.size] }}
    />
  );
};

export const HorizontalSpacer: React.FC<SpaceVerticalProps> = props => {
  return (
    <div
      style={{ width: spacings[props.size], minWidth: spacings[props.size] }}
    />
  );
};
