import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TId } from '../../../shared/domain';
import { TrafficLightRating } from '../domain';

export const editTrafficLightRatingProjectApi = ({
  id,
  trafficLightRatingId,
  authenticationToken,
  rating,
}: {
  id: TId;
  trafficLightRatingId: TId;
  authenticationToken: string;
  rating: TrafficLightRating;
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(
      endpoints.projects.editTrafficLightRate(id, trafficLightRatingId),
    ),
    method: httpMethods.put,
    authenticationToken,
    data: { rating },
  });
