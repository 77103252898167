import { showToasterActionTypes } from '../actions';
import { TToasterTypes } from '../../domain';

interface State {
  description: string | null;
  type?: TToasterTypes | null;
}

interface Action {
  type: string;
  payload: {
    description: string | null;
    type?: TToasterTypes;
  };
}

const initialState = {
  description: null,
  type: null,
};

export const toaster = (state: State = initialState, action: Action): State => {
  if (action.type === showToasterActionTypes.request) {
    return {
      description: action.payload.description,
      type: action.payload.type,
    };
  }

  return state;
};

export default toaster;
