/* eslint-disable @typescript-eslint/camelcase */
import { setProjectsUploadFileActionTypes } from './actions';

export interface State {
  file: File | {};
}

interface ActionInterface {
  type: string;
  payload?: any;
  requestParams?: any;
}

export const initialState = {
  file: {},
};

export const projectsUploadReducer = (
  state: State = initialState,
  action: ActionInterface,
): State => {
  switch (action.type) {
    case setProjectsUploadFileActionTypes.request: {
      return {
        ...state,
        file: action.payload,
      };
    }
  }

  return state;
};

export default projectsUploadReducer;
