import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TId } from '../../../shared/domain';
import { EvaluationValues } from '../../Project/data/evaluateProjectApi';

export const replyToProjectEvaluationApi = ({
  projectId,
  projectEvaluationId,
  authenticationToken,
  evaluationResponse,
}: {
  projectId: TId;
  projectEvaluationId: TId;
  authenticationToken: string;
  evaluationResponse: {
    comment: string;
    evaluation: EvaluationValues;
  };
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(
      endpoints.projects.replyToEvaluation(projectId, projectEvaluationId),
    ),
    method: httpMethods.post,
    authenticationToken,
    data: { evaluationResponse },
  });
