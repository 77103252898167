import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

const spinnerColor = '#ffffff';
const primaryColor = '#409280';

const WhiteSpinner: React.FC<{}> = () => (
  <PulseLoader color={spinnerColor} loading size={8} />
);

const PrimarySpinner: React.FC<{}> = () => (
  <PulseLoader color={primaryColor} loading size={8} />
);

export const Spinners = {
  White: WhiteSpinner,
  Primary: PrimarySpinner,
};
