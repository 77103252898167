import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TId } from '../../../shared/domain';

export const deleteProjectApi = ({
  id,
  authenticationToken,
}: {
  id: TId;
  authenticationToken: string;
}): AxiosPromise =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.delete(id)),
    method: httpMethods.delete,
    authenticationToken,
  });
