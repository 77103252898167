import { matchPath } from 'react-router';
import { appRoutesConfig } from '../config';

export const findRouteByLocationPath = (locationPath: string) => {
  return appRoutesConfig.find(route =>
    matchPath(locationPath, {
      path: route.path,
      exact: true,
      strict: false,
    }),
  );
};
