import { useState } from 'react';

export const useModal = (initialState: boolean): any => {
  const [isOpen, setOpen] = useState(initialState);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  return {
    isOpen,
    open,
    close,
  };
};
