import React from 'react';
import {
  Box,
  EmailInput,
  Paragraph,
  WhiteModal,
  FlexBox,
  PrimaryButton,
} from '../../../../../../shared/components';
import { HorizontalSpacer } from '../../../../../../shared/components/spacers';
import { getTranslateByScope } from '../../../../../../shared/i18n';
import { useUpdateResearchCoordinatorProject } from '../../../../application/use_update_research_coordinator_project';

interface Interface {
  onClose: Function;
  onChange: Function;
  isOpen: boolean;
  value: string;
  hasError: boolean;
  projectId: string;
  toRemoveResearchCoordinatorId: string | undefined;
}
export const translate = getTranslateByScope('ui.layouts.ProjectDetail');

export const ChangeResearchCoordinatorModal = ({
  isOpen,
  onClose,
  onChange,
  value,
  hasError,
  projectId,
  toRemoveResearchCoordinatorId,
}: Interface): JSX.Element => {
  const {
    updateResearchCoordinatorProject,
  } = useUpdateResearchCoordinatorProject(onClose);
  return (
    <WhiteModal
      onClose={onClose}
      isOpen={isOpen}
      headerTitle={translate('changeCoordinatorModal.title')}
    >
      <Box paddingVertical={'lg'} paddingHorizontal={'xxl'}>
        <Paragraph>
          {translate('changeCoordinatorModal.descriptionFirstSection')}
        </Paragraph>
        <Paragraph>
          {translate('changeCoordinatorModal.descriptionSectionSection')}
        </Paragraph>
        <Box marginTop={'md'}>
          <EmailInput
            value={value}
            onChangeText={onChange}
            placeholder={translate('changeCoordinatorModal.textPlaceholder')}
            hasError={hasError}
          />
          {hasError && (
            <Paragraph color={'red'}>
              {translate('changeCoordinatorModal.errorMessage')}
            </Paragraph>
          )}
          <FlexBox justifyContent="end" marginTop={'md'}>
            <PrimaryButton
              style={{
                paddingLeft: '24px',
                paddingRight: '24px',
                background: 'transparent',
                color: '#333333',
              }}
              color="black2"
              onClick={(): void => onClose()}
            >
              {translate('changeCoordinatorModal.closeButton')}
            </PrimaryButton>
            <HorizontalSpacer size="lg" />
            <PrimaryButton
              onClick={(): void =>
                toRemoveResearchCoordinatorId
                  ? updateResearchCoordinatorProject({
                      projectId: projectId,
                      researchCoordinatorEmail: value,
                      toRemoveResearchCoordinatorId: toRemoveResearchCoordinatorId,
                    })
                  : undefined
              }
              disabled={
                hasError || value === '' || !toRemoveResearchCoordinatorId
              }
            >
              {translate('changeCoordinatorModal.addButton')}
            </PrimaryButton>
          </FlexBox>
        </Box>
      </Box>
    </WhiteModal>
  );
};
