import { DropdownItem } from '../../../../../shared/components/dropdown/dropdown.base';

export const EXPORT_TYPE_PNG = 'PNG';
export const EXPORT_TYPE_SVG = 'SVG';
export const EXPORT_TYPES: DropdownItem[] = [
  {
    id: EXPORT_TYPE_PNG,
    value: EXPORT_TYPE_PNG,
  },
  {
    id: EXPORT_TYPE_SVG,
    value: EXPORT_TYPE_SVG,
  },
];
