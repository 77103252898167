import React from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Image } from '../../../../../shared/components';
import EmptyListImage from '../../../../../shared/assets/images/EmptyList.png';
import styles from './index.module.scss';

export const UploadProgressBar = ({
  value,
}: {
  value: number;
}): JSX.Element => {
  return (
    <div style={{ width: 200, height: 200 }}>
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={3}
        styles={buildStyles({
          rotation: 0,
          strokeLinecap: 'butt',
          pathTransitionDuration: 0.5,
          trailColor: '#F3F3F3',
          pathColor: '#409280',
          backgroundColor: '#409280',
        })}
      >
        <Image
          className={styles.upload__emptyImage}
          src={EmptyListImage}
          style={{ width: '40%', height: '40%' }}
        />
      </CircularProgressbarWithChildren>
    </div>
  );
};
