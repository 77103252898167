import PasswordReset from './pages/resetPassword';
import { RouteVisibilityAuthentication } from '../../../shared/routes/utils/RouteVisibility';
import Login from './pages/login';
import SSOLogin from './components/SSOLogin';

export const AuthRoutes = {
  login: '/login',
  passwordReset: '/password-reset',
  ssoLogin: '/sso/',
};

export const AuthRoutesConfig = [
  {
    path: AuthRoutes.passwordReset,
    Component: PasswordReset,
    visibility: {
      authentication: RouteVisibilityAuthentication.always,
    },
  },
  {
    path: AuthRoutes.ssoLogin,
    Component: SSOLogin,
    visibility: {
      authentication: RouteVisibilityAuthentication.unauthenticatedOnly,
    },
  },
  {
    path: AuthRoutes.login,
    Component: Login,
    visibility: {
      authentication: RouteVisibilityAuthentication.unauthenticatedOnly,
    },
  },
  {
    path: '/',
    Component: Login,
    visibility: {
      authentication: RouteVisibilityAuthentication.unauthenticatedOnly,
    },
  },
];
