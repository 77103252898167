import React from 'react';
import { Box, FlexBox, H1 } from '../../../../../shared/components';
import styles from './index.module.scss';
import { TProject } from '../../../domain';

export const Header: React.FC<{ project: TProject }> = ({ project }) => (
  <FlexBox alignItems="center" marginTop="lg" marginBottom="lg">
    <Box>
      <H1 className={styles.projectTile}>{project.name}</H1>
    </Box>
  </FlexBox>
);
