/* eslint-disable @typescript-eslint/camelcase */

import {
  idsInsert,
  byKeyInsert,
} from '../../../shared/redux/reducers/reducerHelpers';
import { ViewMode } from '../../../shared/constants/viewMode';
import { Segments } from '../../../shared/constants/segments';
import { TEvaluation } from '../../Dashboard/domain';
import {
  changeProjectViewModeActionTypes,
  deleteEvaluationActionTypes,
  editEvaluationActionTypes,
  filterBySegmentProjectListActionTypes,
  filterByTextProjectListActionTypes,
  listProjectActionTypes,
  myEvaluationsByProjectActionTypes,
  myProjectActionTypes,
  myQualifiedProjectsActionTypes,
  projectDeleteActionTypes,
  projectDetailActionTypes,
  setEvaluationCommentStateActionTypes,
  setEvaluationModalVisibilityActionTypes,
  uploadProjectActionTypes,
} from './actions';
import { TId } from '../../../shared/domain';

export interface State {
  ids: TId[];
  my_ids: TId[];
  recentlyImportedIds: TId[];
  commentedIds: TId[];
  qualifiedIds: TId[];
  evaluations: { [key: string]: TEvaluation[] };
  byId: {};
  viewMode?: ViewMode;
  hasUnsavedComment: boolean;
  showEvaluationModal: boolean;
  filters: {
    text: string;
    segment: string;
  };
}

interface ActionInterface {
  type: string;
  payload?: any;
  requestParams?: any;
}

export const initialState = {
  ids: [],
  my_ids: [],
  recentlyImportedIds: [],
  commentedIds: [],
  qualifiedIds: [],
  evaluations: {},
  byId: {},
  hasUnsavedComment: false,
  showEvaluationModal: false,
  viewMode: ViewMode.list,
  filters: {
    text: '',
    segment: Segments.allProjects,
  },
};

export const projectsReducer = (
  state: State = initialState,
  action: ActionInterface,
): State => {
  switch (action.type) {
    case myEvaluationsByProjectActionTypes.success: {
      if (action.payload && action.payload.evaluations) {
        return {
          ...state,
          evaluations: {
            ...state.evaluations,
            [action.requestParams.id]: action.payload.evaluations,
          },
        };
      }
      break;
    }
    case deleteEvaluationActionTypes.success: {
      if (state.evaluations[action.requestParams.id]) {
        return {
          ...state,
          evaluations: {
            ...state.evaluations,
            [action.requestParams.id]: state.evaluations[
              action.requestParams.id
            ].filter(
              (e: TEvaluation) => e.id !== action.requestParams.evaluationId,
            ),
          },
        };
      } else {
        return state;
      }
    }
    case editEvaluationActionTypes.success: {
      if (state.evaluations[action.requestParams.id]) {
        return {
          ...state,
          evaluations: {
            ...state.evaluations,
            [action.requestParams.id]: state.evaluations[
              action.requestParams.id
            ].map((e: TEvaluation) =>
              e.id === action.requestParams.evaluationId
                ? {
                    ...e,
                    comment: action.requestParams.comment,
                    evaluation: action.requestParams.evaluation,
                  }
                : e,
            ),
          },
        };
      } else {
        return state;
      }
    }
    case listProjectActionTypes.success: {
      if (action.payload && action.payload.projects) {
        return {
          ...state,
          ids: idsInsert(initialState.ids, action.payload.projects),
          my_ids: state.my_ids,
          byId: byKeyInsert(initialState.byId, action.payload.projects),
        };
      }
      break;
    }
    case uploadProjectActionTypes.success: {
      if (action.payload && action.payload.projects) {
        return {
          ...state,
          ids: idsInsert(state.ids, action.payload.projects),
          recentlyImportedIds: idsInsert(
            initialState.ids,
            action.payload.projects,
          ),
          byId: byKeyInsert(state.byId, action.payload.projects),
        };
      }
      break;
    }
    case myProjectActionTypes.success: {
      if (action.payload && action.payload.projects) {
        return {
          ...state,
          ids: idsInsert(state.ids, action.payload.projects),
          my_ids: idsInsert(initialState.ids, action.payload.projects),
          byId: byKeyInsert(state.byId, action.payload.projects),
        };
      }
      break;
    }
    case projectDetailActionTypes.success: {
      if (action.payload && action.payload.project) {
        return {
          ...state,
          ids: state.ids,
          my_ids: state.my_ids,
          byId: byKeyInsert(state.byId, [action.payload.project]),
        };
      }
      break;
    }
    case projectDeleteActionTypes.success: {
      const ids = state.ids.filter(id => id !== action.requestParams.id);
      const my_ids = state.my_ids.filter(id => id !== action.requestParams.id);
      const byId: any = { ...state.byId };
      delete byId[action.requestParams.id];

      return {
        ...state,
        ids,
        my_ids,
        byId,
      };
    }
    case myQualifiedProjectsActionTypes.success: {
      if (action.payload && action.payload.qualifications) {
        return {
          ...state,
          commentedIds: action.payload.qualifications.evaluated,
          qualifiedIds: action.payload.qualifications.qualified,
        };
      }
      break;
    }
    case changeProjectViewModeActionTypes.request: {
      return {
        ...state,
        viewMode: action.payload,
      };
    }
    case filterByTextProjectListActionTypes.request: {
      return {
        ...state,
        filters: {
          ...state.filters,
          text: action.payload,
        },
      };
    }

    case filterBySegmentProjectListActionTypes.request: {
      return {
        ...state,
        filters: {
          ...state.filters,
          segment: action.payload,
        },
      };
    }
    case setEvaluationCommentStateActionTypes.request: {
      return {
        ...state,
        hasUnsavedComment: action.payload,
      };
    }
    case setEvaluationModalVisibilityActionTypes.request: {
      return {
        ...state,
        showEvaluationModal: action.payload,
      };
    }
  }

  return state;
};

export default projectsReducer;
