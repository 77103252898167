import { getTranslateByScope } from '../i18n';

export const translate = getTranslateByScope('ui.tags');

export const getRemainingTagsNumberText = (
  tagsLength: number | undefined,
  numberOfDisplayTags: number | undefined,
): string => {
  if (!tagsLength || !numberOfDisplayTags) return '';
  if (tagsLength <= numberOfDisplayTags) return '';
  const number = tagsLength - numberOfDisplayTags;
  const text = `+ ${number} ${translate('more.text')}`;

  return text;
};
