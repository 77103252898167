import React from 'react';
import { Box } from '@chakra-ui/react';
import { DashboardProject } from './sampleData';
import styles from './chart.module.scss';
import { chartSize } from './chartConstants';
import { joinClassNames } from '../../../../../shared/utils';
import { TVote } from '../../../domain';
import { Paragraph, Truncate } from '../../../../../shared/components';

export const ChartBubbles: React.FC<{ project: DashboardProject }> = ({
  project,
}) => {
  const isEmptyProject = (): boolean => project?.id === '';

  const medianPoint = (): JSX.Element => {
    if (project?.median < 0) return <></>;
    return (
      <div
        className={styles.bubble__medianContainer}
        style={{
          position: 'absolute',
          top: ((chartSize - 8) * project?.median) / 100 + 2,
          left: 12,
        }}
      >
        <span className={styles.bubble__median} />
      </div>
    );
  };

  const bubblePoint = (): JSX.Element => {
    return (
      <div className={styles.bubble__dotContainer}>
        <span className={styles.bubble__dot} />
      </div>
    );
  };

  const items = project?.votes.map((point: TVote, index) => {
    const currentCoordinator = project?.coordinators.filter(
      coordinator =>
        coordinator?.researchCoordinatorId === point?.researchCoordinatorId,
    )[0];
    return (
      <div
        key={point?.researchCoordinatorId}
        className={styles.header__textContainerBubble}
      >
        <div
          style={{
            position: 'absolute',
            top: ((chartSize + 20) * (point?.vote + 5)) / 100,
            left: 16,
          }}
          className={joinClassNames(styles.header__textTooltipBubble)}
        >
          <Box display={'flex'} opacity={0.6} justifyContent={'space-between'}>
            <Truncate maxLines={1}>
              <Paragraph
                size="body"
                weight="bold"
                style={{ marginRight: '8px' }}
                className={styles.chartBubbleHorizontal}
              >
                {currentCoordinator?.name}
              </Paragraph>
            </Truncate>
            <Paragraph size="body" weight="bold">
              {currentCoordinator?.position +
                '/' +
                currentCoordinator?.projectsCount}
            </Paragraph>
          </Box>
        </div>

        <div
          key={index}
          className={joinClassNames(
            styles.bubble__container,
            isEmptyProject() ? styles.bubble__containerEmpty : null,
          )}
          style={{
            position: 'absolute',
            top: ((chartSize - 8) * point?.vote) / 100,
            left: 16,
          }}
        >
          {bubblePoint()}
        </div>
      </div>
    );
  });
  return (
    <div style={{ height: chartSize, position: 'relative' }}>
      {!isEmptyProject() && items}
      {!isEmptyProject() && medianPoint()}
    </div>
  );
};
