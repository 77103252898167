import React from 'react';
import { H4 } from '../../../../../shared/components';
import { getTranslateByScope } from '../../../../../shared/i18n';
import styles from './index.module.scss';

const translate = getTranslateByScope('ui.layouts.Import.projectsList');

const headers = [
  'error',
  'shortName',
  'name',
  'additionalInstitutes',
  'id',
  'cluster',
  'leadingInstitute',
  'applicantName',
  'applicantEmail',
  'industries',
  'additionalIndustries',
  'description',
  'ipcSupplement',
  'ipcConcordance',
  'tags',
  'researchCoordinators',
  'roi',
  'url',
];

export const ImportProjectHeadersRow = (): JSX.Element => {
  return (
    <tr className={styles.importProjectList__row}>
      {headers.map(header => (
        <th key={header} className={styles.importProjectList__header}>
          <H4>{translate(`headers.${header}`)}</H4>
        </th>
      ))}
    </tr>
  );
};
