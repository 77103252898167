import { createAction } from '../../../shared/redux/actionTypes/createAction';

export const changeProjectViewModeActionTypes = createAction(
  'PROJECT_VIEW_MODE_CHANGE',
);
export const filterByTextProjectListActionTypes = createAction(
  'FILTER_PROJECT_LIST_BY_TEXT',
);
export const filterBySegmentProjectListActionTypes = createAction(
  'FILTER_PROJECT_LIST_BY_SEGMENT',
);
export const listProjectActionTypes = createAction('PROJECTS_LIST');
export const uploadProjectActionTypes = createAction('PROJECTS_UPLOAD');
export const projectDetailActionTypes = createAction('PROJECT_DETAIL');
export const projectDeleteActionTypes = createAction('PROJECT_DELETE');
export const myProjectActionTypes = createAction('PROJECTS_MINE');
export const myProjectRankingActionTypes = createAction('PROJECTS_RANKING');
export const rankProjectActionTypes = createAction('PROJECT_RANK');
export const editTrafficLightRatingActionTypes = createAction(
  'PROJECT_EDIT_TRAFFIC_LIGHT_RATING',
);
export const getTrafficLightRatingActionTypes = createAction(
  'PROJECT_GET_TRAFFIC_LIGHT_RATING',
);
export const trafficLightRateProjectActionTypes = createAction(
  'PROJECT_TRAFFIC_LIGHT_RATE',
);
export const editTopicRatingActionTypes = createAction(
  'PROJECT_EDIT_TOPIC_RATING',
);
export const getTopicRatingActionTypes = createAction(
  'PROJECT_GET_TOPIC_RATING',
);
export const topicRateProjectActionTypes = createAction('PROJECT_TOPIC_RATE');
export const deleteEvaluationActionTypes = createAction(
  'PROJECT_DELETE_EVALUATIONS',
);
export const editEvaluationActionTypes = createAction(
  'PROJECT_EDIT_EVALUATIONS',
);
export const evaluateProjectActionTypes = createAction('PROJECT_EVALUATE');
export const getProjectEvaluationsActionTypes = createAction(
  'PROJECT_EVALUATIONS_GET',
);
export const myEvaluationsByProjectActionTypes = createAction(
  'PROJECT_EVALUATIONS',
);
export const myQualifiedProjectsActionTypes = createAction(
  'PROJECTS_QUALIFICATIONS',
);
export const setProjectsUploadFileActionTypes = createAction(
  'PROJECTS_UPLOAD_SET_FILE',
);
export const setEvaluationCommentStateActionTypes = createAction(
  'PROJECT_EVALUATION_HAS_UNSAVED_COMMENT',
);
export const setEvaluationModalVisibilityActionTypes = createAction(
  'PROJECT_EVALUATION_MODAL_VISIBILITY',
);
export const updateResearchCoordinatorProjectActionTypes = createAction(
  'PROJECT_RESEARCH_COORDINATOR_UPDATE',
);
