import { RefObject, useEffect, useState } from 'react';

export const useComponentSize = (
  myRef: RefObject<any>,
): { [key: string]: number } => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(myRef.current.offsetWidth);
    setHeight(myRef.current.offsetHeight);

    const handleResize = (): void => {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return { width, height };
};
