import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ChartRow } from '../components/chartReport/chartRow';
import { useRequestOnMount } from '../../../../shared/hooks';
import { reportsActions } from '../../redux/effects';
import { SidebarLayout } from '../../../../shared/components/sidebar-layout';
import { H1, H2, PageContainer } from '../../../../shared/components';
import { VerticalSpacer } from '../../../../shared/components/spacers';
import { TCluster, TInstitute } from '../../domain/cluster';
import { reportsSelectors } from '../../redux/selectors';
import styles from '../components/chartReport/chartReport.module.scss';
import { getTranslateByScope } from '../../../../shared/i18n';
import { totalApprovedProjects } from '../utils';
import { ChartRowHorizontal } from '../components/chartReport/chartRowHorizontal';
import { DropdownBasicSmall } from '../../../../shared/components/dropdown/dropdown';
import { DropdownItem } from '../../../../shared/components/dropdown/dropdown.base';

export const Clusters: React.FC = () => {
  useRequestOnMount(reportsActions.getReports);
  const [isClustersView, setIsClustersView] = useState<boolean>(false);
  const clusters: TCluster[] = useSelector(reportsSelectors.getList);
  const institutes: TInstitute[] = useSelector(
    reportsSelectors.getInstitutesList,
  );
  const translate = getTranslateByScope('ui.layouts.Reports');
  const totalApproved = totalApprovedProjects(clusters);
  const dropDownItems: DropdownItem[] = [
    { id: 1, value: 'Per cluster' },

    { id: 2, value: 'Per Institute' },
  ];
  return (
    <SidebarLayout title={translate('header.title')}>
      <PageContainer type="asFullWidthInBigScreen">
        <div className={styles.header__align}>
          <H1>{translate('header.title')}</H1>
          <div>
            <DropdownBasicSmall
              placeholder={dropDownItems[0].value}
              items={dropDownItems}
              onChange={() => setIsClustersView(!isClustersView)}
            />
          </div>
        </div>
        <VerticalSpacer size="lg2" />
        <div className={styles.report__item}>
          {!isClustersView
            ? clusters.map((e, i) => {
                return (
                  <ChartRow key={i} cluster={e} totalApproved={totalApproved} />
                );
              })
            : institutes.map((e, i) => {
                return (
                  <ChartRowHorizontal
                    key={i}
                    institute={e}
                    totalApproved={totalApproved}
                  />
                );
              })}
        </div>
      </PageContainer>
    </SidebarLayout>
  );
};
