import _ from 'lodash';
import { State as projectsUploadState } from './projectsUploadReducer';

type State =
  | {
      persisted: {
        upload: projectsUploadState;
      };
    }
  | {};

const getFile = (state: State): File => _.get(state, 'persisted.upload.file');

export const projectsUploadSelectors = {
  getFile,
};
