import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FlexBox, Paragraph } from '../../../../../shared/components';
import styles from './index.module.scss';
import { UploadProgressBar } from './uploadProgressBar';
import { ProjectRoutes } from '../../routes';
import { translate } from './translate';

export const UploadProgress = ({
  onDone,
  file,
}: {
  onDone: Function;
  file: File;
}): JSX.Element => {
  const [loadProgress, setLoadProgress] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (!file.name) {
      history.replace(ProjectRoutes.projects.list);
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e): Promise<void> => {
      if (e.target) {
        onDone(e.target.result);
      }
    };
    reader.onprogress = async (e): Promise<void> => {
      if (e.lengthComputable) {
        const percentLoaded = Math.round((e.loaded / e.total) * 100);
        setLoadProgress(percentLoaded);
      }
    };
    reader.readAsText(file);
  }, [file, history, onDone]);

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      paddingVertical="5xl"
      paddingHorizontal="10xl"
    >
      <FlexBox.Row flex={1} paddingVertical="lg" alignItems="center">
        <UploadProgressBar value={loadProgress} />
      </FlexBox.Row>
      <FlexBox.Row flex={1} paddingVertical="lg" alignItems="center">
        <Paragraph>{`${translate('loading.uploading')} ${
          file?.name
        }`}</Paragraph>
      </FlexBox.Row>
      <FlexBox.Row flex={1} alignItems="center">
        <Link
          to={ProjectRoutes.projects.list}
          style={{ textDecoration: 'none' }}
        >
          <Paragraph
            size="small"
            weight="bold"
            color="link"
            className={styles.header__linkTooltip}
          >
            Cancel
          </Paragraph>
        </Link>
      </FlexBox.Row>
    </FlexBox>
  );
};
