/* eslint-disable @typescript-eslint/camelcase */

import _ from 'lodash';
import {
  TrafficLightRate,
  State as tlState,
} from './projectsTrafficLightRatingReducer';
import { TId } from '../../../shared/domain';

type State =
  | {
      persisted: {
        trafficLight: tlState;
      };
    }
  | {};

const by_project = (id: TId) => (state: State): TrafficLightRate =>
  _.get(state, 'persisted.trafficLight.by_project_id')[id];

export const trafficLightRatingSelectors = {
  by_project,
};
