import React from 'react';
import { motion } from 'framer-motion';
import { animationDurationsInSeconds } from '../../shared/constants';
import { millisecondsIn } from '../../shared/utils';

const defaultConfiguration = {
  ease: 'easeInOut',
  duration: animationDurationsInSeconds.md,
};

export const AnimateFromTheRight = ({
  children,
  distanceFromTheRightOfThePage,
  isVisible,
  className,
}: {
  children: React.ReactNode;
  distanceFromTheRightOfThePage: number;
  isVisible: boolean;
  className: string;
}): JSX.Element => {
  return (
    <motion.div
      initial={{ x: distanceFromTheRightOfThePage }}
      animate={isVisible ? { x: 0 } : { x: distanceFromTheRightOfThePage }}
      transition={defaultConfiguration}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export const WaitToShow = (
  time: number = millisecondsIn.oneHundredMilliseconds,
) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setShow(true);
      }, time);
    }
  }, [show, time]);

  return { show };
};

const easeInBox = {
  ease: 'easeInOut',
  duration: 0.9,
};
interface EaseInBoxProps {
  children: React.ReactNode;
  style: any;
  fullWidth?: boolean;
}

export const EaseInBox = ({ children, style, fullWidth }: EaseInBoxProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={easeInBox}
    style={{ width: fullWidth ? '100%' : style.width, ...style }}
  >
    {children}
  </motion.div>
);
EaseInBox.defaultProps = { style: {} };

export const WaitToEnter = (props: Record<string, any>) => {
  const { show } = WaitToShow(props.timeToEnter);

  if (!show) return null;

  return <EaseInBox>{props.children}</EaseInBox>;
};

export const ListAnimation = (props: {
  children: any;
  duration?: number;
  style?: any;
}): JSX.Element => (
  <motion.div
    variants={{
      initial: { y: '-20%' },
      start: { y: 0 },
    }}
    initial="initial"
    animate="start"
    transition={{
      ease: 'easeInOut',
      duration: props.duration,
    }}
  >
    {props.children}
  </motion.div>
);

ListAnimation.defaultProps = {
  duration: 0.2,
};
