import { RouteVisibilityAuthentication } from '../../../shared/routes/utils/RouteVisibility';
import Dashboard from './pages';
import { roles } from '../../Auth/domain';

export const DashboardRoutes = {
  dashboard: '/dashboard',
};

export const DashboardRoutesConfig = [
  {
    path: DashboardRoutes.dashboard,
    Component: Dashboard,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [...Object.keys(roles)],
    },
  },
];
