import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { RouteConfig } from './shared/routes/utils';
import configureStore from './shared/redux/setup/storeSetup';
import Toaster from './shared/components/Toaster';
import { ThemeProvider } from './shared/components/theme';
import './App.css';
import './shared/default.css';

const { store, persistor } = configureStore();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <Toaster />
          <RouteConfig />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
