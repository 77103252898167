import React from 'react';
import { useDispatch } from 'react-redux';
import { EvaluationComment } from './components/evaluationComment';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { useSelector } from '../../../../../shared/hooks';
import { projectSelectors } from '../../../redux/projectSelectors';
import { EvaluationValues } from '../../../data/evaluateProjectApi';
import { handleFail, handleSuccess } from './effects';
import styles from './index.module.scss';
import { H4, Separator } from '../../../../../shared/components';
import { joinClassNames } from '../../../../../shared/utils';
import { EvaluationDnD } from './components/evaluationDnD';
import { Ranking } from '../../../redux/projectsRankingReducer';
import { projectActions } from '../../../redux/effects';
import { TProject } from '../../../domain';
import { TopicEvaluation } from './components/topicEvaluation';

export const EvaluationRC: React.FC<{ project: TProject }> = ({ project }) => {
  const myProjects = useSelector(projectSelectors.getMyProjects);

  const dispatch = useDispatch();

  const isMineProject = (): boolean =>
    !!myProjects.find(e => e.id === project.id);

  const onReorder = (items: Ranking[]): void => {
    dispatch(projectActions.rank({ rankings: items }));
  };

  const submit = (
    commentText: string,
    evaluation: EvaluationValues | undefined,
  ): void => {
    dispatch(
      projectActions.evaluate({
        id: project.id,
        comment: commentText,
        evaluation: evaluation,
        onSuccess: () => handleSuccess(dispatch, project.id),
        onFailure: () => handleFail(dispatch),
      }),
    );
  };

  return isMineProject() ? (
    <div className={styles.evaluation__bodyContainer}>
      <div className={styles.evaluation__bodyLeft}>
        <H4>Kommentare</H4>
        <VerticalSpacer size="xl" />
        <EvaluationComment project={project} onSubmit={submit} />
        <VerticalSpacer size="xl" />
        <Separator.Dark />
        <VerticalSpacer size="xl" />
        <TopicEvaluation project={project} />
      </div>
      <div className={styles.evaluation__bodyRight}>
        <EvaluationDnD project={project} onChange={onReorder} />
      </div>
    </div>
  ) : (
    <div className={styles.evaluation__bodyContainerScrollable}>
      <VerticalSpacer size="xl" />
      <div
        className={joinClassNames(
          styles.evaluation__bodyCentered,
          styles.evaluation__bodyNotScrollable,
        )}
      >
        <EvaluationComment project={project} onSubmit={submit} />
      </div>
      <VerticalSpacer size="xl" />
    </div>
  );
};
