import { topicRatingValueNumberMap } from '../../../../../shared/constants/topicRatings';
import {
  TAdmin,
  TProgramManager,
  TResearchCoordinator,
  TThinkTank,
  TThinkTankAdmin,
} from '../../../../Auth/domain';
import { TTopicRating } from '../../../domain';

export type ratingWithTooltip = {
  value: number;
  name: string;
};

const getUserFromTopicRating = (
  topicRating: TTopicRating | undefined,
):
  | TProgramManager
  | TResearchCoordinator
  | TThinkTank
  | TThinkTankAdmin
  | TAdmin
  | null => {
  if (topicRating?.programManager) return topicRating?.programManager;
  if (topicRating?.researchCoordinator) return topicRating?.researchCoordinator;
  if (topicRating?.thinkTankAdmin) return topicRating?.thinkTankAdmin;
  if (topicRating?.thinkTank) return topicRating?.thinkTank;
  if (topicRating?.admin) return topicRating?.admin;
  return null;
};
export const getMarketRatingsFromProject = (
  topicRatings: TTopicRating[] | undefined,
): ratingWithTooltip[] => {
  const marketRatings: ratingWithTooltip[] = [];
  topicRatings?.map(topicRating => {
    const user = getUserFromTopicRating(topicRating);
    if (topicRating?.marketRating) {
      marketRatings.push({
        value: topicRatingValueNumberMap[topicRating.marketRating],
        name: (user?.firstName ?? '') + ' ' + (user?.lastName ?? ''),
      });
    }
  });
  return marketRatings;
};

export const getTechnologyRatingsFromProject = (
  topicRatings: TTopicRating[] | undefined,
): ratingWithTooltip[] => {
  const technologyRatings: ratingWithTooltip[] = [];
  topicRatings?.map(topicRating => {
    const user = getUserFromTopicRating(topicRating);
    if (topicRating?.technologyRating) {
      technologyRatings.push({
        value: topicRatingValueNumberMap[topicRating.technologyRating],
        name: (user?.firstName ?? '') + ' ' + (user?.lastName ?? ''),
      });
    }
  });
  return technologyRatings;
};

export const getConsortiumRatingsFromProject = (
  topicRatings: TTopicRating[] | undefined,
): ratingWithTooltip[] => {
  const consortiumRatings: ratingWithTooltip[] = [];
  topicRatings?.map(topicRating => {
    const user = getUserFromTopicRating(topicRating);
    if (topicRating?.consortiumRating) {
      consortiumRatings.push({
        value: topicRatingValueNumberMap[topicRating.consortiumRating],
        name: (user?.firstName ?? '') + ' ' + (user?.lastName ?? ''),
      });
    }
  });
  return consortiumRatings;
};
