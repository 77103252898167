import React, { useRef, useState } from 'react';
import { HorizontalSpacer } from '../../../../../shared/components/spacers';
import styles from './chart.module.scss';

import { DashboardProject } from './sampleData';
import { ChartBubbles } from './chartBubble';
import { ChartHeader } from './chartHeader';
import { ChartColored } from './chartColored';
import { DashboardResponseData } from '../../../domain';
import { ChartData } from './chartMapper';
import { useComponentSize } from '../../../../../shared/hooks/useComponentSize';
import { ChartPaginator, ChartPaginatorPosition } from './chartPaginator';
import { joinClassNames } from '../../../../../shared/utils';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { columnSize } from './chartConstants';

export const translate = getTranslateByScope('ui.layouts.Dashboard.chart');

export const Graph: React.FC<{
  projects: DashboardResponseData[];
  onItemClick: (item: DashboardResponseData | undefined) => void;
  selectedItem?: DashboardResponseData;
  orderByRating: boolean;
}> = ({ projects, onItemClick, selectedItem, orderByRating }) => {
  const reference = useRef(null);
  const { width } = useComponentSize(reference);
  const [pageIndex, setPageIndex] = useState(0);

  const data = ChartData(projects, width, pageIndex, orderByRating);
  const items = data.list;
  const isFirstItem = (i: number): boolean => i === 0;
  const shouldShowPaginatorLeft = (): boolean => pageIndex !== 0;

  const hasMoreItems = (): boolean => {
    return projects.length > (pageIndex + 1) * Math.floor(width / columnSize);
  };

  const shouldShowPaginatorRight = (): boolean =>
    items.length >= Math.floor(width / 40) && hasMoreItems();

  const isCurrentSelectedProject = (e: DashboardProject): boolean =>
    selectedItem?.id === e.id;
  const isEmptyProject = (project: DashboardProject): boolean =>
    project.id !== '';

  const getChartColored = (
    title: string,
    item: string,
    i: number,
  ): JSX.Element => {
    return isFirstItem(i) ? (
      <ChartColored color={item} title={title} />
    ) : (
      <ChartColored color={item} />
    );
  };

  const onClickProject = (project: DashboardProject): void => {
    if (isEmptyProject(project)) {
      const element = projects.find(item => item.id === project.id);
      onItemClick(element);
    }
  };

  const paginatorLeft = (): JSX.Element => {
    return shouldShowPaginatorLeft() ? (
      <ChartPaginator
        onClick={(): void => setPageIndex(pageIndex - 1)}
        position={ChartPaginatorPosition.LEFT}
      />
    ) : (
      <></>
    );
  };

  const paginatorRight = (): JSX.Element => {
    return shouldShowPaginatorRight() ? (
      <ChartPaginator
        onClick={(): void => setPageIndex(pageIndex + 1)}
        position={ChartPaginatorPosition.RIGHT}
      />
    ) : (
      <></>
    );
  };

  return (
    <div ref={reference}>
      <HorizontalSpacer size="lg" />
      <div
        className={styles.chart__container}
        style={{ width: pageIndex === 0 ? width : items.length * 40 + 5 }}
      >
        <div className={styles.chart__bubbleContainer}>
          {items.map((e: DashboardProject, i: number) => {
            return (
              <div
                key={i}
                className={joinClassNames(
                  styles.chart__column,
                  isCurrentSelectedProject(e)
                    ? styles.chart__column__selected
                    : null,
                  isEmptyProject(e) ? styles.chart__columnEmpty : null,
                )}
                onClick={(): void => onClickProject(e)}
              >
                <ChartHeader
                  project={e}
                  isFirstElement={i === 0}
                  isLastElement={i === items.length - 1}
                />
                <ChartBubbles project={items[i]} />
                {getChartColored(translate('thinkTank'), items[i].voteTT, i)}
                {getChartColored(
                  translate('programManager'),
                  items[i].votePM,
                  i,
                )}
              </div>
            );
          })}
        </div>
        {paginatorLeft()}
        {paginatorRight()}
      </div>
    </div>
  );
};
