import React from 'react';
import { ProjectJson } from '../../../domain';
import { ImportProjectHeadersRow } from './importProjectHeadersRow';
import { ImportProjectRow } from './importProjectRow';
import styles from './index.module.scss';

export const ImportProjectListItems = ({
  projects,
}: {
  projects: ProjectJson[];
}): JSX.Element => (
  <table className={styles.importProjectList__table}>
    <thead>
      <ImportProjectHeadersRow />
    </thead>
    <tbody>
      {projects.map((project, index) => (
        <ImportProjectRow key={index} project={project} />
      ))}
    </tbody>
  </table>
);
