import { TId } from '../../../shared/domain';

export interface TAccount {
  password: string;
}

export interface TAccount {
  id: TId;
  passwordHash: string;
  email: string;
}

export interface TUser {
  id: TId;
  firstName: string;
  lastName: string;
  email?: string;
}

export type TResearchCoordinator = TUser;
export type TThinkTank = TUser;
export type TProgramManager = TUser;
export type TThinkTankAdmin = TUser;
export type TProgramManagerAdmin = TUser;
export type TAdmin = TUser;

export enum roles {
  researchCoordinator = 'researchCoordinator',
  thinkTank = 'thinkTank',
  programManager = 'programManager',
  thinkTankAdmin = 'thinkTankAdmin',
  programManagerAdmin = 'programManagerAdmin',
  admin = 'admin',
}
