import _ from 'lodash';

import { TUser } from '../../Auth/domain';
import { projectSelectors } from '../../Project/redux/projectSelectors';
import { State as projectState } from '../../Project/redux/projectsReducer';
import { sessionSelectors } from '../../Auth/redux/selectors';
import { AdminSegments } from '../../../shared/constants/segments';
import {
  DashboardResponseData,
  TEvaluation,
  TReaction,
  AdminRating,
} from '../domain';
import { State as dashboardState } from './reducerTypes';
import { TProject } from '../../Project/domain';
import { TId } from '../../../shared/domain';

type State =
  | {
      persisted: {
        dashboard: dashboardState;
        adminProjects: projectState;
      };
    }
  | {};

const getAdminProjectEntities = (state: State): { [key: string]: TProject } =>
  _.get(state, 'persisted.adminProjects.entities');

const getSegmentFilter = (state: State): string =>
  _.get(state, 'persisted.adminProjects.filters.segment');

const getAdminProjectList = (state: State): TProject[] => {
  const entities = getAdminProjectEntities(state);
  return Object.keys(entities).map((id: string) => entities[id]);
};

const getProjectsFilteredByText = (projects: any, text: string): TProject[] =>
  _.filter(
    projects,
    (project: any) =>
      project.name.toLowerCase().includes(text.toLowerCase()) ||
      project.shortName.toLowerCase().includes(text.toLowerCase()) ||
      project.description.toLowerCase().includes(text.toLowerCase()),
  );

const filterList = (
  project: TProject,
  user: TUser | null,
  rating: AdminRating,
): number | undefined => {
  return project.projectAdminRatings?.filter(
    e => e.adminId === user?.id && e.rating === rating,
  ).length;
};

const getListFilteredBySegment = (state: State): TProject[] => {
  const list = getAdminProjectList(state);
  const segment = getSegmentFilter(state);
  const user = sessionSelectors.currentUser(state);

  return list.filter((project: TProject) => {
    switch (segment) {
      case AdminSegments.allProjects:
        return true;
      case AdminSegments.acceptedProjects:
        return filterList(project, user, AdminRating.ACCEPT);
      case AdminSegments.rejectedProjects:
        return filterList(project, user, AdminRating.REJECT);
      case AdminSegments.onholdProjects:
        return filterList(project, user, AdminRating.ON_HOLD);
      default:
        return true;
    }
  });
};

const getSummary = (
  state: State,
): { accepted: number; rejected: number; onHold: number } => {
  const list = getAdminProjectList(state);

  return {
    accepted: list.filter(project =>
      project.projectAdminRatings?.find(it => it.rating === AdminRating.ACCEPT),
    ).length,
    rejected: list.filter(project =>
      project.projectAdminRatings?.find(it => it.rating === AdminRating.REJECT),
    ).length,
    onHold: list.filter(project =>
      project.projectAdminRatings?.find(
        it => it.rating === AdminRating.ON_HOLD,
      ),
    ).length,
  };
};

const sortProjects = (state: State, projects: TProject[]) => {
  const user = sessionSelectors.currentUser(state);

  const onhold = projects.filter(
    (project: TProject) =>
      project.projectAdminRatings?.filter(
        e => e.adminId === user?.id && e.rating === AdminRating.ON_HOLD,
      ).length,
  );

  const voted = projects.filter(
    (project: TProject) =>
      project.projectAdminRatings?.filter(
        e => e.adminId === user?.id && e.rating !== AdminRating.ON_HOLD,
      ).length,
  );
  const unvoted = projects.filter(
    (project: TProject) =>
      !project.projectAdminRatings?.filter(e => e.adminId === user?.id)
        .length || !project.projectAdminRatings?.length,
  );

  const ordered = [...voted, ...unvoted].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return [...ordered, ...onhold];
};

const getProjectsFiltered = (state: State): TProject[] => {
  const textFilter = projectSelectors.getTextFilter(state);
  const filter1 = getListFilteredBySegment(state);
  const sorted = sortProjects(state, filter1);

  return getProjectsFilteredByText(sorted, textFilter);
};

export const adminProjectSelectors = {
  getList: getAdminProjectList,
  getListFilteredBySegment,
  getProjectsFiltered,
  getSegmentFilter,
  getSummary,
};

const getEntities = (state: State): { [key: string]: DashboardResponseData } =>
  _.get(state, 'persisted.dashboard.entities');

const getList = (state: State): DashboardResponseData[] => {
  const entities = getEntities(state);

  if (!entities) return [];

  return Object.keys(entities).map((id: string) => entities[id]);
};

const getCommentsForProject = (id: TId) => (state: State): TEvaluation[] => {
  const entities = getEntities(state);

  if (!entities) return [];

  const entity = entities[id];
  return entity?.evaluations || [];
};

const getMyReactionForProjectEvaluation = ({
  projectId,
  evaluationId,
}: {
  projectId: TId;
  evaluationId: TId;
}) => (state: State): TReaction | undefined => {
  const user = sessionSelectors.currentUser(state);
  const evaluations = getCommentsForProject(projectId)(state);
  if (!user || evaluations.length === 0) return undefined;

  const responses = evaluations
    .map(e => e.responses)
    .flat()
    .filter(r => r);

  const evaluation = [...evaluations, ...responses].find(
    e => e.id === evaluationId,
  );
  if (!evaluation) return undefined;

  const reactions = evaluation.reactions;
  const fullName = `${user.firstName} ${user.lastName}`.trim();

  return reactions?.find(r => r.author === fullName);
};

export const dashboardSelectors = {
  getList,
  getCommentsForProject,
  getMyReactionForProjectEvaluation,
};
