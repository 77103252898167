import React, {
  ButtonHTMLAttributes,
  ChangeEvent,
  PropsWithChildren,
  useState,
} from 'react';

import styles from './buttons.module.scss';
import { joinClassNames } from '../../shared/utils';
import { Desktop, FlexBox, Box, Mobile, icons } from '.';
import { iconColors, iconSizes } from '../../shared/constants';
import { Spinners } from './spinners';

export type ButtonInterface = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement>
> & { loading?: boolean };

export const PrimaryButton: React.FC<ButtonInterface> = ({
  className,
  loading,
  children,
  ...other
}) => (
  <button
    className={joinClassNames(styles.PrimaryButton, className)}
    {...other}
  >
    {loading ? <Spinners.White /> : children}
  </button>
);

export const PrimaryButtonWithWidth: React.FC<ButtonInterface> = ({
  className,
  loading,
  children,
  ...other
}) => (
  <button
    className={joinClassNames(styles.PrimaryButtonWithWidth, className)}
    {...other}
  >
    {loading ? <Spinners.White /> : children}
  </button>
);

export const TransparentButton: React.FC<ButtonInterface> = ({
  className,
  ...other
}) => (
  <button
    className={joinClassNames(styles.transparentButton, className)}
    {...other}
  />
);

export const BottomFluidPrimaryButton: React.FC<ButtonInterface> = props => (
  <PrimaryButton {...props} className={styles.bottomFluid} />
);

export const ButtonWithMobile: React.FC<ButtonInterface> = props => (
  <>
    <Desktop>
      <FlexBox marginVertical="xl" justifyContent="flex-end">
        <Box>
          <PrimaryButton {...props} />
        </Box>
      </FlexBox>
    </Desktop>
    <Mobile>
      <BottomFluidPrimaryButton {...props} />
    </Mobile>
  </>
);

interface ImportButtonProps {
  text: string;
  handleFile: (file: File) => void;
}

export const ImportButton: React.FC<ImportButtonProps> = props => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const onClick = (): void => {
    const element = hiddenFileInput.current;

    if (element) {
      element.click();
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      const file = event?.target?.files[0];
      props.handleFile(file);
    }
  };

  return (
    <>
      <ButtonIconText
        onClick={onClick}
        icon={icons.import}
        size={iconSizes.md}
        text={props.text}
      />
      <input
        ref={hiddenFileInput}
        type="file"
        name="file"
        accept="application/json"
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
    </>
  );
};

interface ButtonIconProps {
  onClick: Function;
  active: boolean;
  activeColor?: iconColors;
  inactiveColor?: iconColors;
  hoverColor?: iconColors;
  icon: any;
  color?: iconColors;
  size?: iconSizes;
  loading?: boolean;
}

export const ButtonIcon: React.FC<ButtonIconProps> = props => {
  const [hover, setHover] = useState(false);

  const toggleHover = (): void => {
    setHover(!hover);
  };

  const getColor = (): iconColors => {
    if (hover && props.hoverColor) return props.hoverColor;
    if (props.active) {
      return props.activeColor ? props.activeColor : iconColors.primary;
    }
    return props.inactiveColor ? props.inactiveColor : iconColors.grey;
  };

  return (
    <button
      onClick={(e): void => props.onClick(e)}
      className={styles.buttonIcon}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      {props.loading ? (
        <Spinners.Primary />
      ) : (
        <props.icon size={props.size} color={getColor()} />
      )}
    </button>
  );
};

interface ButtonIconTextProps {
  onClick: Function;
  hoverColor?: iconColors;
  icon: any;
  text: string;
  size?: iconSizes;
  style?: any;
}

const ButtonWithIconAndText: React.FC<ButtonIconTextProps & {
  ButtonComponent: typeof PrimaryButton | typeof TransparentButton;
}> = props => {
  const [hover, setHover] = useState(false);

  const toggleHover = (): void => {
    setHover(!hover);
  };

  return (
    <props.ButtonComponent
      onClick={(): void => props.onClick()}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <FlexBox alignItems="center" style={props?.style}>
        <Box marginRight="sm">
          <props.icon
            size={props.size}
            color={
              props.ButtonComponent === PrimaryButton
                ? iconColors.white
                : iconColors.black2
            }
          />
        </Box>
        <Box>{props.text}</Box>
      </FlexBox>
    </props.ButtonComponent>
  );
};

export const ButtonIconText: React.FC<ButtonIconTextProps> = props => {
  return <ButtonWithIconAndText ButtonComponent={PrimaryButton} {...props} />;
};

export const TransparentButtonIconText: React.FC<ButtonIconTextProps> = props => {
  return (
    <ButtonWithIconAndText ButtonComponent={TransparentButton} {...props} />
  );
};
