import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toasterTypes } from '../../../shared/constants';

import { TId } from '../../../shared/domain';
import { getTranslateByScope } from '../../../shared/i18n';
import { showToasterAction } from '../../../shared/redux/actions';
import { projectActions } from '../../Project/redux/effects';
import { ReactionValues } from '../domain';
import { reactToEvaluationAction } from '../redux/effects';

type Params = {
  projectId: TId;
  evaluationId: TId;
  evaluationReaction: {
    reaction: ReactionValues;
  };
};

export const useReactToEvaluation = (): {
  isLoading: boolean;
  reactToEvaluation: (params: Params) => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const translate = getTranslateByScope(
    'ui.layouts.Dashboard.comments.toasters',
  );

  const onFailure = (): void => {
    dispatch(
      showToasterAction({
        description: translate('failure'),
        type: toasterTypes.failure,
      }),
    );
    setIsLoading(false);
  };

  const reactToEvaluation = ({
    projectId,
    evaluationId,
    evaluationReaction,
  }: Params): void => {
    setIsLoading(true);

    dispatch(
      reactToEvaluationAction({
        projectId,
        evaluationId,
        evaluationReaction,
        onSuccess: () => {
          dispatch(
            projectActions.dashboardData({
              onSuccess: () => {
                setIsLoading(false);
              },
              onFailure,
            }),
          );
        },
        onFailure,
      }),
    );
  };

  return {
    isLoading,
    reactToEvaluation,
  };
};
