import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TProject } from '../domain';

export const listProjectsApi = ({
  authenticationToken,
}: {
  authenticationToken: string;
}): AxiosPromise<{ projects: TProject[] }> =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.list),
    method: httpMethods.get,
    authenticationToken,
  });
