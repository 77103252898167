/* eslint-disable @typescript-eslint/camelcase */

import _ from 'lodash';
import { TopicRating, State as topicState } from './projectTopicRatingReducer';
import { TId } from '../../../shared/domain';

type State =
  | {
      persisted: {
        topicRating: topicState;
      };
    }
  | {};

const by_project = (id: TId) => (state: State): TopicRating =>
  _.get(state, 'persisted.topicRating.by_project_id')[id];

export const topicRatingSelectors = {
  by_project,
};
