import { DashboardResponseData, TTopicRating, TVote } from '../../../domain';
import { DashboardProject } from './sampleData';
import { columnSize, VoteColor } from './chartConstants';
import {
  getConsortiumRatingsFromProject,
  getMarketRatingsFromProject,
  getTechnologyRatingsFromProject,
  ratingWithTooltip,
} from './utils';

const GetMaxColorOf = (items: string[]): string => {
  const count: { [key: string]: number } = {
    [VoteColor.RED]: 0,
    [VoteColor.YELLOW]: 0,
    [VoteColor.GREEN]: 0,
    [VoteColor.GREY]: 0,
  };
  if (!items.length) return VoteColor.GREY;
  items.forEach(i => (count[i] = (count[i] || 0) + 1));
  return Object.keys(count).reduce((a, b) => (count[a] > count[b] ? a : b));
};

const ReduceVotes = (items: DashboardResponseData[]): DashboardProject[] => {
  return items.map((item: any) => {
    const list = Array(items.length).fill(0);

    const votesMapping = item.votes.reduce((accum: number[], v: number) => {
      return { ...accum, [v]: (accum[v] || 0) + 1 };
    }, {});

    return {
      ...item,
      votes: list.map((element: number[], ind: number) => {
        return votesMapping[ind + 1] || null;
      }),
    };
  });
};

const CalculateAverageRatings = (items: ratingWithTooltip[]): number => {
  const sum = items.reduce((a, b) => a + b.value, 0);
  return sum / items.length;
};

const CalculateAverage = (item: TVote[]): number => {
  const sum = item.reduce((a, b) => a + b.vote, 0);
  return sum / item.length;
};

const CalculateAverageMarketRatings = (item: TTopicRating[]): number =>
  CalculateAverageRatings(getMarketRatingsFromProject(item));

const CalculateAverageTechnologyRatings = (item: TTopicRating[]): number =>
  CalculateAverageRatings(getTechnologyRatingsFromProject(item));

const CalculateAverageConsortiumRatings = (item: TTopicRating[]): number =>
  CalculateAverageRatings(getConsortiumRatingsFromProject(item));

const noVotesAvailable = (item: DashboardResponseData): boolean =>
  item.votes.length === 0;

const noMarketRatingsAvailable = (item: DashboardResponseData): boolean =>
  getMarketRatingsFromProject(item?.topicRatings).length === 0;

const noTechnologyRatingsAvailable = (item: DashboardResponseData): boolean =>
  getTechnologyRatingsFromProject(item?.topicRatings).length === 0;

const noConsortiumRatingsAvailable = (item: DashboardResponseData): boolean =>
  getConsortiumRatingsFromProject(item?.topicRatings).length === 0;

export const ChartMapper = (
  items: DashboardResponseData[],
): DashboardProject[] => {
  return items.map((e: DashboardResponseData, index: number) => {
    const average = noVotesAvailable(e) ? -1 : CalculateAverage(e.votes);
    const marketRatingsAverage = noMarketRatingsAvailable(e)
      ? -1
      : CalculateAverageMarketRatings(e.topicRatings);

    const technologyRatingsAverage = noTechnologyRatingsAvailable(e)
      ? -1
      : CalculateAverageTechnologyRatings(e.topicRatings);

    const consortiumRatingsAverage = noConsortiumRatingsAvailable(e)
      ? -1
      : CalculateAverageConsortiumRatings(e.topicRatings);

    return {
      id: e.id,
      shortName: e.shortName,
      name: e.name,
      votes: e.votes,
      median: average,
      marketRatingMedian: marketRatingsAverage,
      consortiumRatingsMedian: consortiumRatingsAverage,
      technologyRatingsMedian: technologyRatingsAverage,
      voteTT: GetMaxColorOf(e.votesTT),
      votePM: GetMaxColorOf(e.votesPM),
      adminRating: e.adminRating,
      coordinators: e.coordinators,
      topicRatings: e.topicRatings,
    };
  });
};

const EmptyProjectMapper = (
  projectLength: number,
  emptyPojectLength: number,
): DashboardProject[] => {
  const emptyProject: DashboardProject = {
    id: '',
    name: '',
    shortName: '',
    votes: Array(projectLength).fill(null),
    median: -1,
    marketRatingMedian: -1,
    consortiumRatingsMedian: -1,
    technologyRatingsMedian: -1,
    voteTT: VoteColor.EMPTY,
    votePM: VoteColor.EMPTY,
    adminRating: [],
    coordinators: Array(projectLength).fill(null),
    topicRatings: [],
  };
  return Array(emptyPojectLength).fill(emptyProject);
};

export const ChartData = (
  list: DashboardResponseData[],
  width: number,
  page: number,
  orderByRating: boolean,
): { [key: string]: any } => {
  const projectList = ChartMapper(list);
  const emptyProjectList = projectList.filter(e => e.median < 0);
  const votedProjectList = projectList.filter(e => e.median >= 0);
  const sortedVotedProjectList = orderByRating
    ? votedProjectList.sort((a, b) => a.median - b.median)
    : votedProjectList;
  const projects = [...sortedVotedProjectList, ...emptyProjectList];

  const hasLoadedSizes = (): boolean => width !== 0;
  const availableColumns = Math.floor(width / columnSize);

  const pageProjectsForPage = projects.slice(
    page * availableColumns,
    (page + 1) * availableColumns,
  );

  let pageProjects;

  if (pageProjectsForPage.length < availableColumns) {
    pageProjects = projects.slice(
      projects.length - 1 - availableColumns,
      projects.length,
    );
  } else {
    pageProjects = pageProjectsForPage;
  }

  const emptyProjectNumber =
    page === 0 ? availableColumns - pageProjects.length : 0;

  const shouldPrintEmptyProjects = (): boolean =>
    hasLoadedSizes() && emptyProjectNumber > 0;

  const columnsLeft: DashboardProject[] = shouldPrintEmptyProjects()
    ? EmptyProjectMapper(pageProjects.length, emptyProjectNumber)
    : [];

  return {
    list: [...pageProjects, ...columnsLeft],
    emptyNumber: emptyProjectNumber,
  };
};
