import { useLocation, useHistory, useParams } from 'react-router';
import { Location } from 'history';
import { getSearchParam } from '../../shared/utils';

export const useGetSearchParam = (paramName: string): string | null => {
  const location: Location = useLocation();

  return getSearchParam(location, paramName);
};

export const useReplaceRoute = (): { replaceRoute: (arg1: string) => void } => {
  const history = useHistory();

  return {
    replaceRoute: (routeName: string): void => {
      history.replace(routeName);
    },
  };
};

export const usePushRoute = (): { push: (arg1: string) => void } => {
  const history = useHistory();

  return {
    push: (routeName: string): void => {
      history.push(routeName);
    },
  };
};

export const useBackRoute = (): { back: () => void } => {
  const history = useHistory();

  return {
    back: (): void => {
      history.goBack();
    },
  };
};

export const useLocationPath = () => {
  const location = useLocation();

  return location.pathname;
};

export const useExternalLocation = (): { goTo: (path: string) => void } => {
  return {
    goTo: (path: string): void => {
      window.location.href = path;
    },
  };
};

export { useParams, useHistory, useLocation };
