import { useSelector } from 'react-redux';

import { TId } from '../../../shared/domain';
import { Reaction } from '../domain/reaction';
import { dashboardSelectors } from '../redux/selectors';

export const useGetMyReactionForProjectEvaluation = ({
  projectId,
  evaluationId,
}: {
  projectId: TId;
  evaluationId: TId;
}): Reaction | undefined => {
  const reaction = useSelector(
    dashboardSelectors.getMyReactionForProjectEvaluation({
      projectId,
      evaluationId,
    }),
  );

  return reaction ? Reaction.create(reaction) : undefined;
};
