import React from 'react';
import { Pie } from 'react-chartjs-2';
import { TPieChart } from '../../../../domain/chart';

import 'chart.piecelabel.js';
import { ChartConfiguration } from './configuration';
import styles from '../chartReport.module.scss';
import { Paragraph } from '../../../../../../shared/components';
import { getTranslateByScope } from '../../../../../../shared/i18n';

export const PieChart: React.FC<{ data: TPieChart; title: string }> = ({
  data,
  title,
}) => {
  const translate = getTranslateByScope('ui.layouts.Reports');
  return (
    <div className={styles.report__pieChartContainer}>
      <Pie data={data} options={ChartConfiguration} />
      <br />
      <Paragraph size="small" color="black2" style={{ textAlign: 'center' }}>
        {translate(title)}
      </Paragraph>
    </div>
  );
};
