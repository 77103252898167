import React from 'react';
import styles from './index.module.scss';

import { Box } from '../../../../../../shared/components';

export const RightSidebar = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element => (
  <Box
    className={styles.rightSidebar}
    paddingLeft="lg"
    paddingRight="xxl"
    paddingTop="xxl"
    paddingBottom="xxl"
    style={{ gridArea: 'members', height: '100%' }}
  >
    {children}
  </Box>
);
