import React from 'react';
import { translate } from '../../detail/translate';
import {
  RadioButtonItem,
  RadioButtons,
} from '../../../../../../shared/components/radio';
import { TrafficLightRating } from '../../../../domain';

export const radioItems: RadioButtonItem[] = [
  {
    id: TrafficLightRating.GREY,
    value: translate('modal.votingGrey'),
  },
  {
    id: TrafficLightRating.GREEN,
    value: translate('modal.votingGreen'),
  },
  {
    id: TrafficLightRating.YELLOW,
    value: translate('modal.votingYellow'),
  },
  {
    id: TrafficLightRating.RED,
    value: translate('modal.votingRed'),
  },
];

export const EvaluationRadioButtons: React.FC<{
  selectedItem: RadioButtonItem;
  onChange: (item: RadioButtonItem) => void;
}> = ({ selectedItem, onChange }) => {
  return (
    <RadioButtons
      items={radioItems}
      selectedItem={selectedItem}
      onChange={onChange}
    />
  );
};
