import { useDispatch } from 'react-redux';
import { TopicRatingValue } from '../domain';
import { projectActions } from '../redux/effects';

type Params = {
  projectId: string;
  technologyRating: TopicRatingValue | null;
  marketRating: TopicRatingValue | null;
  consortiumRating: TopicRatingValue | null;
};

export const useCreateTopicRating = (): {
  createTopicRating: (params: Params) => void;
} => {
  const dispatch = useDispatch();

  const createTopicRating = ({
    projectId,
    technologyRating,
    marketRating,
    consortiumRating,
  }: Params): void => {
    dispatch(
      projectActions.topicRate({
        id: projectId,
        technologyRating,
        marketRating,
        consortiumRating,
        onSuccess: () =>
          dispatch(
            projectActions.getProjectTopicRatingAction({
              id: projectId,
            }),
          ),
      }),
    );
  };

  return {
    createTopicRating,
  };
};
