import React from 'react';
import styles from '../index.module.scss';
import { ButtonIcon, H3, icons } from '../../../../../../shared/components';
import { iconColors } from '../../../../../../shared/constants';
import {
  useBackRoute,
  useDispatch,
  useSelector,
} from '../../../../../../shared/hooks';
import { getTranslateByScope } from '../../../../../../shared/i18n';
import { TProject } from '../../../../domain';
import { projectSelectors } from '../../../../redux/projectSelectors';
import { setEvaluationModalVisibilityAction } from '../../../../redux/effects';

export const translate = getTranslateByScope('ui.layouts.ProjectEvaluation');

export const EvaluationHeader: React.FC<{ project: TProject }> = ({
  project,
}) => {
  const { back } = useBackRoute();
  const dispatch = useDispatch();
  const hasUnsavedComment = useSelector(projectSelectors.getHasUnsavedComment);

  const onClose = (): void => {
    if (hasUnsavedComment) {
      dispatch(setEvaluationModalVisibilityAction(true));
    } else {
      back();
    }
  };

  return (
    <div className={styles.evaluation__header}>
      <div className={styles.evaluation__headerButton} />
      <H3>
        {translate('header')} {project.shortName}
      </H3>
      <div className={styles.evaluation__headerButton}>
        <ButtonIcon
          onClick={onClose}
          active
          icon={icons.close}
          activeColor={iconColors.black2}
        />
      </div>
    </div>
  );
};
