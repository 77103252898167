import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ProjectRow } from './ProjectRow';
import { TId } from '../../../../../shared/domain';
import { projectSelectors } from '../../../redux/projectSelectors';
import { ProjectsPerCluster } from '../../../domain';
import { H2 } from '../../../../../shared/components';

export const ProjectListItems = ({
  projectIds,
  projectDetailRoute,
  projectListRoute,
  pageIndex,
  perCluster,
  clusters,
}: {
  projectIds: any[];
  projectDetailRoute: (projectId: string) => string;
  projectListRoute: string;
  pageIndex: number;
  perCluster: boolean;
  clusters: string[];
}): JSX.Element => {
  const projectsByClusters: ProjectsPerCluster[] = useSelector(
    projectSelectors.getAllForCluster(clusters),
  );

  interface RowParams {
    projectId: any;
    pageIndex: number;
    projectListRoute: string;
  }

  const RowProject: FC<RowParams> = ({
    projectListRoute,
    pageIndex,
    projectId,
  }) => {
    return (
      <div>
        <ProjectRow
          projectId={projectId}
          routeTo={{
            pathname: projectDetailRoute(projectId),
            state: { page: pageIndex + 1, projectListRoute },
          }}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      {perCluster
        ? projectsByClusters.map((cluster: ProjectsPerCluster, index) => (
            <span key={index}>
              {' '}
              <H2 style={{ marginTop: '25px' }}>{cluster.id}</H2>
              {cluster.projects.map((projectId: TId) => (
                <RowProject
                  key={projectId}
                  projectListRoute={projectListRoute}
                  pageIndex={pageIndex}
                  projectId={projectId}
                />
              ))}
            </span>
          ))
        : projectIds.map((projectId: TId) => (
            <RowProject
              key={projectId}
              projectListRoute={projectListRoute}
              pageIndex={pageIndex}
              projectId={projectId}
            />
          ))}
    </React.Fragment>
  );
};
