import React from 'react';
import {
  DropdownBase,
  DropdownButtonOutlined,
  DropdownButtonBasic,
  DropdownItem,
  DropdownItemDefault,
  DropdownButtonBasicSmall,
} from './dropdown.base';
import styles from './dropdown.module.scss';

export const DropdownOutlined = (props: {
  placeholder: string;
  label?: string;
  width?: number;
  items: DropdownItem[];
  initialItem?: DropdownItem;
  hasError?: boolean;
  onChange: (item: DropdownItem) => void;
}): JSX.Element => {
  return (
    <DropdownBase
      {...props}
      customButton={({
        placeholder,
        selectedItem,
        hasError,
        toggle,
      }: {
        placeholder: string;
        selectedItem: DropdownItem;
        hasError: boolean;
        toggle: () => void;
      }) => (
        <DropdownButtonOutlined
          width={props.width}
          placeholder={placeholder}
          selectedItem={selectedItem ? selectedItem : props.initialItem}
          hasError={hasError}
          toggle={toggle}
        />
      )}
      customItem={({ item, onClick }: any) => (
        <DropdownItemDefault key={item.id} item={item} onClick={onClick} />
      )}
    />
  );
};

export const DropdownBasic = (props: {
  placeholder: string;
  label?: string;
  items: DropdownItem[];
  width?: number;
  initialItem?: DropdownItem;
  hasError?: boolean;
  onChange: (item: DropdownItem) => void;
}): JSX.Element => {
  return (
    <DropdownBase
      {...props}
      customButton={({ placeholder, selectedItem, hasError, toggle }: any) => (
        <DropdownButtonBasic
          width={props.width}
          placeholder={placeholder}
          selectedItem={selectedItem ? selectedItem : props.initialItem}
          hasError={hasError}
          toggle={toggle}
        />
      )}
      customItem={({ item, onClick }: any) => (
        <DropdownItemDefault key={item.id} item={item} onClick={onClick} />
      )}
    />
  );
};

export const DropdownBasicSmall = (props: {
  placeholder: string;
  label?: string;
  items: DropdownItem[];
  initialItem?: DropdownItem;
  width?: number;
  hasError?: boolean;
  onChange: (item: DropdownItem) => void;
}): JSX.Element => {
  return (
    <DropdownBase
      {...props}
      customButton={({ placeholder, selectedItem, hasError, toggle }: any) => (
        <DropdownButtonBasicSmall
          width={props.width}
          placeholder={placeholder}
          selectedItem={selectedItem ? selectedItem : props.initialItem}
          hasError={hasError}
          toggle={toggle}
        />
      )}
      customItem={({ item, onClick }: any) => (
        <DropdownItemDefault key={item.id} item={item} onClick={onClick} />
      )}
    />
  );
};

export const DropdownContainer = ({
  label,
  isWithArrow,
  children,
  id,
}: {
  id?: string;
  label?: JSX.Element;
  isWithArrow?: boolean;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <div className={styles.dropdown}>
      <input type="checkbox" id={id} />

      <label htmlFor={id}>
        {label}
        {isWithArrow && <div className={styles.dropdown__arrow}></div>}
      </label>
      <div className={styles.dropdown__items}>{children}</div>
    </div>
  );
};
