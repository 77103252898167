import React, { useState } from 'react';
import {
  H4,
  Box,
  Paragraph,
  BoxWithUnderlinedLabel,
  FlexBox,
  LettersCircle,
  PrimaryButton,
  ButtonIcon,
  icons,
} from '../../../../../../shared/components';

import { translate } from '../translate';

import { RightSidebar as DefaultRightSidebar } from './sidebar';
import {
  formatFullName,
  formatUserFullName,
  getInitials,
} from '../../../../../../shared/utils';
import {
  useDispatch,
  usePushRoute,
  useSelector,
} from '../../../../../../shared/hooks';
import { routePaths } from '../../../../../../shared/routes/config';
import { TProject } from '../../../../domain';
import { VerticalSpacer } from '../../../../../../shared/components/spacers';
import { iconColors, iconSizes } from '../../../../../../shared/constants';
import { sessionSelectors } from '../../../../../Auth/redux/selectors';
import { TResearchCoordinator } from '../../../../../Auth/domain';
import { ChangeResearchCoordinatorModal } from '../components/changeResearchCoordinator';
import { validateEmail } from '../../../../../Auth/presentation/components/validateEmail';
import { projectActions } from '../../../../redux/effects';

export const RightSidebar: React.FC<{ project: TProject }> = ({ project }) => {
  const { push } = usePushRoute();
  const dispatch = useDispatch();

  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [newEmailValue, setNewEmailValue] = useState<string>('');

  const applicantFullName = formatFullName({
    firstName: project.applicantFirstName,
    lastName: project.applicantLastName,
  });

  const currentUser = useSelector(sessionSelectors.currentUser);
  function checkHttp(link: string | undefined) {
    return link?.split(':')[0].includes('http') ? link : `http://${link}`;
  }

  return (
    <DefaultRightSidebar>
      <ChangeResearchCoordinatorModal
        value={newEmailValue}
        isOpen={importModalVisible}
        onClose={() => {
          dispatch(projectActions.detail(project.id));

          setImportModalVisible(false);
        }}
        onChange={setNewEmailValue}
        hasError={newEmailValue !== '' && !validateEmail(newEmailValue)}
        projectId={project.id}
        toRemoveResearchCoordinatorId={currentUser?.id}
      />
      <Box marginBottom="lg">
        <PrimaryButton
          onClick={() =>
            push(routePaths.projects.projectEvaluation(project.id))
          }
        >
          {translate('rightSidebar.button.label')}
        </PrimaryButton>
      </Box>
      <Box>
        <H4>{translate('rightSidebar.additionalInformation.label')}</H4>
      </Box>
      <BoxWithUnderlinedLabel
        label={translate('rightSidebar.leadingInstitute.label')}
      >
        <Paragraph>{project.leadingInstitute}</Paragraph>
      </BoxWithUnderlinedLabel>
      <BoxWithUnderlinedLabel
        label={translate('rightSidebar.involvedInstitutes.label')}
      >
        <Paragraph>{project.additionalInstitutes.join(', ')}</Paragraph>
      </BoxWithUnderlinedLabel>
      <BoxWithUnderlinedLabel label={translate('rightSidebar.applicant.label')}>
        <FlexBox alignItems="center">
          <LettersCircle>{getInitials(applicantFullName)}</LettersCircle>
          <Box marginLeft="md">
            <Paragraph>{applicantFullName}</Paragraph>
            <Paragraph size="small">{project.applicantEmail}</Paragraph>
          </Box>
        </FlexBox>
      </BoxWithUnderlinedLabel>
      <BoxWithUnderlinedLabel
        label={translate('rightSidebar.researchCoordinators.label')}
      >
        <Box>
          {project.researchCoordinators.map(
            (researchCoordinator: TResearchCoordinator, index: any) => (
              <FlexBox
                marginTop="sm"
                marginBottom="md"
                key={index}
                alignItems="center"
              >
                <LettersCircle>
                  {getInitials(formatUserFullName(researchCoordinator))}
                </LettersCircle>
                <Box marginLeft="md" style={{ width: '100%' }}>
                  <FlexBox justifyContent="space-between">
                    <Paragraph>
                      {formatUserFullName(researchCoordinator)}
                    </Paragraph>
                    {currentUser?.id === researchCoordinator.id && (
                      <ButtonIcon
                        onClick={(): void => setImportModalVisible(true)}
                        active
                        icon={icons.edit}
                        size={iconSizes.sm}
                        activeColor={iconColors.black2}
                      />
                    )}
                  </FlexBox>
                </Box>
              </FlexBox>
            ),
          )}
        </Box>
      </BoxWithUnderlinedLabel>
      <VerticalSpacer size="xl" />
      <Box
        marginTop="xxxl"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PrimaryButton
          disabled={!project.url || project.url === 'noUrl' ? true : false}
          onClick={() => window.open(checkHttp(project.url), '_blank')}
        >
          {translate('rightSidebar.downButton.label')}
        </PrimaryButton>
      </Box>
    </DefaultRightSidebar>
  );
};
