import React, { useEffect, useState } from 'react';
import { Paragraph } from '../../../../../shared/components';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { ProjectJson } from '../../../domain';
import { ImportProjectListItems } from './importProjectListItems';
import { translate } from './translate';

export const ImportOverview = ({
  projects,
}: {
  projects: ProjectJson[];
}): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!projects) {
      return;
    }

    let rowErrors = 0;
    const invalidColumns: string[] = [];

    projects.forEach(project => {
      const invalidProps = project.invalidProperties();
      invalidProps.length > 0 && rowErrors++;

      invalidProps.forEach(prop => {
        if (!invalidColumns.includes(prop.attribute)) {
          invalidColumns.push(prop.attribute);
        }
      });
    });

    setErrorMessage(
      `${translate('validation.errorIn')} ${invalidColumns.length} ${
        invalidColumns.length === 1
          ? translate('validation.column')
          : translate('validation.columns')
      } ${translate('validation.and')} ${rowErrors} ${
        rowErrors === 1
          ? translate('validation.row')
          : translate('validation.rows')
      } ${translate('validation.found')}.`,
    );
  }, [projects]);

  return (
    <>
      <Paragraph weight="bold">{errorMessage}</Paragraph>
      <VerticalSpacer size="xl" />
      <ImportProjectListItems projects={projects} />
    </>
  );
};
