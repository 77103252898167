import { getReportsActionTypes } from './actions';
import { ActionInterface, ReportsState } from '../domain/state';
import { reduceListToEntities } from '../../../shared/redux/reducers/reducerHelpers';

export const reportsInitialState: ReportsState = {
  entities: {},
  institutes: {},
};

export const reportsReducer = (
  state: ReportsState = reportsInitialState,
  action: ActionInterface,
): ReportsState => {
  switch (action.type) {
    case getReportsActionTypes.success: {
      return {
        ...state,
        entities: reduceListToEntities(action.payload.clusters, 'name'),
        institutes: reduceListToEntities(action.payload.institutes, 'name'),
      };
    }
  }
  return state;
};
