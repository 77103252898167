import _ from 'lodash';
import { TUser } from '../domain';

const authenticationToken = (state: any): string | null =>
  _.get(state, 'persisted.session.authenticationToken');

const refreshToken = (state: any): string | null =>
  _.get(state, 'persisted.session.refreshToken');

const currentUser = (state: any): TUser | null =>
  _.get(state, 'persisted.session.currentUser');

const sessionSelectors = {
  authenticationToken,
  refreshToken,
  currentUser,
};
export { sessionSelectors };
