import { TopicRatingValue } from '../../app/Project/domain';

export const topicRatingValueMap: { [key: number]: TopicRatingValue } = {
  0: TopicRatingValue.ZERO,
  1: TopicRatingValue.ONE,
  2: TopicRatingValue.TWO,
  3: TopicRatingValue.THREE,
  4: TopicRatingValue.FOUR,
  5: TopicRatingValue.FIVE,
  6: TopicRatingValue.SIX,
  7: TopicRatingValue.SEVEN,
  8: TopicRatingValue.EIGHT,
  9: TopicRatingValue.NINE,
  10: TopicRatingValue.TEN,
};

export const topicRatingValueNumberMap: {
  [key: string]: number;
} = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
};
