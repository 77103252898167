import { ReportsState } from '../domain/state';
import { TCluster, TInstitute } from '../domain/cluster';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const R = require('ramda');

interface State {
  persisted: {
    reports: ReportsState;
  };
}

const persistedLens = R.lensProp('persisted');
const reportsLens = R.lensProp('reports');
const entitiesLens = R.lensProp('entities');
const institutesLens = R.lensProp('institutes');

export const reportsListLens = R.compose(
  persistedLens,
  reportsLens,
  entitiesLens,
);

export const institutesListLens = R.compose(
  persistedLens,
  reportsLens,
  institutesLens,
);

const mapToList = (data: { [key: string]: TCluster }): TCluster[] =>
  Object.keys(data).map((id: string) => data[id]);

const mapToListInstitute = (data: {
  [key: string]: TInstitute;
}): TInstitute[] => Object.keys(data).map((id: string) => data[id]);

const getReports = (state: State): TCluster[] =>
  mapToList(R.view(reportsListLens, state));

const getInstitutes = (state: State): TInstitute[] => {
  return mapToListInstitute(R.view(institutesListLens, state));
};
export const reportsSelectors = {
  getList: getReports,
  getInstitutesList: getInstitutes,
};
