/* eslint-disable @typescript-eslint/camelcase */

import { getTopicRatingActionTypes } from './actions';
import { TopicRatingValue } from '../domain';
import { TId } from '../../../shared/domain';

export type TopicRating = {
  id: TId;
  technologyRating: TopicRatingValue;
  marketRating: TopicRatingValue;
  consortiumRating: TopicRatingValue;
  projectId: TId;
};

export interface State {
  by_project_id: { [key: string]: TopicRating };
}

interface ActionInterface {
  type: string;
  payload?: any;
}

export const initialState: State = {
  by_project_id: {},
};

export const projectTopicRatingReducer = (
  state: State = initialState,
  action: ActionInterface,
): State => {
  switch (action.type) {
    case getTopicRatingActionTypes.success: {
      if (action.payload && action.payload.topicRating) {
        const rating: TopicRating = action.payload.topicRating;
        return {
          ...state,
          by_project_id: { ...state.by_project_id, [rating.projectId]: rating },
        };
      }
    }
  }

  return state;
};

export default projectTopicRatingReducer;
