import { RouteVisibilityAuthentication } from '../../../shared/routes/utils/RouteVisibility';
import { UserDetails } from './pages';

export const UserRoutes = {
  user: { profile: '/profile' },
};

export const UserRoutesConfig = [
  {
    path: UserRoutes.user.profile,
    Component: UserDetails,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
    },
  },
];
