export const chartSize = 273;
export const chartSizeHorizontal = 3000;
export const columnSize = 40;
export const updateTime = 7000;

export enum VoteColor {
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  GREY = 'GREY',
  EMPTY = 'EMPTY',
}
