import { refreshTokenApi } from '../data/refreshTokenApi';
import signinApi from '../data/signinApi';
import {
  refreshTokenActionTypes,
  resetPasswordActionTypes,
  signinActionTypes,
} from './actions';
import { createEffect } from '../../../shared/redux/actionTypes/createEffect';
import { resetPasswordApi } from '../data/resetPasswordApi';

export const refreshTokenAction = createEffect(
  refreshTokenActionTypes,
  refreshTokenApi,
  ({ refreshToken }: { refreshToken: string }) => ({
    refreshToken,
  }),
);

export const resetPasswordAction = createEffect(
  resetPasswordActionTypes,
  resetPasswordApi,
  ({ token, password }: { token: string; password: string }) => ({
    token,
    account: {
      password,
    },
  }),
  false,
);

export const signinAction = createEffect(
  signinActionTypes,
  signinApi,
  ({ email, password }: { email: string; password: string }) => ({
    account: {
      email,
      password,
    },
  }),
);
