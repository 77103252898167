import { useDispatch } from 'react-redux';
import { TopicRatingValue } from '../domain';
import { projectActions } from '../redux/effects';

type Params = {
  projectId: string;
  topicRatingId: string;
  technologyRating: TopicRatingValue | null;
  marketRating: TopicRatingValue | null;
  consortiumRating: TopicRatingValue | null;
};

export const useUpdateTopicRating = (): {
  updateTopicRating: (params: Params) => void;
} => {
  const dispatch = useDispatch();

  const updateTopicRating = ({
    projectId,
    topicRatingId,
    technologyRating,
    marketRating,
    consortiumRating,
  }: Params): void => {
    dispatch(
      projectActions.editTopicRatingAction({
        id: projectId,
        topicRatingId,
        technologyRating,
        marketRating,
        consortiumRating,
      }),
    );
  };

  return {
    updateTopicRating,
  };
};
