import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FlexBox, H4, Paragraph } from '../../../../../../shared/components';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../shared/components/spacers';
import { topicRatingValueMap } from '../../../../../../shared/constants/topicRatings';
import { useRequestOnMountWithMultipleParams } from '../../../../../../shared/hooks';
import { useCreateTopicRating } from '../../../../application/use_create_topic_rating';
import { useUpdateTopicRating } from '../../../../application/use_update_topic_rating';
import { TopicRatingValue, TProject } from '../../../../domain';
import { projectActions } from '../../../../redux/effects';
import { topicRatingSelectors } from '../../../../redux/projectTopicRatingSelectors';
import { translate } from '../translate';
import { RatingInfo } from './ratingInfo';
import { RatingSelector } from './ratingSelector';

export const TopicEvaluation: React.FC<{ project: TProject }> = ({
  project,
}) => {
  const { createTopicRating } = useCreateTopicRating();
  const { updateTopicRating } = useUpdateTopicRating();
  const [rating, setRating] = useState<{
    technologyRating: TopicRatingValue | null;
    marketRating: TopicRatingValue | null;
    consortiumRating: TopicRatingValue | null;
  }>({
    technologyRating: null,
    marketRating: null,
    consortiumRating: null,
  });

  const oldRating = useSelector(topicRatingSelectors.by_project(project.id));

  useEffect(() => {
    if (oldRating) {
      setRating(oldRating);
    }
  }, [oldRating]);

  useRequestOnMountWithMultipleParams(
    projectActions.getProjectTopicRatingAction({
      id: project.id,
      onFailure: () => {
        createTopicRating({ ...rating, projectId: project.id });
      },
    }),
  );

  const isFirstTime = (): boolean => !oldRating;

  const onUpdateRating = (newRating: {
    technologyRating: TopicRatingValue | null;
    marketRating: TopicRatingValue | null;
    consortiumRating: TopicRatingValue | null;
  }): void => {
    setRating(newRating);

    if (isFirstTime()) {
      createTopicRating({ ...newRating, projectId: project.id });
    } else {
      updateTopicRating({
        ...newRating,
        projectId: project.id,
        topicRatingId: oldRating.id,
      });
    }
  };

  return (
    <>
      <H4>{translate('your_feedback')}</H4>
      <VerticalSpacer size="md" />
      <FlexBox.Row>
        <Paragraph>{translate('topicEvaluation.instructions')}</Paragraph>
        <HorizontalSpacer size="sm" />
        <RatingInfo />
      </FlexBox.Row>
      <VerticalSpacer size="sm" />
      <RatingSelector
        onChange={(value): void =>
          onUpdateRating({ ...rating, technologyRating: value })
        }
        selectedValue={rating.technologyRating}
        title={translate('topicEvaluation.technology')}
        options={topicRatingValueMap}
      />
      <RatingSelector
        onChange={(value): void =>
          onUpdateRating({ ...rating, marketRating: value })
        }
        selectedValue={rating.marketRating}
        title={translate('topicEvaluation.market')}
        options={topicRatingValueMap}
      />
      <RatingSelector
        onChange={(value): void =>
          onUpdateRating({ ...rating, consortiumRating: value })
        }
        selectedValue={rating.consortiumRating}
        title={translate('topicEvaluation.consortium')}
        options={topicRatingValueMap}
      />
    </>
  );
};
