import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Segments } from '../../../../shared/constants/segments';

import { useUploadProjects } from '../../application/use_upload_projects';
import { ProjectJson } from '../../domain';
import {
  filterProjectListBySegmentAction,
  projectActions,
} from '../../redux/effects';
import { projectsUploadSelectors } from '../../redux/projectsUploadSelectors';
import { ImportLayout } from '../components/import/importLayout';
import { ImportOverview } from '../components/import/importOverview';
import { UploadProgress } from '../components/import/uploadProgress';
import { ProjectRoutes } from '../routes';

export const ProjectsImport: React.FC = () => {
  const [isParsingFile, setIsParsingFile] = useState(true);
  const [projects, setProjects] = useState<ProjectJson[]>([]);
  const file = useSelector(projectsUploadSelectors.getFile);
  const { isLoading, uploadProjects } = useUploadProjects();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (file) setIsParsingFile(true);
  }, [file]);

  const parseFileContent = (fileContent: string): void => {
    const parsedContent = JSON.parse(fileContent);
    const projects = parsedContent.map((proj: any) => {
      return ProjectJson.createFromJson(proj);
    });

    setProjects(projects);
  };

  const onFinishProcessingFile = (fileContent: string): void => {
    parseFileContent(fileContent);
    setIsParsingFile(false);
  };

  const handleUploadProjects = (): void => {
    uploadProjects(projects, () => {
      dispatch(filterProjectListBySegmentAction(Segments.recentlyImported));
      dispatch(projectActions.dashboardData());
      history.push(ProjectRoutes.projects.list);
    });
  };

  return (
    <ImportLayout
      isLoading={isLoading}
      onUploadProjects={handleUploadProjects}
      canImport={projects.every(project => project.isValidImport())}
    >
      {isParsingFile ? (
        <UploadProgress file={file} onDone={onFinishProcessingFile} />
      ) : (
        <ImportOverview projects={projects} />
      )}
    </ImportLayout>
  );
};
