import React from 'react';
import { FlexBox, Box, Link, icons, Paragraph } from '.';

export const NavigateBackTextWithIcon = (props: {
  route: string;
  text: string;
  state?: any;
}): JSX.Element => (
  <Link
    to={{
      pathname: props.route,
      state: props.state,
    }}
  >
    <FlexBox.Row>
      <icons.chevronLeft />
      <Box marginLeft="xs">
        <Paragraph size="small">{props.text}</Paragraph>
      </Box>
    </FlexBox.Row>
  </Link>
);
