import { TId } from '../../../shared/domain';
import {
  TAdmin,
  TProgramManager,
  TProgramManagerAdmin,
  TResearchCoordinator,
  TThinkTank,
  TThinkTankAdmin,
} from '../../Auth/domain';
import { EvaluationValues } from '../../Project/data/evaluateProjectApi';
import { TopicRatingValue } from '../../Project/domain';

export enum AdminRating {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  ON_HOLD = 'ON_HOLD',
}

export interface TProjectAdminRating {
  id: string;
  rating: string;
  projectId: TId;
  adminId: string;
}

export type BaseEvaluation = {
  id: string;
  comment: string;
  evaluation: EvaluationValues;
  creationDate: string;
  author: string;
  authorId: TId;
  role: string;
};

export enum ReactionValues {
  AGREE = 'AGREE',
  DISAGREE = 'DISAGREE',
}

export type TReaction = {
  id: TId;
  reaction: ReactionValues;
  author: string;
  authorId: TId;
  creationDate: string;
};

export type TEvaluation = BaseEvaluation & {
  responses: TEvaluation[];
  reactions: TReaction[];
};

export type TVote = {
  researchCoordinatorId: string;
  vote: number;
};

export type TCoordinator = {
  researchCoordinatorId: string;
  name: string;
  projectsCount: number;
  position: number;
};

export type TTopicRating = {
  id: string;
  admin: TAdmin | null;
  consortiumRating: TopicRatingValue | null;
  marketRating: TopicRatingValue | null;
  technologyRating: TopicRatingValue | null;
  programManagerAdmin: TProgramManagerAdmin | null;
  programManager: TProgramManager | null;
  projectId: string;
  researchCoordinator: TResearchCoordinator | null;
  thinkTankAdmin: TThinkTankAdmin | null;
  thinkTank: TThinkTank | null;
};

export type DashboardResponseData = {
  id: TId;
  shortName: string;
  name: string;
  cluster: string;
  evaluations: TEvaluation[];
  votes: TVote[];
  votesTT: string[];
  votesPM: string[];
  adminRating: AdminRating[];
  coordinators: TCoordinator[];
  topicRatings: TTopicRating[];
};
