import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { ProjectJson, TProject } from '../domain';

export const uploadProjectsApi = ({
  authenticationToken,
  projects,
}: {
  authenticationToken: string;
  projects: ProjectJson[];
}): AxiosPromise<{ projects: TProject[] }> =>
  fetchApiWithAuthRequest({
    url: apiUrl(endpoints.projects.upload),
    method: httpMethods.post,
    authenticationToken,
    data: { projects: projects.map(p => p.toDTO()) },
  });
