export const chartColors = {
  grey: '#F3F3F3',
  green: '#409280',
  white: '#fff',
  black: '#333',
  blueGrey: '#59697F',
};

export const ChartBarWidth = 60;
export const ChartBarHeight = 210;
