import React, { useRef } from 'react';
import { DashboardResponseData } from '../../../domain';
import { ChartBubblesHorizontal } from './chartBubbleHorizontal';
import { ChartMapper } from './chartMapper';
import { DashboardProject } from './sampleData';

export const VotesGraph: React.FC<{
  projects: DashboardResponseData[];
  selectedProject: DashboardResponseData | null;
}> = ({ projects, selectedProject }) => {
  const reference = useRef(null);

  const items = ChartMapper(projects);
  const selected: DashboardProject = items?.filter(
    item => item?.id === selectedProject?.id,
  )[0];
  return (
    <div ref={reference}>
      <ChartBubblesHorizontal project={selected} />
    </div>
  );
};
