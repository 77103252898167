import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../../../../../shared/components';
import { HorizontalSpacer } from '../../../../../../shared/components/spacers';
import { useBackRoute, useModal } from '../../../../../../shared/hooks';
import {
  setEvaluationCommentStateAction,
  setEvaluationModalVisibilityAction,
} from '../../../../redux/effects';
import { projectSelectors } from '../../../../redux/projectSelectors';
import styles from '../index.module.scss';
import { translate } from '../translate';
import { CloseConfirmationModal } from './closeConfirmationModal';

export const EvaluationBottomBar: React.FC<{}> = () => {
  const { back } = useBackRoute();
  const dispatch = useDispatch();
  const hasUnsavedComment = useSelector(projectSelectors.getHasUnsavedComment);
  const closeConfirmationModal = useModal(false);
  const [isCancel, setIsCancel] = useState(true);
  const showEvaluationModal = useSelector(
    projectSelectors.getShouldShowEvaluationModal,
  );

  const closeEvaluation = (isCancel: boolean): void => {
    setIsCancel(isCancel);

    if (hasUnsavedComment) {
      closeConfirmationModal.open();
    } else {
      back();
    }
  };

  const closeConfirmed = (): void => {
    dispatch(setEvaluationCommentStateAction(false));
    back();
  };

  useEffect(() => {
    if (showEvaluationModal) {
      dispatch(setEvaluationModalVisibilityAction(false));
      closeConfirmationModal.open();
    }
  }, [showEvaluationModal]);

  return (
    <>
      <div className={styles.evaluation__bottomBar}>
        <PrimaryButton
          onClick={(): void => closeEvaluation(true)}
          className={styles.evaluation__cancelButton}
        >
          {translate('topicEvaluation.cancel')}
        </PrimaryButton>
        <HorizontalSpacer size="md" />
        <PrimaryButton onClick={(): void => closeEvaluation(false)}>
          {translate('topicEvaluation.close')}
        </PrimaryButton>
      </div>
      <CloseConfirmationModal
        modal={closeConfirmationModal}
        onConfirm={closeConfirmed}
        isCancel={isCancel}
      />
    </>
  );
};
