import React from 'react';

import { TinyLettersCircle } from './circles';
import { TopDarkTooltip } from './tooltips';
import styles from './reactions.module.scss';
import { FlexBox } from './boxes';
import { iconColors, iconSizes } from '../constants';
import { HorizontalSpacer, VerticalSpacer } from './spacers';
import { Paragraph } from './typographies';
import { icons } from './icons';
import { getTranslateByScope } from '../i18n';
import { TId } from '../domain';
import { useReactToEvaluation } from '../../app/Dashboard/application/use_react_to_evaluation';
import { Spinners } from './spinners';
import { useGetMyReactionForProjectEvaluation } from '../../app/Dashboard/application/use_get_my_reaction_for_project_evaluation';
import { TEvaluation, ReactionValues } from '../../app/Dashboard/domain';
import { Evaluation } from '../../app/Dashboard/domain/evaluation';
import { Reaction } from '../../app/Dashboard/domain/reaction';

const translate = getTranslateByScope('ui.layouts.ProjectDetail');

const Badge: React.FC<{ number: number; isVisible: boolean }> = ({
  number,
  isVisible,
}) => {
  if (!isVisible) return <div className={styles.badgeSpacer} />;
  return (
    <div className={styles.badge}>
      <TinyLettersCircle>{number}</TinyLettersCircle>
    </div>
  );
};

const ReactionButton: React.FC<{
  Icon: any;
  label: string;
  reactions: Reaction[];
  isSelected: boolean;
  onClick: () => void;
}> = ({ Icon, isSelected, label, reactions, onClick }) => {
  const authors = reactions.map(r => r.author);

  return (
    <>
      <FlexBox marginRight="lg" alignItems="center">
        <TopDarkTooltip
          isDisabled={authors.length === 0}
          ariaLabel={authors.join(', ')}
          label={
            <span>
              {authors.map((author, index) => (
                <div key={index}>
                  {author}
                  <br />
                </div>
              ))}
            </span>
          }
        >
          <FlexBox>
            <Badge isVisible={reactions.length > 0} number={reactions.length} />
            <button
              onClick={onClick}
              className={isSelected ? styles.selectedButton : styles.button}
            >
              <Icon
                color={isSelected ? iconColors.primary : iconColors.grey}
                size={Icon === icons.coach ? iconSizes.sm : iconSizes.xs2}
              />
            </button>
          </FlexBox>
        </TopDarkTooltip>
        <HorizontalSpacer size="sm" />
        <a className={styles.buttonLabel} onClick={onClick}>
          <Paragraph color="black2" size="small">
            {label}
          </Paragraph>
        </a>
      </FlexBox>
    </>
  );
};

const CommentsIcon: React.FC<{
  commentsCount: number;
  onClick: () => void;
}> = ({ commentsCount, onClick }) => (
  <FlexBox>
    <FlexBox paddingTop="xs">
      <Badge isVisible={commentsCount > 0} number={commentsCount} />
    </FlexBox>
    <FlexBox marginRight="lg" alignItems="center">
      <button onClick={onClick} className={styles.comments}>
        <icons.coach color={iconColors.grey} size={iconSizes.sm} />
      </button>
      <HorizontalSpacer size="sm" />
      <a className={styles.buttonLabel} onClick={onClick}>
        <Paragraph color="black2" size="small">
          {translate('modal.comments.reactions.comments')}
        </Paragraph>
      </a>
    </FlexBox>
  </FlexBox>
);

export const Reactions: React.FC<{
  evaluation: TEvaluation;
  projectId: TId;
  onToggleCommentReply?: () => void;
}> = props => {
  const evaluation = Evaluation.create(props.evaluation);
  const userReaction = useGetMyReactionForProjectEvaluation({
    projectId: props.projectId,
    evaluationId: evaluation.id,
  });
  const { isLoading, reactToEvaluation } = useReactToEvaluation();
  const handleReaction = (reaction: ReactionValues): void => {
    reactToEvaluation({
      projectId: props.projectId,
      evaluationId: evaluation.id,
      evaluationReaction: { reaction },
    });
  };

  return (
    <FlexBox className={styles.container} marginTop="sm">
      {isLoading ? (
        <FlexBox alignItems="center">
          <Spinners.Primary />
        </FlexBox>
      ) : (
        <>
          <ReactionButton
            isSelected={!!userReaction && userReaction.isAgree()}
            Icon={icons.like}
            label={translate('modal.comments.reactions.agree')}
            reactions={evaluation.getLikes()}
            onClick={(): void => handleReaction(ReactionValues.AGREE)}
          />
          <ReactionButton
            isSelected={!!userReaction && userReaction.isDisagree()}
            Icon={icons.dislike}
            label={translate('modal.comments.reactions.disagree')}
            reactions={evaluation.getDislikes()}
            onClick={(): void => handleReaction(ReactionValues.DISAGREE)}
          />
          {!!props.onToggleCommentReply && (
            <CommentsIcon
              commentsCount={evaluation.responses.length}
              onClick={props.onToggleCommentReply}
            />
          )}
        </>
      )}
    </FlexBox>
  );
};
