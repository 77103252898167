import { TApiActionsTypes, TRequestAction } from '../../domain';

export const createEffect = (
  action: TApiActionsTypes,
  apiMethod?: Function,
  apiParams?: Function,
  isAuthenticated = true,
) => {
  return (params?: any): TRequestAction => {
    return {
      type: action.request,
      payload: {
        isAuthenticated: isAuthenticated,
        apiMethod: apiMethod ? apiMethod : () => null,
        failureActionType: action.failure,
        successActionType: action.success,
        onSuccess: params?.onSuccess || undefined,
        onFailure: params?.onFailure || undefined,
        params: apiParams ? apiParams(params) : undefined,
      },
    };
  };
};
