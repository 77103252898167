import React, { useState } from 'react';
import {
  ButtonIcon,
  FlexBox,
  icons,
  Paragraph,
  PrimaryButton,
  Textarea,
} from '../../../../../../shared/components';
import { translate } from '../../detail/translate';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../shared/components/spacers';
import { EvaluationValues } from '../../../../data/evaluateProjectApi';
import { iconColors } from '../../../../../../shared/constants';
import { BaseEvaluation } from '../../../../../Dashboard/domain';
import { useDispatch } from '../../../../../../shared/hooks';
import styles from '../../../../../../shared/components/comment.module.scss';
import { editEvaluationProjectAction } from '../../../../redux/effects';
import { TId } from '../../../../../../shared/domain';

export const EvaluationCommentEdit: React.FC<{
  projectId?: TId;
  closeEdit: () => void;
  author: string;
  evaluationToEdit: BaseEvaluation;
  isListLayout?: boolean;
}> = ({ projectId, closeEdit, evaluationToEdit, isListLayout, author }) => {
  const dispatch = useDispatch();
  const [evaluation, setEvaluation] = useState<EvaluationValues>(
    evaluationToEdit.evaluation,
  );
  const [commentText, setCommentText] = useState<string>(
    evaluationToEdit.comment,
  );

  const isActive = (e: EvaluationValues): boolean => e === evaluation;
  const hasErrorEvaluation = (): boolean => !evaluation;
  const hasErrorCommentText = (): boolean => !commentText;

  const toggleEvaluation = (e: EvaluationValues): void => {
    setEvaluation(e);
  };

  const hasChanged = (): boolean =>
    evaluationToEdit.comment !== commentText ||
    evaluationToEdit.evaluation !== evaluation;

  const onSubmit = (): void => {
    if (projectId) {
      dispatch(
        editEvaluationProjectAction({
          id: projectId,
          evaluationId: evaluationToEdit.id,
          comment: commentText,
          evaluation,
        }),
      );
      closeEdit();
    }
  };

  return (
    <div className={styles.commentEdit}>
      <div>
        <FlexBox.Row alignItems="center">
          <Paragraph color="black2" weight="bold">
            {author}
          </Paragraph>
        </FlexBox.Row>
        <VerticalSpacer size="md" />
        {hasErrorEvaluation() && (
          <Paragraph size="small" color="red">
            {translate('modal.evaluationIconError')}
          </Paragraph>
        )}
        <FlexBox.Row>
          <FlexBox.Column alignItems="center">
            <ButtonIcon
              onClick={(): void => toggleEvaluation(EvaluationValues.OK)}
              active={isActive(EvaluationValues.OK)}
              icon={icons.like}
            />
            <VerticalSpacer size="sm" />
            <ButtonIcon
              onClick={(): void => toggleEvaluation(EvaluationValues.DOUBT)}
              active={isActive(EvaluationValues.DOUBT)}
              icon={icons.questionCircle}
            />
            <VerticalSpacer size="sm" />
            <ButtonIcon
              onClick={(): void => toggleEvaluation(EvaluationValues.KO)}
              active={isActive(EvaluationValues.KO)}
              icon={icons.dislike}
            />
          </FlexBox.Column>

          <HorizontalSpacer size="md" />

          <Textarea
            rows={4}
            onChange={(v: string): void => {
              setCommentText(v);
            }}
            placeholder={translate('modal.textareaPlaceholder')}
            hasError={false}
          >
            {commentText}
          </Textarea>

          <HorizontalSpacer size="md" />
          <FlexBox.Column justifyContent="start">
            <ButtonIcon
              onClick={closeEdit}
              active
              activeColor={iconColors.grey}
              icon={icons.close}
            />
          </FlexBox.Column>
          <VerticalSpacer size="md" />
        </FlexBox.Row>
        {hasErrorCommentText() && (
          <Paragraph size="small" color="red">
            {translate('modal.commentError')}
          </Paragraph>
        )}
        <VerticalSpacer size="md" />

        <FlexBox.Row>
          <PrimaryButton onClick={onSubmit} disabled={!hasChanged()}>
            {translate('modal.editButton')}
          </PrimaryButton>
        </FlexBox.Row>
        <VerticalSpacer size="md" />
      </div>
    </div>
  );
};
