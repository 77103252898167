import React, { useRef, useState } from 'react';
import { ButtonIcon, EaseInBox, FlexBox, H1, H4, icons, If } from './index';
import { AuthenticatedSidebar } from './Sidebar';
import styles from './sidebar-layout.module.scss';
import { joinClassNames } from '../../shared/utils';
import { iconSizes, isTablet } from '../../shared/constants';

export const SidebarLayout = ({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) => {
  const reference = useRef(null);
  const [sidebar, setSidebar] = useState(false);

  return (
    <div ref={reference} style={{ height: '100vh', overflow: 'hidden' }}>
      <If condition={isTablet}>
        {() => (
          <FlexBox.Column>
            <div className={styles.sidebarLayout__topbar}>
              <ButtonIcon
                active={true}
                icon={icons.burger}
                size={iconSizes.lg}
                onClick={() => setSidebar(!sidebar)}
              />
              <H4>{title}</H4>
              <div className={styles.sidebarLayout__hiddenButton}>
                <ButtonIcon
                  active={false}
                  icon={icons.burger}
                  onClick={() => null}
                />
              </div>
            </div>
            <FlexBox.Row>
              <div
                className={joinClassNames(
                  styles.sidebarLayout__sidebar,
                  sidebar ? styles.sidebarLayout__sidebarOpen : null,
                )}
              >
                <AuthenticatedSidebar />
              </div>

              <EaseInBox
                fullWidth
                style={{ overflow: 'auto', height: '100vh' }}
              >
                <>
                  <H1 className={styles.sidebarLayout__title}>{title}</H1>
                  <div
                    className={joinClassNames(
                      styles.sidebarLayout__content,
                      sidebar ? styles.sidebarLayout__contentOpen : null,
                    )}
                    onClick={() => setSidebar(!sidebar)}
                  />
                  {children}
                </>
              </EaseInBox>
            </FlexBox.Row>
          </FlexBox.Column>
        )}
      </If>
      <If condition={!isTablet}>
        {() => (
          <FlexBox style={{ overflow: 'auto' }}>
            <AuthenticatedSidebar />
            <EaseInBox fullWidth style={{ overflow: 'auto', height: '100vh' }}>
              {children}
            </EaseInBox>
          </FlexBox>
        )}
      </If>
    </div>
  );
};
