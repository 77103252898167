import html2canvas from 'html2canvas';
import { EXPORT_TYPE_SVG } from '../presentation/components/chartReport/constants';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const htmlToImage = require('html-to-image');

const download = (url: string, filename: string) => {
  fetch(url).then(function(t) {
    return t.blob().then(b => {
      // eslint-disable-next-line prefer-const
      let a = document.createElement('a');
      a.href = URL.createObjectURL(b);
      a.setAttribute('download', filename);
      a.click();
    });
  });
};

export class ScreenshotService {
  captureReport = (elementId: string, name: string, exportType: string) => {
    if (exportType === EXPORT_TYPE_SVG) {
      htmlToImage
        .toSvg(document.getElementById(elementId) || document.body)
        .then((canvas: any) => {
          download(canvas, name);
        });
    } else {
      html2canvas(
        document.getElementById(elementId) || document.body,
      ).then(canvas => download(canvas.toDataURL('image/png'), name));
    }
  };
}
