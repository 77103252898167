import { createAction } from '../../../shared/redux/actionTypes/createAction';

export const listAdminProjectActionTypes = createAction('PROJECTS_ADMIN');
export const getDashboardDataActionTypes = createAction('DASHBOARD_DATA');
export const changeFilterAdminProjectListActionTypes = createAction(
  'FILTER_ADMIN_PROJECT_LIST_BY_SEGMENT',
);
export const adminRateProjectActionTypes = createAction('PROJECTS_ADMIN_RATE');
export const editAdminRatingActionTypes = createAction(
  'PROJECTS_EDIT_ADMIN_RATING',
);
export const replyToEvaluationActionTypes = createAction(
  'PROJECT_EVALUATIONS_REPLY',
);
export const reactToEvaluationActionTypes = createAction(
  'PROJECT_EVALUATIONS_REACT',
);
