import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  FlexBox,
  H1,
  PageContainer,
  PrimaryButton,
} from '../../../../shared/components';
import { getTranslateByScope } from '../../../../shared/i18n';
import { VerticalSpacer } from '../../../../shared/components/spacers';

import { SidebarLayout } from '../../../../shared/components/sidebar-layout';
import Chart from '../components/chart';
import { AdminProjectList } from '../components/adminProjectList/adminProjectList';
import { roleSelectors } from '../../../Auth/redux/roles';
import { roles } from '../../../Auth/domain';
import { dashboardSelectors } from '../../redux/selectors';
import { DashboardResponseData } from '../../domain';

export const translate = getTranslateByScope('ui.layouts.Dashboard');

const Projects = (): JSX.Element => {
  const role = useSelector(roleSelectors.getRole);
  const [clusterButtonState, setClusterButtonState] = useState<{
    label: string;
    disabled: boolean;
    onClick: Function;
  }>({ label: '', disabled: true, onClick: () => null });

  const list: DashboardResponseData[] = useSelector(dashboardSelectors.getList);
  const [filteredList, setFilteredList] = useState<DashboardResponseData[]>(
    list,
  );
  const [filter, setFilter] = useState<string | null>(null);
  const [project, setProject] = useState<DashboardResponseData | null>(null);

  useEffect(() => {
    if (filter === null && filteredList.length === 0 && list.length > 0) {
      setFilteredList(list);
    }
  }, [list, filter, filteredList]);

  useEffect(() => {
    const setFilteredProjects = (cluster: string | null): void => {
      setFilteredList(
        list.filter(proj => !cluster || proj.cluster === cluster),
      );
      setFilter(cluster);
    };

    setClusterButtonState({
      disabled: project === null || list.length === 0,
      label: !!filter
        ? translate('clusterFilter.labelShowAll')
        : translate('clusterFilter.labelShowFiltered'),
      onClick: () =>
        !!filter
          ? setFilteredProjects(null)
          : setFilteredProjects(project?.cluster ?? null),
    });
  }, [project, filter, list, setClusterButtonState]);

  return (
    <SidebarLayout title={translate('header.title')}>
      <>
        <PageContainer type="asFullWidthInBigScreen">
          <FlexBox.Row justifyContent="space-between">
            <H1>{translate('header.title')}</H1>
            <PrimaryButton
              onClick={(): void => clusterButtonState.onClick()}
              disabled={clusterButtonState.disabled}
            >
              {clusterButtonState.label}
            </PrimaryButton>
          </FlexBox.Row>

          <VerticalSpacer size="md" />
          <Chart
            list={list}
            filteredList={filteredList}
            project={project}
            setProject={setProject}
            filter={filter}
          />
          {role === roles.admin ? (
            <>
              <VerticalSpacer size="lg" />
              <AdminProjectList selectedProject={project} />
            </>
          ) : (
            <></>
          )}
        </PageContainer>
        <VerticalSpacer size="lg2" />
      </>
    </SidebarLayout>
  );
};

export default Projects;
