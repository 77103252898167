import React from 'react';
import {
  ConfirmationModal,
  H4,
  Paragraph,
  Separator,
} from '../../../../../../shared/components';
import { VerticalSpacer } from '../../../../../../shared/components/spacers';

import { translate } from '../translate';

export const CloseConfirmationModal: React.FC<{
  modal: any;
  onConfirm: () => void;
  isCancel: boolean;
}> = ({ modal, onConfirm, isCancel }) => {
  return (
    <ConfirmationModal
      onCancel={modal.close}
      onConfirm={onConfirm}
      isOpen={modal.isOpen}
      confirmText={
        isCancel
          ? translate('closeModal.cancelConfirm')
          : translate('closeModal.closeConfirm')
      }
    >
      {modal.isOpen && (
        <div>
          <H4 color="black2" style={{ textAlign: 'center' }}>
            {isCancel
              ? translate('closeModal.cancelTitle')
              : translate('closeModal.closeTitle')}
          </H4>
          <VerticalSpacer size="lg" />
          <Separator.Light />
          <VerticalSpacer size="lg" />
          <Paragraph color="black2">
            {isCancel
              ? translate('closeModal.cancelWarning')
              : translate('closeModal.closeWarning')}
          </Paragraph>
        </div>
      )}
    </ConfirmationModal>
  );
};
