import { createAction } from '../actionTypes/createAction';
import { TToasterTypes } from '../../domain';

export interface ActionType {
  type: string;
  payload: {
    description: string | null;
    type: TToasterTypes;
  };
}

export const showToasterActionTypes = createAction('SHOW_TOASTER_ACTION_TYPE');

export const showToasterAction = ({
  description,
  type,
}: {
  description: string | null;
  type?: TToasterTypes;
}): ActionType => ({
  type: showToasterActionTypes.request,
  payload: {
    description,
    type: type || 'success',
  },
});
