/* eslint-disable @typescript-eslint/camelcase */

import _ from 'lodash';
import { Ranking, State as prState } from './projectsRankingReducer';
import { projectSelectors } from './projectSelectors';
import { TProject } from '../domain';
import { TId } from '../../../shared/domain';

type State =
  | {
      persisted: {
        projectRanking: prState;
      };
    }
  | {};

const by_position = (state: State): Ranking =>
  _.get(state, 'persisted.projectRanking.by_position');

const ranked = (state: State): TProject[] => {
  const rankings: Ranking = by_position(state);
  const projects = projectSelectors.getById(state);

  const a: TId[] = Object.keys(rankings).reduce(
    (prev: any, e: any, index: number) => {
      return [...prev, rankings[e]];
    },
    [],
  );
  return _.uniq([...a]).map((e: TId) => projects[e]);
};

const unranked = (state: State): TProject[] => {
  const myProjectsIds: TId[] = projectSelectors.getMyIds(state) || [];
  const rankings: Ranking = by_position(state) || {};
  const projects = projectSelectors.getById(state) || {};

  return myProjectsIds
    .filter(id => !Object.values(rankings).includes(id))
    .map(id => projects[id]);
};

export const projectRankingSelectors = {
  by_position,
  ranked,
  unranked,
};
