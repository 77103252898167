import React, { RefObject, useEffect, useRef, useState } from 'react';
import styles from './dropdown.module.scss';
import { Paragraph, Sizes } from '../typographies';
import { icons } from '../icons';
import { iconSizes } from '../../../shared/constants';
import { HorizontalSpacer } from '../spacers';

export interface DropdownItem {
  id: any;
  value: string;
  disabled?: boolean;
}

export const useOutsideClick = (
  ref: RefObject<HTMLDivElement>,
): { [key: string]: any } => {
  const [open, setOpen] = useState(false);
  const disabledClicksClass = 'disabledClicks';

  const toggleValue = (): void => {
    if (!open) {
      document.body.classList.add(disabledClicksClass);
    } else {
      document.body.classList.remove(disabledClicksClass);
    }
    setOpen(!open);
  };
  const close = (): void => setOpen(false);

  useEffect(() => {
    const hasClickedOutside = (event: any) =>
      ref.current && !ref.current.contains(event.target);

    const handleClickOutside = (event: any): void => {
      if (hasClickedOutside(event)) {
        document.body.classList.remove(disabledClicksClass);
        close();
      }
    };
    document.addEventListener('mouseup', handleClickOutside);
    return (): void => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref]);

  return { open, toggleValue };
};

export const DropdownBase = ({
  placeholder,
  label,
  items,
  hasError,
  customButton,
  customItem,
  onChange,
}: {
  placeholder: string;
  label?: string;
  items: DropdownItem[];
  hasError?: boolean;
  customButton: (props: any) => JSX.Element;
  customItem: (props: any) => JSX.Element;
  onChange: (item: DropdownItem) => void;
}): JSX.Element => {
  const [selection, setSelection] = useState<DropdownItem>();
  const wrapperRef = useRef(null);
  const dropdown = useOutsideClick(wrapperRef);

  const toggle = (): void => dropdown.toggleValue();

  const handleOnClick = (item: DropdownItem): void => {
    setSelection(item);
    onChange(item);
    toggle();
  };

  const listItems = items?.map(item =>
    customItem({ item, onClick: () => handleOnClick(item) }),
  );

  return (
    <div ref={wrapperRef} className={styles.ddWrapper} data-backdrop="static">
      {label && (
        <>
          <Paragraph color="black2" size="small">
            {label}
          </Paragraph>
          <HorizontalSpacer size="sm" />
        </>
      )}
      <div className={styles.ddContainer}>
        {customButton({
          placeholder,
          selectedItem: selection,
          hasError,
          toggle,
        })}
        {dropdown.open && (
          <div className={styles.ddListContainer}>
            <ul className={styles.ddList}>{listItems}</ul>
          </div>
        )}
      </div>
    </div>
  );
};

export const DropdownItemDefault = ({
  item,
  onClick,
}: {
  item: DropdownItem;
  onClick: (item: DropdownItem) => void;
}): JSX.Element => {
  return (
    <li className={styles.ddListItem} key={item.id}>
      <button
        type="button"
        onClick={(): void => onClick(item)}
        disabled={item.disabled}
      >
        <Paragraph color={item.disabled ? 'grey' : 'black2'}>
          {item.value}
        </Paragraph>
      </button>
    </li>
  );
};

const getTitleOrPlaceholder = (
  selectedItem: DropdownItem | undefined,
  placeholder: string,
) => {
  const buttonText = {
    placeholder: <Paragraph color="grey">{placeholder}</Paragraph>,
    title: <Paragraph>{selectedItem?.value}</Paragraph>,
  };
  return selectedItem ? buttonText['title'] : buttonText['placeholder'];
};

const getTitleOrPlaceholderForBasic = (
  selectedItem: DropdownItem | undefined,
  placeholder: string,
  size?: Sizes,
) => {
  const buttonText = {
    placeholder: (
      <Paragraph size={size ? size : 'body'} color="black2">
        {placeholder}
      </Paragraph>
    ),
    title: (
      <Paragraph size={size ? size : 'body'} color="black2">
        {selectedItem?.value}
      </Paragraph>
    ),
  };
  return selectedItem ? buttonText['title'] : buttonText['placeholder'];
};

export const DropdownButtonOutlined = ({
  placeholder,
  selectedItem,
  hasError,
  toggle,
  width,
}: {
  placeholder: string;
  selectedItem: DropdownItem | undefined;
  hasError?: boolean;
  toggle: () => void;
  width?: number;
}): JSX.Element => {
  return (
    <div
      tabIndex={0}
      className={`${styles.ddHeaderOutlined} ${
        hasError ? styles.ddHeaderOutlined__error : null
      }`}
      style={{ width: width }}
      role="button"
      onKeyPress={(): void => toggle()}
      onClick={(): void => toggle()}
    >
      <div className={styles.ddHeader__placeholder}>
        {getTitleOrPlaceholder(selectedItem, placeholder)}
      </div>
      <div className={styles.ddHeader__action}>
        <icons.chevronDown size={iconSizes.sm} />
      </div>
    </div>
  );
};

export const DropdownButtonBasic = ({
  placeholder,
  selectedItem,
  hasError,
  toggle,
  width,
}: {
  placeholder: string;
  selectedItem: DropdownItem;
  hasError?: boolean;
  width?: number;
  toggle: () => void;
}): JSX.Element => {
  return (
    <div
      tabIndex={0}
      className={`${styles.ddHeader} ${
        hasError ? styles.ddHeader__error : null
      }`}
      style={{ width: width }}
      role="button"
      onKeyPress={(): void => toggle()}
      onClick={(): void => toggle()}
    >
      <div className={styles.ddHeader__placeholder}>
        {getTitleOrPlaceholderForBasic(selectedItem, placeholder)}
      </div>
      <HorizontalSpacer size="md" />
      <div className={styles.ddHeader__action}>
        <icons.chevronDown size={iconSizes.xs2} />
      </div>
    </div>
  );
};

export const DropdownButtonBasicSmall = ({
  placeholder,
  selectedItem,
  hasError,
  toggle,
  width,
}: {
  placeholder: string;
  selectedItem: DropdownItem;
  hasError?: boolean;
  width?: number;
  toggle: () => void;
}): JSX.Element => {
  return (
    <div
      tabIndex={0}
      className={`${styles.ddHeader} ${
        hasError ? styles.ddHeader__error : null
      }`}
      style={{ width: width, paddingTop: 2 }}
      role="button"
      onKeyPress={(): void => toggle()}
      onClick={(): void => toggle()}
    >
      <div className={styles.ddHeader__placeholder}>
        {getTitleOrPlaceholderForBasic(selectedItem, placeholder, 'small')}
      </div>
      <HorizontalSpacer size="sm" />
      <div className={styles.ddHeader__action}>
        <icons.chevronDown size={iconSizes.xs2} />
      </div>
    </div>
  );
};
