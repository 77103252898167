import { TProjectAdminRating } from '../../Dashboard/domain';
import { TResearchCoordinator } from '../../Auth/domain';
import { TId } from '../../../shared/domain';

export { ProjectJson } from './project-json';
export interface TProject {
  id: TId;
  shortName: string;
  name: string;
  sector: string[];
  cluster: string;
  leadingInstitute: string;
  additionalInstitutes: string[];
  applicantEmail: string;
  applicantLastName: string;
  applicantFirstName: string;
  industries: string[];
  additionalIndustries: string[];
  description: string;
  url?: string;
  ipcSupplement: string[];
  ipcConcordance: string[];
  projectAdminRatings?: TProjectAdminRating[];
  tags: string[];
  researchCoordinators: TResearchCoordinator[];
}

export interface ProjectsPerCluster {
  id: string;
  projects: string[];
  order?: any;
}

export enum TrafficLightRating {
  GREY = 'GREY',
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
}

export enum TopicRatingValue {
  ZERO = 'ZERO',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE = 'FIVE',
  SIX = 'SIX',
  SEVEN = 'SEVEN',
  EIGHT = 'EIGHT',
  NINE = 'NINE',
  TEN = 'TEN',
}
