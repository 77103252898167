import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { useRequestOnMount, useSelector } from '../../../../../../shared/hooks';
import { projectRankingSelectors } from '../../../../redux/projectRankingSelectors';
import { Ranking } from '../../../../redux/projectsRankingReducer';
import { iconColors, iconSizes } from '../../../../../../shared/constants';
import { icons } from '../../../../../../shared/components/icons';
import { ProjectImagePlaceHolder } from '../../list/ProjectImagePlaceholder';
import { H4, Paragraph, Truncate } from '../../../../../../shared/components';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../../shared/components/spacers';
import styles from '../index.module.scss';
import { joinClassNames } from '../../../../../../shared/utils';
import { projectActions } from '../../../../redux/effects';
import { TProject } from '../../../../domain';
import { translate } from '../../evaluation/translate';

const rankedId = 'ranked';
const unrankedId = 'unranked';

export const EvaluationDnD: React.FC<{
  onChange: (list: Ranking[]) => void;
  project: TProject;
}> = ({ onChange, project }) => {
  useRequestOnMount(projectActions.myRanking);

  const ranked: TProject[] = useSelector(projectRankingSelectors.ranked);
  const unranked: TProject[] = useSelector(projectRankingSelectors.unranked);
  const [rankedList, setRankedList] = useState<TProject[]>([]);
  const [unrankedList, setUnrankedList] = useState<TProject[]>([]);

  useEffect(() => {
    setRankedList(ranked);
  }, [ranked]);

  useEffect(() => {
    setUnrankedList(unranked);
  }, [unranked]);

  const onUpdate = (items: TProject[]): void => {
    const data = items.map((e: TProject, index: number) => {
      return { projectId: e.id, position: index + 1 };
    });
    onChange(data);
  };

  const updateOrders = (
    source: any,
    destination: any,
  ): { newRankedList: TProject[]; newUnrankedList: TProject[] } => {
    let removedElement;
    const newRankedList = Array.from(rankedList);
    const newUnrankedList = Array.from(unrankedList);

    if (source.droppableId === rankedId) {
      const [removed] = newRankedList.splice(source.index, 1);
      removedElement = removed;
    } else {
      const [removed] = newUnrankedList.splice(source.index, 1);
      removedElement = removed;
    }

    if (destination.droppableId == rankedId) {
      newRankedList.splice(destination.index, 0, removedElement);
    } else {
      newUnrankedList.splice(destination.index, 0, removedElement);
    }

    return {
      newRankedList: newRankedList,
      newUnrankedList: newUnrankedList,
    };
  };

  const onDragEnd = (result: any): void => {
    const { source, destination } = result;

    if (!destination || destination.droppableId == unrankedId) {
      return;
    }

    const { newRankedList, newUnrankedList } = updateOrders(
      source,
      destination,
    );

    if (!_.isEqual(unrankedList, newUnrankedList)) {
      setUnrankedList(newUnrankedList);
    }

    if (!_.isEqual(rankedList, newRankedList)) {
      setRankedList(newRankedList);
      onUpdate(newRankedList);
    }
  };

  if (!rankedList) return <></>;
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.evaluationDnD__container}>
        <div className={styles.evaluationDnD__numberColumn}>
          {rankedList.map((proj, index) => (
            <Paragraph
              key={proj.id}
              weight="bold"
              size="big"
              color="darkGrey"
              className={styles.evaluationDnD__number}
            >
              {index + 1}.
            </Paragraph>
          ))}
          {unrankedList.length !== 0 && (
            <Paragraph
              key={rankedList.length + 1}
              weight="bold"
              size="big"
              color="darkGrey"
              className={styles.evaluationDnD__number}
            >
              {rankedList.length + 1}.
            </Paragraph>
          )}
        </div>
        <Droppable droppableId={rankedId}>
          {(provided): JSX.Element => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={styles.evaluationDnD__boxContainer}
            >
              {rankedList.map((proj, index) => {
                return (
                  <EvaluationDnDItem
                    key={proj.id}
                    project={project}
                    current={proj}
                    index={index}
                  />
                );
              })}
              {unrankedList.length !== 0 && <PlaceholderItem />}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      {unrankedList.length > 0 && (
        <>
          <H4>{translate('ranking.not_rated')}</H4>
          <VerticalSpacer size="md" />
          <div className={styles.evaluationDnD__container}>
            <Droppable droppableId={unrankedId}>
              {(provided): JSX.Element => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={styles.evaluationDnD__boxContainer}
                >
                  {unrankedList.map((proj, index) => {
                    return (
                      <EvaluationDnDItem
                        key={proj.id}
                        project={project}
                        current={proj}
                        index={index}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <VerticalSpacer size="xl" />
        </>
      )}
    </DragDropContext>
  );
};

const PlaceholderItem: React.FC = () => {
  return (
    <div
      className={joinClassNames(styles.evaluationDnD__placeholderItemContainer)}
    >
      <div
        className={styles.evaluationDnD__itemBox}
        style={{ justifyContent: 'center' }}
      >
        <Paragraph color="black2" style={{ textAlign: 'center' }}>
          {translate('ranking.placeholder')}
        </Paragraph>
      </div>
    </div>
  );
};

const EvaluationDnDItem: React.FC<{
  project: TProject;
  current: TProject;
  index: number;
}> = ({ project, current, index }) => {
  return (
    <Draggable draggableId={current.id} index={index}>
      {(provided): JSX.Element => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={joinClassNames(
            styles.evaluationDnD__itemContainer,
            current.id === project.id
              ? styles.evaluationDnD__itemContainerSelected
              : null,
          )}
          style={{
            ...provided.draggableProps.style,
          }}
        >
          <div className={styles.evaluationDnD__itemBox}>
            <div className={styles.evaluationDnD__itemIcon}>
              <icons.itemSelect color={iconColors.grey} size={iconSizes.md} />
            </div>
            <ProjectImagePlaceHolder shortName={current.shortName} />
            <HorizontalSpacer size="md" />
            <Truncate maxLines={1}>
              <Paragraph color="black2">{current.name}</Paragraph>
            </Truncate>
          </div>
        </div>
      )}
    </Draggable>
  );
};
