import { chartColors } from '../../../../domain/constants';

export const ChartConfiguration = {
  legend: {
    display: false,
  },
  pieceLabel: {
    render: 'percentage',
    fontColor: [chartColors.black, chartColors.white],
    fontSize: 16,
    overlap: false,
  },
};
