import React from 'react';
import { joinClassNames } from '../../shared/utils';

import styles from './images.module.scss';
import { FlexBox } from '.';

export const Image = ({
  src,
  srcSet,
  style,
  className,
  alt,
}: {
  src: string | undefined;
  srcSet?: any;
  className?: string;
  style?: React.CSSProperties | undefined;
  alt?: string;
}): JSX.Element => {
  if (!src && !srcSet) {
    return (
      <div
        style={style}
        className={joinClassNames(className, styles.imageFallback)}
      />
    );
  }
  return (
    <img
      srcSet={srcSet}
      alt={alt}
      src={src}
      style={style}
      className={className}
    />
  );
};

export const ImageContainer = ({
  children,
  backgroundImage,
  srcSet,
}: {
  children: React.ReactNode;
  backgroundImage?: any;
  srcSet: any;
}): JSX.Element => (
  <FlexBox style={{ position: 'relative', width: '100%', height: '100%' }}>
    <Image
      style={{
        position: 'absolute',
        objectFit: 'cover',
        padding: 32,
        width: '100%',
        height: '100%',
      }}
      src={backgroundImage}
      srcSet={srcSet}
    />
    <FlexBox paddingLeft="xxl" flexDirection="column" style={{ zIndex: 10 }}>
      {children}
    </FlexBox>
  </FlexBox>
);
