import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  FlexBox,
  H1,
  Image,
  ImportButton,
  Paragraph,
} from '../../../../../shared/components';
import styles from './index.module.scss';
import EmptyListImage from '../../../../../shared/assets/images/EmptyList.png';
import { translate } from './translate';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { roleSelectors } from '../../../../Auth/redux/roles';
import { roles } from '../../../../Auth/domain';
import { showToasterAction } from '../../../../../shared/redux/actions';
import { toasterTypes } from '../../../../../shared/constants';
import { ProjectRoutes } from '../../routes';
import { setProjectsUploadFileAction } from '../../../redux/effects';

const jsonFileType = 'application/json';

export const ProjectImport = ({
  title,
  subtitle,
  onFileSelected,
}: {
  title: string;
  subtitle: string;
  onFileSelected?: () => void;
}): JSX.Element => {
  const role = useSelector(roleSelectors.getRole);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleFile = (file: File): void => {
    if (file.type !== jsonFileType) {
      dispatch(
        showToasterAction({
          description: translate('Projects')('import.failure'),
          type: toasterTypes.failure,
        }),
      );
    } else {
      dispatch(setProjectsUploadFileAction(file));
      history.push(ProjectRoutes.projects.import);
      if (onFileSelected) onFileSelected();
    }
  };

  return (
    <FlexBox
      flexDirection="column"
      alignItems="center"
      paddingVertical="5xl"
      paddingHorizontal="10xl"
    >
      <FlexBox.Row flex={1} paddingVertical="sm" alignItems="center">
        <Image
          className={styles.projectList__emptyImage}
          src={EmptyListImage}
        />
      </FlexBox.Row>
      <FlexBox.Row flex={1} paddingVertical="lg" alignItems="center">
        <H1>{title}</H1>
      </FlexBox.Row>
      <FlexBox.Row flex={1} alignItems="center">
        <Paragraph color="black2" className={styles.projectList__subtitle}>
          {subtitle}
        </Paragraph>
      </FlexBox.Row>
      {role === roles.admin && (
        <>
          <VerticalSpacer size="xxxl" />
          <FlexBox.Row flex={1} alignItems="center">
            <ImportButton
              text={translate('Projects')('import.button')}
              handleFile={handleFile}
            />
          </FlexBox.Row>
        </>
      )}
    </FlexBox>
  );
};
