import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch, usePushRoute } from '../../../hooks';
import { FlexBox, icons, LettersCircle } from '../../index';
import { Item } from './Item';
import { formatUserFullName, getInitials } from '../../../utils';
import { translate } from './translate';
import { logoutActionTypes } from '../../../../app/Auth/redux/actions';
import { TUser } from '../../../../app/Auth/domain';
import { sessionSelectors } from '../../../../app/Auth/redux/selectors';
import { routePaths } from '../../../routes/config';

export const SidebarBottom: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = usePushRoute();
  const currentUser: TUser | null = useSelector(sessionSelectors.currentUser);
  if (!currentUser) return null;

  return (
    <FlexBox.Column
      fullHeight
      flexDirection="column"
      alignItems="flex-end"
      justifyContent="flex-end"
    >
      <Item
        IconComponent={(): JSX.Element => (
          <LettersCircle>
            {getInitials(formatUserFullName(currentUser))}
          </LettersCircle>
        )}
        onClick={() => push(routePaths.user.profile)}
        text={formatUserFullName(currentUser)}
      />
      <Item
        IconComponent={icons.logout}
        onClick={() =>
          dispatch({
            type: logoutActionTypes.request,
          })
        }
        text={translate('routes.logout.label')}
      />
    </FlexBox.Column>
  );
};
