import { ViewMode } from '../../../shared/constants/viewMode';

import {
  changeProjectViewModeActionTypes,
  deleteEvaluationActionTypes,
  editEvaluationActionTypes,
  editTopicRatingActionTypes,
  editTrafficLightRatingActionTypes,
  evaluateProjectActionTypes,
  filterBySegmentProjectListActionTypes,
  filterByTextProjectListActionTypes,
  getProjectEvaluationsActionTypes,
  getTopicRatingActionTypes,
  getTrafficLightRatingActionTypes,
  listProjectActionTypes,
  myEvaluationsByProjectActionTypes,
  myProjectActionTypes,
  myProjectRankingActionTypes,
  myQualifiedProjectsActionTypes,
  projectDeleteActionTypes,
  projectDetailActionTypes,
  rankProjectActionTypes,
  setEvaluationCommentStateActionTypes,
  setEvaluationModalVisibilityActionTypes,
  setProjectsUploadFileActionTypes,
  topicRateProjectActionTypes,
  trafficLightRateProjectActionTypes,
  updateResearchCoordinatorProjectActionTypes,
  uploadProjectActionTypes,
} from './actions';
import { listProjectsApi } from '../data/listProjectsApi';
import { myProjectsApi } from '../data/myProjectsApi';
import { projectDetailApi } from '../data/projectDetailApi';
import { myProjectRankingApi } from '../data/myProjectRankingApi';
import { Ranking } from './projectsRankingReducer';
import { rankProjectApi } from '../data/rankProjectApi';
import { trafficLightRatingProjectApi } from '../data/trafficLightRatingProjectApi';
import { editTrafficLightRatingProjectApi } from '../data/editTrafficLightRatingProjectApi';
import { myTrafficLightRatingProjectApi } from '../data/myTrafficLightRatingProjectApi';
import { deleteEvaluationProjectApi } from '../data/deleteEvaluationProjectApi';
import {
  evaluateProjectApi,
  EvaluationValues,
} from '../data/evaluateProjectApi';
import { editEvaluationProjectApi } from '../data/editEvaluationProjectApi';
import { myEvaluationsByProjectApi } from '../data/myEvaluationsByProjectApi';
import { myQualifiedProjectsApi } from '../data/myQualifiedProjectsApi';
import {
  adminProjectsAction,
  adminRateProjectAction,
  editAdminRateProjectAction,
  getDashboardDataAction,
} from '../../Dashboard/redux/effects';
import { createEffect } from '../../../shared/redux/actionTypes/createEffect';
import { TId } from '../../../shared/domain';
import { ProjectJson, TopicRatingValue, TrafficLightRating } from '../domain';
import { uploadProjectsApi } from '../data/uploadProjectsApi';
import { getProjectEvaluationsApi } from '../data/getProjectEvaluationsApi';
import { deleteProjectApi } from '../data/deleteProjectApi';
import { myTopicRatingProjectApi } from '../data/myTopicRatingProjectApi';
import { topicRatingProjectApi } from '../data/topicRatingProjectApi';
import { editTopicRatingProjectApi } from '../data/editTopicRatingProjectApi';
import { editResearchCoordinatorsProjectApi } from '../data/editResearchCoordinatorsProjectApi';

export const changeProjectViewModeAction = (payload: ViewMode) => ({
  type: changeProjectViewModeActionTypes.request,
  payload,
});
export const setEvaluationCommentStateAction = (payload: boolean) => ({
  type: setEvaluationCommentStateActionTypes.request,
  payload,
});
export const setEvaluationModalVisibilityAction = (payload: boolean) => ({
  type: setEvaluationModalVisibilityActionTypes.request,
  payload,
});
export const setProjectsUploadFileAction = (payload: File) => ({
  type: setProjectsUploadFileActionTypes.request,
  payload,
});
export const filterProjectListByTextAction = (payload: string) => ({
  type: filterByTextProjectListActionTypes.request,
  payload,
});
export const filterProjectListBySegmentAction = (payload: string) => ({
  type: filterBySegmentProjectListActionTypes.request,
  payload,
});
export const listProjectsAction = createEffect(
  listProjectActionTypes,
  listProjectsApi,
);
export const uploadProjectsAction = createEffect(
  uploadProjectActionTypes,
  uploadProjectsApi,
  ({ projects }: { projects: ProjectJson[] }) => ({ projects }),
);
export const myProjectsAction = createEffect(
  myProjectActionTypes,
  myProjectsApi,
);
export const projectDetailAction = createEffect(
  projectDetailActionTypes,
  projectDetailApi,
  (id: TId) => ({
    id: id,
  }),
);
export const projectDeleteAction = createEffect(
  projectDeleteActionTypes,
  deleteProjectApi,
  ({ id }: { id: TId }) => ({
    id: id,
  }),
);
export const myProjectRankingAction = createEffect(
  myProjectRankingActionTypes,
  myProjectRankingApi,
);
export const rankProjectAction = createEffect(
  rankProjectActionTypes,
  rankProjectApi,
  ({ rankings }: { rankings: Ranking[] }) => ({
    rankings,
  }),
);
export const editTrafficLightRatingAction = createEffect(
  editTrafficLightRatingActionTypes,
  editTrafficLightRatingProjectApi,
  ({
    id,
    trafficLightRatingId,
    rating,
  }: {
    id: TId;
    trafficLightRatingId: TId;
    rating: TrafficLightRating;
  }) => ({
    id,
    trafficLightRatingId,
    rating,
  }),
);
export const getProjectTrafficLightRatingAction = createEffect(
  getTrafficLightRatingActionTypes,
  myTrafficLightRatingProjectApi,
  ({ id }: { id: TId }) => ({
    id,
  }),
);
export const trafficLightRateProjectAction = createEffect(
  trafficLightRateProjectActionTypes,
  trafficLightRatingProjectApi,
  ({ id, rating }: { id: TId; rating: TrafficLightRating }) => ({ id, rating }),
);
export const editTopicRatingAction = createEffect(
  editTopicRatingActionTypes,
  editTopicRatingProjectApi,
  ({
    id,
    topicRatingId,
    technologyRating,
    marketRating,
    consortiumRating,
  }: {
    id: TId;
    topicRatingId: TId;
    technologyRating: TopicRatingValue;
    marketRating: TopicRatingValue;
    consortiumRating: TopicRatingValue;
  }) => ({
    id,
    topicRatingId,
    technologyRating,
    marketRating,
    consortiumRating,
  }),
);
export const getProjectTopicRatingAction = createEffect(
  getTopicRatingActionTypes,
  myTopicRatingProjectApi,
  ({ id }: { id: TId }) => ({
    id,
  }),
);
export const topicRateProjectAction = createEffect(
  topicRateProjectActionTypes,
  topicRatingProjectApi,
  ({
    id,
    technologyRating,
    marketRating,
    consortiumRating,
  }: {
    id: TId;
    technologyRating: TopicRatingValue;
    marketRating: TopicRatingValue;
    consortiumRating: TopicRatingValue;
  }) => ({ id, technologyRating, marketRating, consortiumRating }),
);
export const deleteEvaluationProjectAction = createEffect(
  deleteEvaluationActionTypes,
  deleteEvaluationProjectApi,
  ({ id, evaluationId }: { id: TId; evaluationId: TId }) => ({
    id,
    evaluationId,
  }),
);
export const editEvaluationProjectAction = createEffect(
  editEvaluationActionTypes,
  editEvaluationProjectApi,
  ({
    id,
    evaluationId,
    comment,
    evaluation,
  }: {
    id: TId;
    evaluationId: TId;
    comment: string;
    evaluation?: EvaluationValues;
  }) => ({ id, evaluationId, comment, evaluation }),
);
export const evaluateProjectAction = createEffect(
  evaluateProjectActionTypes,
  evaluateProjectApi,
  ({
    id,
    comment,
    evaluation,
  }: {
    id: TId;
    comment: string;
    evaluation?: EvaluationValues;
  }) => ({ id, comment, evaluation }),
);
export const getProjectEvaluationsAction = createEffect(
  getProjectEvaluationsActionTypes,
  getProjectEvaluationsApi,
  ({ id }: { id: TId }) => ({ id }),
);
export const myEvaluationsByProjectAction = createEffect(
  myEvaluationsByProjectActionTypes,
  myEvaluationsByProjectApi,
  ({ id }: { id: TId }) => ({ id }),
);
export const myQualifiedProjectsAction = createEffect(
  myQualifiedProjectsActionTypes,
  myQualifiedProjectsApi,
);

export const updateResearchCoordinatorProjectAction = createEffect(
  updateResearchCoordinatorProjectActionTypes,
  editResearchCoordinatorsProjectApi,
  ({
    projectId,
    researchCoordinatorEmail,
    toRemoveResearchCoordinatorId,
  }: {
    projectId: TId;
    researchCoordinatorEmail: string;
    toRemoveResearchCoordinatorId: TId;
  }) => ({
    projectId,
    researchCoordinatorEmail,
    toRemoveResearchCoordinatorId,
  }),
);

export const projectActions = {
  listAll: listProjectsAction,
  upload: uploadProjectsAction,
  detail: projectDetailAction,
  delete: projectDeleteAction,
  mine: myProjectsAction,
  dashboardData: getDashboardDataAction,
  adminProjects: adminProjectsAction,
  evaluate: evaluateProjectAction,
  getProjectEvaluations: getProjectEvaluationsAction,
  rank: rankProjectAction,
  myRanking: myProjectRankingAction,
  trafficLightRate: trafficLightRateProjectAction,
  editTrafficLightRatingAction: editTrafficLightRatingAction,
  getProjectTrafficLightRatingAction: getProjectTrafficLightRatingAction,
  topicRate: topicRateProjectAction,
  editTopicRatingAction: editTopicRatingAction,
  getProjectTopicRatingAction: getProjectTopicRatingAction,
  qualifiedProjects: myQualifiedProjectsAction,
  adminRateProject: adminRateProjectAction,
  editAdminRateProject: editAdminRateProjectAction,
  myEvaluationsByProject: myEvaluationsByProjectAction,
  editEvaluationProject: editEvaluationProjectAction,
  deleteEvaluationProject: deleteEvaluationProjectAction,
  updateResearchCoordinatorProject: updateResearchCoordinatorProjectAction,
};
