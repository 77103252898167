import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { H4 } from '../../../../../../shared/components';
import { RadioButtonItem } from '../../../../../../shared/components/radio';
import { VerticalSpacer } from '../../../../../../shared/components/spacers';
import { useRequestOnMountWithMultipleParams } from '../../../../../../shared/hooks';
import { useCreateTrafficLightRating } from '../../../../application/use_create_traffic_light_rating';
import { useUpdateTrafficLightRating } from '../../../../application/use_update_traffic_light_rating';
import { TProject } from '../../../../domain';
import { projectActions } from '../../../../redux/effects';
import { trafficLightRatingSelectors } from '../../../../redux/trafficLightRatingSelectors';
import { translate } from '../translate';
import { EvaluationRadioButtons, radioItems } from './evaluationRadioButtons';

export const TrafficLightEvaluation: React.FC<{ project: TProject }> = ({
  project,
}) => {
  const { createTrafficLightRating } = useCreateTrafficLightRating();
  const { updateTrafficLightRating } = useUpdateTrafficLightRating();

  const oldRating = useSelector(
    trafficLightRatingSelectors.by_project(project.id),
  );
  const [rating, setRating] = useState<RadioButtonItem>(radioItems[0]);

  useEffect(() => {
    if (oldRating)
      setRating(radioItems.find(it => it.id === oldRating.rating)!!);
  }, [oldRating]);

  useRequestOnMountWithMultipleParams(
    projectActions.getProjectTrafficLightRatingAction({
      id: project.id,
      onFailure: () => {
        createTrafficLightRating({
          projectId: project.id,
          rating: radioItems[0].id,
        });
      },
    }),
  );

  const isFirstTime = (): boolean => !oldRating;

  const onUpdateRating = (e: RadioButtonItem): void => {
    setRating(e);
    if (isFirstTime()) {
      createTrafficLightRating({ rating: e.id, projectId: project.id });
      return;
    }
    updateTrafficLightRating({
      rating: e.id,
      trafficLightRatingId: oldRating.id,
      projectId: project.id,
    });
  };

  return (
    <>
      <H4>{translate('your_feedback')}</H4>
      <VerticalSpacer size="xl" />
      <EvaluationRadioButtons selectedItem={rating} onChange={onUpdateRating} />
    </>
  );
};
