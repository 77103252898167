/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';

import { isRouteFound } from './isRouteFound';
import { replaceRouteIfNeeded } from './replaceRouteIfNeeded';
import { findRouteByLocationPath } from './findRouteByLocationPath';
import { NotFound } from '../../components/NotFound';
import { useLocationPath, useReplaceRoute, useSelector } from '../../hooks';
import { If, WaitToEnter } from '../../components';
import { appRoutesConfig } from '../config';
import { sessionSelectors } from '../../../app/Auth/redux/selectors';
import { roleSelectors } from '../../../app/Auth/redux/roles';

const EmptyReturn = (): JSX.Element => <></>;

const useSetNotFoundIfNeeded = ({
  setNotFound,
  currentLocation,
  currentRole,
}: any): void => {
  React.useEffect(() => {
    setNotFound(
      isRouteFound({
        currentRole,
        currentLocation,
      }),
    );
  }, [currentLocation, currentRole, setNotFound]);
};

const useReplaceRouteIfNeeded = ({
  currentLocation,
  currentRole,
}: any): void => {
  const [previousRole, setPreviousRole] = React.useState() as any;
  const { replaceRoute } = useReplaceRoute();

  const isAuthenticated = useSelector(sessionSelectors.authenticationToken);
  React.useEffect(() => {
    if (currentRole && currentRole !== previousRole) {
      setPreviousRole(currentRole);
    }
  }, [currentRole, previousRole, setPreviousRole]);

  React.useEffect(() => {
    replaceRouteIfNeeded({
      currentLocation,
      isAuthenticated,
      replaceRoute,
      currentRole,
      previousRole,
    });
  }, [
    currentLocation,
    isAuthenticated,
    replaceRoute,
    currentRole,
    previousRole,
  ]);
};

const AppRoute = ({ path, Component, ...props }: any): JSX.Element => {
  const locationPath = useLocationPath();

  const currentRole = useSelector(roleSelectors.getRole);

  const currentLocation = findRouteByLocationPath(locationPath);

  useReplaceRouteIfNeeded({
    currentLocation,
    currentRole,
  });

  useSetNotFoundIfNeeded({
    setNotFound: props.setNotFound,
    currentRole,
    currentLocation,
  });

  return (
    <Route
      exact
      path={path}
      render={(props: any): JSX.Element | JSX.Element[] => {
        return !props.notFound ? <Component {...props} /> : <EmptyReturn />;
      }}
    />
  );
};

const ScrollToTopOnNavigate = withRouter(
  ({ children, location: { pathname } }: any) => {
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return children || null;
  },
);

export const RouteConfig: React.FC = () => {
  const [notFound, setNotFound] = React.useState(false);

  return (
    <WaitToEnter timeToEnter={300}>
      <Router>
        <ScrollToTopOnNavigate />
        {appRoutesConfig.map((route, i) => (
          <AppRoute
            notFound={notFound}
            setNotFound={setNotFound}
            key={i}
            path={route.path}
            Component={route.Component}
          />
        ))}
        <If condition={notFound}>{(): JSX.Element => <NotFound />}</If>
      </Router>
    </WaitToEnter>
  );
};
