import React from 'react';

import {
  Paragraph,
  Box,
  PasswordInput,
  EmailInput,
  If,
  InputWithLabel,
} from '../../../../shared/components';

import { getTranslateByScope } from '../../../../shared/i18n';

export const translate = getTranslateByScope('ui.layouts.Login');

interface FormInputsInterface {
  hasSubmittedWithErrors: boolean;
  setEmail: Function;
  email: string;
  setPassword: Function;
  password: string;
}
const FormInputs = (props: FormInputsInterface): JSX.Element => (
  <Box>
    <Box marginTop="xl">
      <InputWithLabel
        label={translate('emailInput.label')}
        InputComponent={
          <EmailInput
            hasError={props.hasSubmittedWithErrors}
            onChangeText={props.setEmail}
            value={props.email}
            placeholder={translate('emailInput.placeholder')}
          />
        }
      />
      <Box marginTop="sm">
        <If condition={props.hasSubmittedWithErrors}>
          {(): JSX.Element => (
            <Paragraph size="tiny" color="red">
              {translate('emailInput.emailValidationText')}
            </Paragraph>
          )}
        </If>
      </Box>
    </Box>
    <Box marginTop="lg">
      <InputWithLabel
        label={translate('passwordInput.label')}
        InputComponent={
          <PasswordInput
            onChangeText={props.setPassword}
            value={props.password}
            placeholder={translate('passwordInput.placeholder')}
          />
        }
      />
    </Box>
  </Box>
);

export default FormInputs;
