import React from 'react';
import styles from './circles.module.scss';
import { Paragraph } from './typographies';
import { FlexBox } from './boxes';
import { Image } from './images';

const BaseLettersCircle: React.FC<{ className: string }> = ({
  children,
  className,
}) => {
  return (
    <FlexBox className={className} justifyContent="center" alignItems="center">
      <Paragraph color="white" size="tiny">
        {children}
      </Paragraph>
    </FlexBox>
  );
};

export const LettersCircle: React.FC = ({ children }) => {
  return (
    <BaseLettersCircle className={styles.circle}>{children}</BaseLettersCircle>
  );
};

export const TinyLettersCircle: React.FC = ({ children }) => {
  return (
    <BaseLettersCircle className={styles.tinyCircle}>
      {children}
    </BaseLettersCircle>
  );
};

export const ImageCircle = ({
  src,
}: {
  src: string | undefined;
}): JSX.Element => {
  return (
    <FlexBox
      className={styles.circle}
      justifyContent="center"
      alignItems="center"
    >
      <Image className={styles.circleImage} src={src} />
    </FlexBox>
  );
};
