import { createEffect } from '../../../shared/redux/actionTypes/createEffect';

import { resetUserPasswordApi } from '../data/resetPasswordApi';
import { resetUserPasswordActionTypes } from './actions';

export const resetUserPasswordAction = createEffect(
  resetUserPasswordActionTypes,
  resetUserPasswordApi,
  ({ password }: { password: string }) => ({
    account: {
      password,
    },
  }),
  true,
);
