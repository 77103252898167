import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  ButtonIcon,
  FlexBox,
  H4,
  icons,
  Link,
  Paragraph,
  ProjectTagsContainer,
  Truncate,
} from '../../../../../shared/components';
import styles from './index.module.scss';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { projectSelectors } from '../../../redux/projectSelectors';
import { ProjectImagePlaceHolder } from './ProjectImagePlaceholder';
import { iconColors } from '../../../../../shared/constants';
import {
  HorizontalSpacer,
  VerticalSpacer,
} from '../../../../../shared/components/spacers';
import { TId } from '../../../../../shared/domain';
import { roleSelectors } from '../../../../Auth/redux/roles';
import { roles } from '../../../../Auth/domain';
import { useDeleteProject } from '../../../application/use_delete_project';
import { useModal } from '../../../../../shared/hooks';
import { DeleteModal } from './DeleteModal';

export const translate = getTranslateByScope('ui.layouts.Projects');

interface Interface {
  projectId: TId;
  routeTo: any;
}

const LeadingInstitute: React.FC<{ leadingInstitute: string }> = ({
  leadingInstitute,
}) => (
  <FlexBox.Row alignItems="center">
    <Box marginRight="sm">
      <icons.industry color={iconColors.grey} />
    </Box>
    <Paragraph color="black2" size="tiny">
      {leadingInstitute}
    </Paragraph>
  </FlexBox.Row>
);

export const ProjectRow: React.FC<Interface> = ({ projectId, routeTo }) => {
  const project = useSelector(projectSelectors.getForId(projectId));
  const role = useSelector(roleSelectors.getRole);
  const deleteModal = useModal(false);
  const { isLoading, deleteProject } = useDeleteProject();
  const handleDelete = (): void => deleteProject(projectId);

  if (!project) return null;

  return (
    <div className={styles.projectList__row}>
      <DeleteModal modal={deleteModal} onConfirm={handleDelete} />
      <Link style={{ display: 'flex', flex: 1 }} to={routeTo}>
        <div className={styles.projectList__textSection}>
          <div className={styles.projectList__textContainer}>
            <ProjectImagePlaceHolder shortName={project.shortName} />
            <HorizontalSpacer size="md" />
            <Truncate maxLines={1}>
              <H4 color={'black2'}>{project.name}</H4>
            </Truncate>
          </div>
          <VerticalSpacer size="sm" />
          <Truncate maxLines={2}>
            <Paragraph
              color="black2"
              size="tiny"
              className={styles.projectList__description}
            >
              {project.description}
            </Paragraph>
          </Truncate>
        </div>
        <div className={styles.projectList__secondBlock}>
          <div className={styles.projectList__tagsSection}>
            <ProjectTagsContainer projectTags={project.tags} truncate={true} />
          </div>
          <div className={styles.projectList__leadingSection}>
            <LeadingInstitute leadingInstitute={project.leadingInstitute} />
          </div>
        </div>
      </Link>
      {role === roles.admin && (
        <div className={styles.projectList__deleteButtonRow}>
          <ButtonIcon
            active
            loading={isLoading}
            activeColor={iconColors.grey}
            hoverColor={iconColors.primary}
            icon={icons.delete}
            onClick={(): void => deleteModal.open()}
          />
        </div>
      )}
    </div>
  );
};
