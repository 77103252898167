import React from 'react';
import { DashboardProject } from './sampleData';
import {
  FlexBox,
  Link,
  Paragraph,
  Truncate,
} from '../../../../../shared/components';
import styles from './chart.module.scss';
import { columnSize } from './chartConstants';
import { joinClassNames } from '../../../../../shared/utils';
import { routePaths } from '../../../../../shared/routes/config';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { getTranslateByScope } from '../../../../../shared/i18n';
import { AdminRating } from '../../../domain';

export const ChartHeader: React.FC<{
  project: DashboardProject;
  isFirstElement: boolean;
  isLastElement: boolean;
}> = ({ project, isFirstElement, isLastElement }) => {
  const translate = getTranslateByScope('ui.layouts.Dashboard.chart');

  const isEmptyProject = (): boolean => project.id === '';

  const text = (): JSX.Element => {
    if (isEmptyProject()) {
      return <div color="black2" className={styles.header__textPlaceholder} />;
    }
    return (
      <>
        <div
          className={joinClassNames(
            styles.header__textTooltip,
            isFirstElement ? styles.header__textTooltipFirst : null,
            isLastElement ? styles.header__textTooltipLast : null,
          )}
        >
          <span />
          <FlexBox flexDirection="column" justifyContent="space-between">
            <Paragraph size="small" color="link" weight="bold">
              {project.shortName}
            </Paragraph>
            <Paragraph size="tiny" color="darkGrey">
              {project.name}
            </Paragraph>
            <VerticalSpacer size="xs" />
            <Link
              to={{
                pathname: routePaths.projects.detail(project.id),
                state: { projectListRoute: routePaths.dashboard },
              }}
            >
              <Paragraph
                size="tiny"
                color="link"
                className={styles.header__linkTooltip}
              >
                {translate('goToProject')}
              </Paragraph>
            </Link>
          </FlexBox>
        </div>
        <div className={styles.header__textBox}>
          <Truncate maxLines={1}>
            <Paragraph
              color="black2"
              weight="bold"
              className={styles.header__text}
            >
              {project.shortName}
            </Paragraph>
          </Truncate>
        </div>
      </>
    );
  };

  const getColorForRating = () => {
    switch (project.adminRating[0]) {
      case AdminRating.ACCEPT:
        return styles.coloredChart__greenWithOpacity;
      case AdminRating.REJECT:
        return styles.coloredChart__redWithOpacity;
      case AdminRating.ON_HOLD:
        return styles.coloredChart__yellowWithOpacity;
    }
  };

  return (
    <div
      className={joinClassNames(
        styles.header__container,
        isEmptyProject() ? styles.header__containerEmpty : null,
      )}
    >
      <div
        className={joinClassNames(
          styles.header__textContainer,
          getColorForRating(),
        )}
        style={{ width: columnSize }}
      >
        {text()}
      </div>
    </div>
  );
};
