import React from 'react';
import styles from './chart.module.scss';
import { iconColors, iconSizes } from '../../../../../shared/constants';
import { icons } from '../../../../../shared/components/icons';
import { joinClassNames } from '../../../../../shared/utils';

export enum ChartPaginatorPosition {
  RIGHT = 'right',
  LEFT = 'left',
}

export const ChartPaginator: React.FC<{
  onClick: () => void;
  visible?: boolean;
  position: string;
  size?: iconSizes;
}> = ({ onClick, position, size = iconSizes.md, visible = true }) => {
  return visible ? (
    <div
      className={joinClassNames(styles.paginator, styles[`paginator__${size}`])}
      style={{
        left: position === ChartPaginatorPosition.LEFT ? 8 : undefined,
        right: position === ChartPaginatorPosition.RIGHT ? 8 : undefined,
      }}
      onClick={onClick}
    >
      {position === ChartPaginatorPosition.LEFT ? (
        <icons.chevronLeft size={size} color={iconColors.white} />
      ) : (
        <icons.chevronRight size={size} color={iconColors.white} />
      )}
    </div>
  ) : (
    <></>
  );
};
