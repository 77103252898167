import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Colors,
  EaseInBox,
  FlexBox,
  If,
  Paragraph,
  PasswordInput,
  PrimaryButton,
} from '../../../../shared/components';
import { VerticalSpacer } from '../../../../shared/components/spacers';
import { toasterTypes } from '../../../../shared/constants';
import { useDispatch } from '../../../../shared/hooks';
import { getTranslateByScope } from '../../../../shared/i18n';
import { showToasterAction } from '../../../../shared/redux/actions';
import { validatePassword } from '../../../Auth/presentation/components/validatePassword';
import { sessionSelectors } from '../../../Auth/redux/selectors';
import { resetUserPasswordAction } from '../../redux/effects';
import { InputSize } from '../../domain/constants';
import { TToasterTypes } from '../../../../shared/domain';

export const translate = getTranslateByScope('ui.layouts.PasswordReset');

export const PasswordResetForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [hasSubmittedWithErrors, setHasSubmittedWithErrors] = useState<boolean>(
    false,
  );

  const token: string | null = useSelector(
    sessionSelectors.authenticationToken,
  );

  const actionDispatch = (
    actionType: TToasterTypes,
    description: string,
  ): void => {
    dispatch(
      showToasterAction({
        description: translate(description),
        type: actionType,
      }),
    );
  };

  const showSuccessToast = (): void => {
    actionDispatch(toasterTypes.success, 'successfullyResetToast.description');
  };

  const showErrorToast = (): void => {
    actionDispatch(toasterTypes.failure, 'errorToast.description');
  };

  const handleClick = (): void => {
    if (password !== repeatPassword) {
      actionDispatch(toasterTypes.failure, 'differentPassword.description');
      return;
    }
    const isValidPassword = validatePassword(password);
    setHasSubmittedWithErrors(!isValidPassword);

    if (!token) {
      actionDispatch(toasterTypes.failure, 'tokenMissingToast.description');

      return;
    }

    if (token && isValidPassword) {
      actionDispatch(
        toasterTypes.success,
        'successfullyResetToast.description',
      );

      dispatch(
        resetUserPasswordAction({
          password,
          onSuccess: () => {
            setPassword('');
            setRepeatPassword('');
            showSuccessToast();
          },
          onFailure: () => {
            showErrorToast();
          },
        }),
      );
    }
  };

  const passwordValidationInput = (textColor: Colors): JSX.Element => {
    return (
      <Paragraph color={textColor} size="tiny">
        {translate('passwordInput.passwordValidationText')}
      </Paragraph>
    );
  };

  return (
    <EaseInBox>
      <FlexBox
        style={{ minWidth: InputSize.minWidth, maxWidth: InputSize.width }}
        flexDirection="column"
      >
        <Box marginTop="lg">
          <Paragraph size="small" style={{ textDecorationLine: 'underline' }}>
            {translate('resetPasswordInput.label')}
          </Paragraph>
          <VerticalSpacer size="xs" />
          <PasswordInput
            hasError={hasSubmittedWithErrors}
            onChangeText={setPassword}
            value={password}
            placeholder={translate('resetPasswordInput.placeholder')}
          />
          <VerticalSpacer size="lg2" />

          <Paragraph size="small" style={{ textDecorationLine: 'underline' }}>
            {translate('repeatPasswordInput.label')}
          </Paragraph>
          <VerticalSpacer size="xs" />

          <PasswordInput
            hasError={hasSubmittedWithErrors}
            onChangeText={setRepeatPassword}
            value={repeatPassword}
            placeholder={translate('repeatPasswordInput.placeholder')}
          />

          <Box marginTop="sm">
            {!hasSubmittedWithErrors && passwordValidationInput('grey')}
            {hasSubmittedWithErrors && passwordValidationInput('red')}
          </Box>
        </Box>
        <Box marginTop="lg">
          <PrimaryButton
            disabled={!password || !repeatPassword}
            onClick={handleClick}
          >
            {translate('buttonUserResetPassword.caption')}
          </PrimaryButton>
        </Box>
      </FlexBox>
    </EaseInBox>
  );
};
