import {
  AuthRoutes,
  AuthRoutesConfig,
} from '../../app/Auth/presentation/routes';
import {
  ProjectRoutes,
  ProjectRoutesConfig,
} from '../../app/Project/presentation/routes';
import {
  DashboardRoutes,
  DashboardRoutesConfig,
} from '../../app/Dashboard/presentation/routes';
import {
  ReportsRoutes,
  ReportsRoutesConfig,
} from '../../app/Reports/presentation/routes';
import {
  UserRoutes,
  UserRoutesConfig,
} from '../../app/User/presentation/routes';

export const routePaths = {
  ...AuthRoutes,
  ...ProjectRoutes,
  ...DashboardRoutes,
  ...ReportsRoutes,
  ...UserRoutes,
};

export const appRoutesConfig = [
  ...UserRoutesConfig,
  ...AuthRoutesConfig,
  ...ProjectRoutesConfig,
  ...DashboardRoutesConfig,
  ...ReportsRoutesConfig,
];
