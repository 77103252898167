import { AdminSegments } from '../../../shared/constants/segments';
import { reduceListToEntities } from '../../../shared/redux/reducers/reducerHelpers';
import {
  changeFilterAdminProjectListActionTypes,
  listAdminProjectActionTypes,
} from './actions';
import { TProject } from '../../Project/domain';

export interface State {
  entities: { [key: string]: TProject };
  filters: {
    segment: string;
  };
}

interface ActionInterface {
  type: string;
  payload?: any;
}

export const initialState = {
  entities: {},
  filters: {
    segment: AdminSegments.allProjects,
  },
};

export const adminProjectsReducer = (
  state: State = initialState,
  action: ActionInterface,
): State => {
  switch (action.type) {
    case listAdminProjectActionTypes.success: {
      return {
        ...state,
        entities: reduceListToEntities(action.payload.projects),
      };
    }
    case changeFilterAdminProjectListActionTypes.request: {
      return {
        ...state,
        filters: {
          ...state.filters,
          segment: action.payload,
        },
      };
    }
  }
  return state;
};

export default adminProjectsReducer;
