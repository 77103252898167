import { RouteVisibilityAuthentication } from '../../../shared/routes/utils/RouteVisibility';
import { Projects } from './pages/list';
import { MyProjects } from './pages/myList';
import { ProjectDetail } from './pages/detail';
import { ProjectEvaluation } from './pages/evaluation';
import { TId } from '../../../shared/domain';
import { roles } from '../../Auth/domain';
import { ProjectsImport } from './pages/import';

export const ProjectRoutes = {
  projects: {
    list: '/projects',
    myProjects: '/my-projects',
    import: '/projects-import',
    projectEvaluation: (id: TId): string => `/project-evaluation/${id}`,
    myProjectDetail: (id: TId): string => `/my-projects/${id}`,
    detail: (id: TId): string => `/projects/${id}`,
  },
};

export const ProjectRoutesConfig = [
  {
    path: ProjectRoutes.projects.import,
    Component: ProjectsImport,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [roles.admin],
    },
  },
  {
    path: ProjectRoutes.projects.list,
    Component: Projects,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [...Object.keys(roles)],
    },
  },
  {
    path: ProjectRoutes.projects.myProjects,
    Component: MyProjects,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [roles.researchCoordinator],
    },
  },
  {
    path: ProjectRoutes.projects.detail(':id'),
    Component: ProjectDetail,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [...Object.keys(roles)],
    },
  },
  {
    path: ProjectRoutes.projects.projectEvaluation(':id'),
    Component: ProjectEvaluation,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [...Object.keys(roles)],
    },
  },
  {
    path: ProjectRoutes.projects.myProjectDetail(':id'),
    Component: ProjectDetail,
    visibility: {
      authentication: RouteVisibilityAuthentication.authenticatedOnly,
      roles: [roles.researchCoordinator],
    },
  },
];
