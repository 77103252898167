// eslint-disable-next-line @typescript-eslint/camelcase
import jwt_decode from 'jwt-decode';
import { signinActionTypes } from './actions';
import {
  roles,
  TAdmin,
  TProgramManager,
  TProgramManagerAdmin,
  TResearchCoordinator,
  TThinkTank,
  TThinkTankAdmin,
} from '../domain';
import { JWTData } from './reducers';

export const initialState = {
  role: null,
};

export interface Action {
  type: string;
  payload: {
    meta?: {
      authenticationToken: string;
      refreshToken: string;
    };
    researchCoordinator?: TResearchCoordinator;
    thinkTank?: TThinkTank;
    programManager?: TProgramManager;
    thinkTankAdmin?: TThinkTankAdmin;
    programManagerAdmin?: TProgramManagerAdmin;
    admin?: TAdmin;
  };
}

export interface State {
  role: roles | null;
}

const rolesReducer = (state: State = initialState, action: Action): State => {
  if (action.type === signinActionTypes.success) {
    const token = action.payload.meta?.authenticationToken || '';

    const decoded: JWTData = jwt_decode(token);

    return { role: roles[decoded.role as roles] };
  }

  return state;
};

export default rolesReducer;
