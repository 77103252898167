import _ from 'lodash';
import { TUser } from '../../app/Auth/domain';

export const formatFullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName?: string;
}): string =>
  `${_.defaultTo(firstName, '')} ${_.defaultTo(lastName, '')}`.trim();

export const getInitials = (fullName: string): string => {
  if (!fullName) return '';

  const mappedInitials = fullName.split(' ').map(name => name[0]);
  return mappedInitials.join('').substring(0, 2);
};

export const formatUserFullName = (user: TUser): string =>
  formatFullName({
    firstName: user.firstName,
    lastName: user.lastName,
  });
