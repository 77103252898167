import React, { useRef, useState } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';

import { TStackedBarChart } from '../../../../domain/chart';
import {
  ChartPaginator,
  ChartPaginatorPosition,
} from '../../../../../Dashboard/presentation/components/chart/chartPaginator';
import { iconSizes } from '../../../../../../shared/constants';
import {
  ChartConfiguration,
  HorizontalChartConfiguration,
} from './configuration';
import { useChartHook } from './chartHook';
import styles from '../chartReport.module.scss';
import { ChartBarHeight } from '../../../../domain/constants';

require('../chart.js');

export const StackedBarChart: React.FC<{
  data: TStackedBarChart;
  isHorizontal?: boolean;
}> = ({ data, isHorizontal = false }) => {
  const reference = useRef(null);
  const [pageIndex, setPageIndex] = useState(0);
  const { dataset, width, hasNextPage } = useChartHook(
    reference,
    data,
    pageIndex,
  );

  return (
    <div ref={reference} className={styles.report__stackedBarChartContainer}>
      <div style={{ width: width, height: ChartBarHeight }}>
        <ChartPaginator
          visible={pageIndex !== 0}
          size={iconSizes.sm}
          onClick={(): void => setPageIndex(pageIndex - 1)}
          position={ChartPaginatorPosition.LEFT}
        />
        {isHorizontal ? (
          <HorizontalBar
            data={dataset}
            width={500}
            height={500}
            options={HorizontalChartConfiguration}
            redraw
          />
        ) : (
          <Bar
            redraw
            data={dataset}
            width={500}
            height={1000}
            options={ChartConfiguration}
          />
        )}

        <ChartPaginator
          visible={hasNextPage}
          size={iconSizes.sm}
          onClick={(): void => setPageIndex(pageIndex + 1)}
          position={ChartPaginatorPosition.RIGHT}
        />
      </div>
    </div>
  );
};
