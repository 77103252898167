import { AxiosPromise } from 'axios';
import { httpMethods } from '../../../shared/constants';
import { fetchApiWithAuthRequest } from '../../../shared/api/fetchApi';
import { apiUrl } from '../../../shared/api/apiUrl';
import { endpoints } from '../../../shared/api/endpoints';
import { TId, TQualifications } from '../../../shared/domain';

export const editResearchCoordinatorsProjectApi = ({
  authenticationToken,
  projectId,
  researchCoordinatorEmail,
  toRemoveResearchCoordinatorId,
}: {
  authenticationToken: string;
  projectId: TId;
  researchCoordinatorEmail: TId;
  toRemoveResearchCoordinatorId: string;
}): AxiosPromise<{ qualifications: TQualifications }> => {
  return fetchApiWithAuthRequest({
    url: apiUrl(
      endpoints.projects.editResearchCoordinatorProject(
        projectId,
        toRemoveResearchCoordinatorId,
      ),
    ),
    method: httpMethods.post,
    authenticationToken,
    data: { email: researchCoordinatorEmail },
  });
};
