import React, { useState } from 'react';

import {
  H1,
  Paragraph,
  FlexBox,
  Box,
  PasswordInput,
  PrimaryButton,
  If,
  Desktop,
  InputWithLabel,
  EaseInBox,
  MidHeightSeparatorDark,
} from '../../../../shared/components';

import {
  useGetSearchParam,
  useReplaceRoute,
  useDispatch,
  useExternalLocation,
} from '../../../../shared/hooks';
import { getTranslateByScope } from '../../../../shared/i18n';
import { validatePassword } from '../components/validatePassword';
import { showToasterAction } from '../../../../shared/redux/actions';
import { toasterTypes } from '../../../../shared/constants';
import { routePaths } from '../../../../shared/routes/config';
import { SessionPagesBackground } from '../components/SessionPagesBackground';
import { resetPasswordAction } from '../../redux/effects';
import { apiUrl } from '../../../../shared/api/apiUrl';
import { endpoints } from '../../../../shared/api/endpoints';
import { VerticalSpacer } from '../../../../shared/components/spacers';

import styles from '../components/LoginForm.module.scss';

export const translate = getTranslateByScope('ui.layouts.PasswordReset');

const PASSWORD_RESET_FORM_WIDTH = 428;

const PasswordResetForm = (): JSX.Element => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [hasSubmittedWithErrors, setHasSubmittedWithErrors] = useState(false);
  const { replaceRoute } = useReplaceRoute();
  const { goTo } = useExternalLocation();

  const token = useGetSearchParam('token');

  const handleClick = (): void => {
    const isValidPassword = validatePassword(password);
    setHasSubmittedWithErrors(!isValidPassword);

    if (!token) {
      dispatch(
        showToasterAction({
          description: translate('tokenMissingToast.description'),
          type: toasterTypes.failure,
        }),
      );
      return;
    }

    if (token && isValidPassword) {
      const showSuccessToast = (): void => {
        dispatch(
          showToasterAction({
            description: translate('successfullyResetToast.description'),
            type: toasterTypes.success,
          }),
        );
      };

      const showErrorToast = (): void => {
        dispatch(
          showToasterAction({
            description: translate('errorToast.description'),
            type: toasterTypes.failure,
          }),
        );
      };

      dispatch(
        resetPasswordAction({
          token,
          password,
          onSuccess: () => {
            replaceRoute(routePaths.login);
            showSuccessToast();
          },
          onFailure: () => {
            showErrorToast();
          },
        }),
      );
    }
  };

  const handleClickSSO = (): void => {
    goTo(apiUrl(endpoints.session.sso));
  };

  return (
    <EaseInBox>
      <FlexBox
        style={{ minWidth: PASSWORD_RESET_FORM_WIDTH, maxWidth: '100%' }}
        flexDirection="column"
      >
        <H1 color="black">{translate('header.title')}</H1>
        <VerticalSpacer size="lg" />
        <Paragraph color="black" size="body">
          {translate('header.ssoDescription')}:
        </Paragraph>
        <VerticalSpacer size="lg" />
        <Box>
          <PrimaryButton onClick={handleClickSSO}>
            {translate('button.sso')}
          </PrimaryButton>
        </Box>
        <VerticalSpacer size="xl" />

        <FlexBox.Row className={styles.loginSeparator}>
          <MidHeightSeparatorDark />
          <Paragraph className={styles.loginSeparatorText}>oder</Paragraph>
          <MidHeightSeparatorDark />
        </FlexBox.Row>

        <VerticalSpacer size="xxxl" />
        <Paragraph color="black" size="body">
          {translate('header.description')}:
        </Paragraph>

        <Box marginTop="lg">
          <InputWithLabel
            label={translate('passwordInput.label')}
            InputComponent={
              <PasswordInput
                hasError={hasSubmittedWithErrors}
                onChangeText={setPassword}
                value={password}
                placeholder={translate('passwordInput.placeholder')}
              />
            }
          />

          <Box marginTop="sm">
            <If condition={!hasSubmittedWithErrors}>
              {(): JSX.Element => (
                <Paragraph color="grey" size="tiny">
                  {translate('passwordInput.passwordValidationText')}
                </Paragraph>
              )}
            </If>
            <If condition={hasSubmittedWithErrors}>
              {(): JSX.Element => (
                <Paragraph color="red" size="tiny">
                  {translate('passwordInput.passwordValidationText')}
                </Paragraph>
              )}
            </If>
          </Box>
        </Box>
        <Box marginTop="lg">
          <PrimaryButton disabled={!password} onClick={handleClick}>
            {translate('button.caption')}
          </PrimaryButton>
        </Box>
      </FlexBox>
    </EaseInBox>
  );
};

export const PasswordReset = (): JSX.Element => (
  <FlexBox style={{ height: '100vh', maxHeight: '100vh' }}>
    <Desktop>
      <FlexBox flex={1}>
        <SessionPagesBackground />
      </FlexBox>
    </Desktop>
    <FlexBox alignItems="center" justifyContent="center" flex={1}>
      <PasswordResetForm />
    </FlexBox>
  </FlexBox>
);

export default PasswordReset;
