import React from 'react';
import { Box, FlexBox, Link } from '../../../../../shared/components';
import { ProjectCard } from './ProjectCard';
import { TId } from '../../../../../shared/domain';

export const ProjectGridItems = ({
  projectIds,
  projectDetailRoute,
  projectListRoute,
  pageIndex,
}: {
  projectIds: any[];
  projectDetailRoute: (projectId: string) => string;
  projectListRoute: string;
  pageIndex: number;
}): JSX.Element => (
  <React.Fragment>
    <FlexBox flexWrap={true}>
      {projectIds.map((projectId: TId) => (
        <Box key={projectId}>
          <Link
            to={{
              pathname: projectDetailRoute(projectId),
              state: { page: pageIndex + 1, projectListRoute },
            }}
          >
            <ProjectCard projectId={projectId} />
          </Link>
        </Box>
      ))}
    </FlexBox>
  </React.Fragment>
);
