import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toasterTypes } from '../../../shared/constants';
import { TId } from '../../../shared/domain';
import { getTranslateByScope } from '../../../shared/i18n';
import { showToasterAction } from '../../../shared/redux/actions';

import { projectActions } from '../redux/effects';

export const useDeleteProject = (): {
  isLoading: boolean;
  deleteProject: (projectId: TId) => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const translate = getTranslateByScope(`ui.layouts.Projects.delete`);

  const onRequestFinished = (status: 'success' | 'failure'): void => {
    dispatch(
      showToasterAction({
        description: translate(`toasters.${status}`),
        type: toasterTypes[status],
      }),
    );
    setIsLoading(false);
  };

  const deleteProject = (projectId: TId): void => {
    setIsLoading(true);
    dispatch(
      projectActions.delete({
        id: projectId,
        onSuccess: () => {
          onRequestFinished('success');
        },
        onFailure: () => onRequestFinished('failure'),
      }),
    );
  };

  return { deleteProject, isLoading };
};
