import React from 'react';
import { useDispatch } from 'react-redux';
import { EvaluationValues } from '../../../data/evaluateProjectApi';
import { handleFail, handleSuccess } from './effects';
import { EvaluationComment } from './components/evaluationComment';
import styles from './index.module.scss';
import { VerticalSpacer } from '../../../../../shared/components/spacers';
import { projectActions } from '../../../redux/effects';
import { translate } from './translate';
import { TProject } from '../../../domain';
import { TopicEvaluation } from './components/topicEvaluation';
import { H4 } from '../../../../../shared/components';

export const EvaluationTTandPM: React.FC<{ project: TProject }> = ({
  project,
}) => {
  const dispatch = useDispatch();

  const submit = (
    commentText: string,
    evaluation: EvaluationValues | undefined,
  ): void => {
    dispatch(
      projectActions.evaluate({
        id: project.id,
        comment: commentText,
        evaluation: evaluation,
        onSuccess: () => handleSuccess(dispatch, project.id),
        onFailure: () => handleFail(dispatch),
      }),
    );
  };

  return (
    <div className={styles.evaluation__bodyContainer}>
      <div className={styles.evaluation__bodyLeft}>
        <H4>{translate('comments')}</H4>
        <VerticalSpacer size="xl" />
        <EvaluationComment project={project} onSubmit={submit} />
      </div>
      <div className={styles.evaluation__bodyRight}>
        <TopicEvaluation project={project} />
      </div>
    </div>
  );
};
